import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button, Loading, Typography } from "ui-library/atoms";
import { ModalBody, ModalFooter } from "ui-library/modules";

import i18n from "core/i18n";
import fetching from "core/fetching";
import modal from "core/modal";
import router from "core/router";
import { SimpleModalWindow } from "containers/modal";
import { Pages, Tabs } from "routeConstants";

import { MODAL_ENTITY_PRINT_CONFIRMATION } from "../../constants";

export const ModalPrintConfirmation = () => {
    const dispatch = useDispatch();
    const { t } = i18n.useTranslation();

    const { idObject } = useParams();
    const isSubmitting = useSelector(fetching.isFetching(MODAL_ENTITY_PRINT_CONFIRMATION));
    const link = router.getStaticUrl(Pages.CONTRACT_DIRECT, Tabs.DOCUMENTS, { idObject: idObject });

    return (
        <SimpleModalWindow
            title={t("success.title.beingProcessed")}
            modalName={MODAL_ENTITY_PRINT_CONFIRMATION}
            icon={"document"}
            isSubmitting={isSubmitting}
        >
            <ModalBody>
                <Loading loading={isSubmitting}>
                    <Typography markDown={t("success.text.sellBuyConfirmation", { link })} internalAppLink />
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => dispatch(modal.closeModalForm(MODAL_ENTITY_PRINT_CONFIRMATION))} color="blue" variant="contained">
                    {t("common.close")}
                </Button>
            </ModalFooter>
        </SimpleModalWindow>
    );
};
