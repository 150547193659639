import { fetch } from "core/util";
import { API_PREFIX } from "app/constants";

const validateMobile = (number) => {
    if (number) {
        return fetch.doPost(`${API_PREFIX}/validation/mobile`, { value: number });
    }
    return Promise.resolve();
};

const validatePhone = (number) => {
    if (number) {
        return fetch.doPost(`${API_PREFIX}/validation/phone`, { value: number });
    }
    return Promise.resolve();
};

const validateIdentificationCard = (idNumber, values) => {
    const idType = values.getIn(["identificationCard", "identification"]);

    if (idNumber && idType) {
        return fetch.doPost(`${API_PREFIX}/validation/identification-card`, { idNumber, idType });
    }
    return Promise.resolve();
};

const validateEmployerIdentificationNumber = (employerId) =>
    fetch.doPost(`${API_PREFIX}/validation/employer-identification-number`, { value: employerId });

const createValidateContractAgentNumber = (idObject) => (agentNumber) =>
    fetch.doPost(`${API_PREFIX}/validation/agent`, {
        agentNumber,
        objects: [{ idObject }],
    });

// This validation functions return normal js array (not Immutable Record like other rest calls)
export default {
    validateMobile,
    validatePhone,
    validateIdentificationCard,
    validateEmployerIdentificationNumber,
    createValidateContractAgentNumber,
};
