import React, { FC } from "react";
import { InputAdornment, StandardTextFieldProps } from "@mui/material";

import { StyledTextField } from "ui-library/Styled";
import { ErrorContent, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";

import { Label } from "./Label";

export interface TextFieldProps extends StandardTextFieldProps {
    id: string;
    label?: string;
    labelOptional?: string;
    tooltip?: string;
    error?: boolean;
    errorContent?: string;
    labelDesc?: string;
    prefix?: string;
    sufix?: string;
    multiline?: boolean;
    isActivationCode?: boolean;
    rows?: number;
}

export const TextField: FC<TextFieldProps> = ({
    labelDesc,
    labelOptional,
    errorContent,
    id,
    label,
    tooltip,
    error,
    prefix,
    sufix,
    multiline,
    rows,
    size = "medium",
    isActivationCode,
    ...props
}) => (
    <div>
        <Box>
            <Flexbox alignItems={"flex-start"} gap={1}>
                <Label label={label} id={id} labelOptional={labelOptional} />
                <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
            </Flexbox>
            {labelDesc && (
                <Box mt={0.5} mb={1}>
                    <Typography color={"gray"} variant="caption">
                        {labelDesc}
                    </Typography>
                </Box>
            )}
        </Box>

        <StyledTextField
            id={id}
            error={error}
            label={null}
            hiddenLabel={true}
            variant="outlined"
            multiline={multiline}
            rows={rows}
            $size={size}
            sx={isActivationCode && { letterSpacing: "10px" }}
            InputProps={{
                startAdornment: prefix && !multiline ? <InputAdornment position="start">{prefix}</InputAdornment> : "",
                endAdornment: sufix && !multiline ? <InputAdornment position="end">{sufix}</InputAdornment> : "",
            }}
            {...props}
        />

        {error ? <ErrorContent content={errorContent} /> : ""}
    </div>
);
