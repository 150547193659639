import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selector } from "@reduxjs/toolkit";

import { Typography } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { formatLocalizedValueMap, formatYesNo } from "core/formatters";
import { getLocalizedMutualFundsStatementFrequencyMap } from "core/localized";
import { MutualFundsClientStatementSettings, State } from "types";

export interface MutualFundsStatementSettingsProps {
    includeBuySellConfirmation?: boolean;
    getClientStatementSettings: Selector<State, MutualFundsClientStatementSettings>;
    clientStatementSettingsFormName: string;
    ModalClientStatementSettingsForm: any;
    isContractEditable: boolean;
    editPolicyHolderLink: string;
    isElComSet?: boolean;
}

export const MutualFundsStatementSettings: FC<MutualFundsStatementSettingsProps> = ({
    includeBuySellConfirmation = false,
    getClientStatementSettings,
    clientStatementSettingsFormName,
    ModalClientStatementSettingsForm,
    editPolicyHolderLink,
    isContractEditable,
    isElComSet = false,
}) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const hasEditPermission = useHasEditContractPermission();

    const statementSettings = useSelector(getClientStatementSettings);
    const frequencyMap = useSelector(getLocalizedMutualFundsStatementFrequencyMap);
    const formattedPeriodicity = formatLocalizedValueMap(statementSettings.statementPeriodicity, frequencyMap);

    const missingElComText =
        hasEditPermission && isContractEditable
            ? t("general.clientStatement.missingElectronicCommunication", { link: editPolicyHolderLink })
            : t("general.clientStatement.missingElectronicCommunicationNoEdit");

    const editLinksElcomSet = [
        {
            formConst: clientStatementSettingsFormName,
            name: "form.clientStatementSettings",
        },
    ];

    return (
        <GridItem display={"flex"} xs={12}>
            <InfoPanel
                icon={"document"}
                title={t("statements.statementSettings")}
                headerAction={hasEditPermission && isElComSet && isContractEditable && <EditLinksDropdown links={editLinksElcomSet} />}
            >
                {isElComSet ? (
                    <ContentBox>
                        <LabelTextItem
                            label={t("general.statements.receiveStatement")}
                            text={formatYesNo(t, statementSettings.receiveStatement)}
                        />
                        <LabelTextItem
                            label={t("general.statements.statementPeriodicity")}
                            text={formattedPeriodicity ? formattedPeriodicity : "-"}
                        />
                        {includeBuySellConfirmation && (
                            <>
                                <LabelTextItem
                                    label={t("investments.clientStatement.receiveBuyConfirmation")}
                                    text={formatYesNo(t, statementSettings.receiveBuyConfirmation)}
                                />
                                <LabelTextItem
                                    label={t("investments.clientStatement.receiveSellConfirmation")}
                                    text={formatYesNo(t, statementSettings.receiveSellConfirmation)}
                                />
                            </>
                        )}
                    </ContentBox>
                ) : (
                    <>
                        <ContentBox>
                            <Typography markDown={missingElComText} />
                        </ContentBox>
                    </>
                )}
            </InfoPanel>

            {/* Modals */}
            {isElComSet && <ModalClientStatementSettingsForm />}
        </GridItem>
    );
};
