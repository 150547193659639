import { all, call, fork, put, select } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import modal from "core/modal";
import userContracts from "core/userContracts";

import licencePlateNumberForm from "form/modal/licencePlateNumber";
import vehicleLicenceNumberForm from "form/modal/vehicleLicenceNumber";
import contractNameForm from "form/modal/contractName";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import policyHolderForm from "form/fullPage/policyHolder";

import { contractsApi, documentsApi, paymentApi, vehicleApi } from "serverApi";
import { Tabs } from "routeConstants";

import { clear, setDetailSelectedCoverage, setVehicleContractDetail } from "./actions";
import {
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_CONTRACT_INCIDENTS,
    ENTITY_CONTRACT_OVERVIEW,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_GREEN_CARD_DATA,
    ENTITY_PAYMENT_INFORMATION,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    FORM_EDIT_LICENCE_PLATE_NUMBER,
    FORM_EDIT_POLICY_HOLDER,
    FORM_EDIT_VEHICLE_LICENCE_NUMBER,
} from "./constants";
import {
    getActualSchedulerRecord,
    getElectronicCommunication,
    getIdObject,
    getInsurancePackagesOptions,
    getInsuredVehicleWithMtpl,
    getPolicy,
    getPolicyHolder,
    getSelectedInsuredVehicle,
} from "./selectors";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const hasDetail = yield select(userContracts.createGetContractById(Number(idObject)));
        if (hasDetail) {
            const vehicleContractDetail = yield call(vehicleApi.getVehicleContractDetail, idObject);
            return [setVehicleContractDetail(vehicleContractDetail)];
        }
        return [];
    },
    *onPageEnter({ idObject }) {
        const hasDetail = yield select(userContracts.createGetContractById(Number(idObject)));
        if (hasDetail) {
            yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
            yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => vehicleApi.getVehicleFinancialOperations(idObject));
            yield fork(entity.load, ENTITY_CONTRACT_INCIDENTS, () => vehicleApi.getVehicleClaims(idObject));
            const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
            yield fork(entity.load, ENTITY_CONTRACT_OVERVIEW, () => contractsApi.getContractOverview(productGroup, idObject));
            yield fork(loadPaymentInformation, idObject);
            yield fork(entity.periodicallyCheckClaims, 60 * 1000, ENTITY_CONTRACT_INCIDENTS, () => vehicleApi.getVehicleClaims(idObject));

            const coveragePackagesOptions = yield select(getInsurancePackagesOptions);
            if (coveragePackagesOptions?.size === 1) {
                yield put(setDetailSelectedCoverage(coveragePackagesOptions.get(0).value));
            }

            // As last thing - load header data for green card.
            const vehiclesWithMtpl = yield select(getInsuredVehicleWithMtpl);
            yield all([
                ...vehiclesWithMtpl.map((vehicle) =>
                    fork(entity.loadIntoMap, ENTITY_GREEN_CARD_DATA, vehicle.vinNumber, () =>
                        vehicleApi.getVehicleGreenCardHeaderData(idObject, vehicle.vinNumber),
                    ),
                ),
            ]);
        }
    },
    *onInnerRouteChange(name, params) {
        switch (name) {
            case Tabs.EDIT_POLICY_HOLDER_FORM:
                yield call(contractEditHolderFormSaga, params.idObject);
                break;
            default:
                break;
        }
    },
    *onModalOpen(modalName) {
        const idObject = yield select(getIdObject);
        switch (modalName) {
            case FORM_EDIT_CONTRACT_NAME:
                yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
                break;
            case FORM_EDIT_CONTRACT_OWNER_PAYMENTS:
                yield call(
                    contractOwnerPaymentsForm.createSagaLegacy,
                    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
                    idObject,
                    getActualSchedulerRecord,
                );
                break;
            case FORM_EDIT_LICENCE_PLATE_NUMBER:
                yield call(licencePlateNumberForm.createSaga, FORM_EDIT_LICENCE_PLATE_NUMBER, idObject, getSelectedInsuredVehicle);
                break;
            case FORM_EDIT_VEHICLE_LICENCE_NUMBER:
                yield call(vehicleLicenceNumberForm.createSaga, FORM_EDIT_VEHICLE_LICENCE_NUMBER, idObject, getSelectedInsuredVehicle);
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_CONTRACT_INCIDENTS,
                ENTITY_PAYMENT_INFORMATION,
                ENTITY_GREEN_CARD_DATA,
                ENTITY_CONTRACT_OVERVIEW,
            ),
        ];
    },
});

function* loadPaymentInformation(idObject) {
    const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
    yield call(entity.load, ENTITY_PAYMENT_INFORMATION, () => paymentApi.get(productGroup, idObject));
}

const contractEditHolderFormSaga = policyHolderForm.createSagaLegacy(
    FORM_EDIT_POLICY_HOLDER,
    getPolicyHolder,
    getPolicy,
    getElectronicCommunication,
);
