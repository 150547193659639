import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Container, Loading } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";

import { form } from "core/form";
import i18n from "core/i18n";
import userContracts from "core/userContracts";

import { BulkChangeFormBox } from "containers/bulkChange";
import { FullPageFormLayout } from "containers/fullPageForm";

import { IndividualPersonType } from "enums";
import { CLIENT_TYPE_COMPANY, CLIENT_TYPE_INDIVIDUAL } from "./constants";
import { getClientTypeCallback, isIdentificationCardIdDirtyCallback } from "./selectors";

import { ButtonsBox } from "./components";
import {
    AttachmentsFormBox,
    CompanyParticipantFormBox,
    ContactAddressFormBox,
    ElectronicCommunicationFormBox,
    EmailFormBox,
    IdentityCardTypeFormBox,
    IndividualParticipantFormBox,
    PermanentAddressFormBox,
    TelephoneFormBox,
} from "./containers";
import { BackToPolicyHolderButton } from "form/fullPage/policyHolder/components/BackToPolicyHolderButton";

export const getAreInasNumbersTheSame = (policyHolder) => {
    if (!policyHolder.inasId && !policyHolder.inasContactId) {
        return true;
    }
    return policyHolder.inasId === policyHolder.inasContactId;
};

const EditForm = ({
    canSubmit,
    handleSubmit,
    submitting,
    formName,
    individualPersonType,
    getIdObject,
    getPolicyHolder,
    isIdentificationDocHidden,
}) => {
    const idCardDirty = useSelector(isIdentificationCardIdDirtyCallback(formName));
    const clientType = useSelector(getClientTypeCallback(formName));
    const idObject = useSelector(getIdObject);
    const policyHolder = useSelector(getPolicyHolder);
    const contract = userContracts.useGetContractById(idObject);
    const areInasNumbersTheSame = getAreInasNumbersTheSame(policyHolder);

    // For cases when rendering of form is sooner that store contains idObject. Happens on browser back.
    if (!contract) {
        return null;
    }

    return (
        <Container maxWidth="md">
            <Loading fullPage loading={submitting} />
            <FormBlock>
                {clientType === CLIENT_TYPE_INDIVIDUAL ? (
                    <IndividualParticipantFormBox individualPersonType={individualPersonType} />
                ) : (
                    <CompanyParticipantFormBox individualPersonType={individualPersonType} />
                )}

                <PermanentAddressFormBox isCompany={clientType === CLIENT_TYPE_COMPANY} formName={formName} />

                <ContactAddressFormBox
                    isCompany={clientType === CLIENT_TYPE_COMPANY}
                    formName={formName}
                    isUniqaContract={contract.isUniqaContract}
                    contractCountry={contract.countryCode}
                />

                {!isIdentificationDocHidden && clientType === CLIENT_TYPE_INDIVIDUAL && (
                    <IdentityCardTypeFormBox idCardDirty={idCardDirty} />
                )}

                <TelephoneFormBox />

                <EmailFormBox />

                <ElectronicCommunicationFormBox />

                <AttachmentsFormBox
                    formName={formName}
                    isCompany={clientType === CLIENT_TYPE_COMPANY}
                    isIdentificationDocHidden={isIdentificationDocHidden}
                />

                <BulkChangeFormBox
                    idObject={idObject}
                    formName={formName}
                    isCompany={clientType === CLIENT_TYPE_COMPANY}
                    individualPersonType={individualPersonType}
                    isUniqaContract={contract.isUniqaContract}
                    areInasNumbersTheSame={areInasNumbersTheSame}
                />

                <ButtonsBox handleSubmit={handleSubmit} formName={formName} idObject={idObject} canSubmit={canSubmit} />
            </FormBlock>
        </Container>
    );
};

EditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    individualPersonType: PropTypes.oneOf(Object.values(IndividualPersonType)).isRequired,
    getIdObject: PropTypes.func.isRequired,
    getPolicyHolder: PropTypes.func.isRequired,
    isIdentificationDocHidden: PropTypes.bool.isRequired,
};

const createContainerLegacy = (formName, individualPersonType, getIdObject, getPolicyHolder, isIdentificationDocHidden = false) =>
    form(formName, {
        asyncValidationFields: ["mobile", "identificationCard.cardId"],
    })((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(getIdObject);

        return (
            <FullPageFormLayout
                formName={formName}
                formTitle={t("form.editContractData")}
                BackButton={<BackToPolicyHolderButton idObject={idObject} />}
            >
                <EditForm
                    {...props}
                    formName={formName}
                    individualPersonType={individualPersonType}
                    getIdObject={getIdObject}
                    getPolicyHolder={getPolicyHolder}
                    isIdentificationDocHidden={isIdentificationDocHidden}
                />
            </FullPageFormLayout>
        );
    });

export default createContainerLegacy;
