import { Tabs } from "routeConstants";
import { innerRoutes } from "routeUrls";

export default {
    [Tabs.OVERVIEW]: innerRoutes[Tabs.OVERVIEW],
    [Tabs.HOLDER]: innerRoutes[Tabs.HOLDER],
    [Tabs.DETAIL]: innerRoutes[Tabs.DETAIL],
    [Tabs.FINANCIAL_OPERATIONS]: innerRoutes[Tabs.FINANCIAL_OPERATIONS],
    [Tabs.CLAIMS]: innerRoutes[Tabs.CLAIMS],
    [Tabs.DOCUMENTS]: innerRoutes[Tabs.DOCUMENTS],
    [Tabs.AGENT]: innerRoutes[Tabs.AGENT],
    [Tabs.EDIT_POLICY_HOLDER_FORM]: innerRoutes[Tabs.EDIT_POLICY_HOLDER_FORM],
    [Tabs.PENSION_BENEFICIARIES_FORM]: innerRoutes[Tabs.PENSION_BENEFICIARIES_FORM],
};
