import React from "react";
import PropTypes from "prop-types";
import { List } from "immutable";

import { FeatureFlag } from "types";
import pageContext from "core/pageContext";

import { HarmonizedActualAndAdvancedPaymentSchedulerPanel } from "./HarmonizedActualAndAdvancedPaymentSchedulerPanel";
import { HarmonizedActualPaymentOnlySchedulerPanel } from "./HarmonizedActualPaymentOnlySchedulerPanel";

export const HarmonizedContractPaymentSchedulerGridItem = ({
    actualPaymentScheduler,
    advancePaymentsScheduler,
    hasEditPermission,
    modalFormName,
}) => {
    const isHarmonizedContractsEditEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.HARMONIZED_CONTRACTS_EDIT_ENABLED);

    // In case advance scheduler is available we do display both.
    if (advancePaymentsScheduler instanceof List && !advancePaymentsScheduler.isEmpty()) {
        return (
            <HarmonizedActualAndAdvancedPaymentSchedulerPanel
                actualScheduler={actualPaymentScheduler}
                advancePaymentsScheduler={advancePaymentsScheduler}
                hasEditPermission={hasEditPermission && isHarmonizedContractsEditEnabled}
                modalFormName={modalFormName}
            />
        );
    }

    return (
        <HarmonizedActualPaymentOnlySchedulerPanel
            actualScheduler={actualPaymentScheduler}
            hasEditPermission={hasEditPermission && isHarmonizedContractsEditEnabled}
            modalFormName={modalFormName}
        />
    );
};

HarmonizedContractPaymentSchedulerGridItem.propTypes = {
    actualPaymentScheduler: PropTypes.object.isRequired,
    advancePaymentsScheduler: PropTypes.object,
    modalFormName: PropTypes.string.isRequired,
    hasEditPermission: PropTypes.bool,
};

HarmonizedContractPaymentSchedulerGridItem.defaultProps = {
    advancePaymentsScheduler: null,
    hasEditPermission: false,
};
