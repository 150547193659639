import { createSelector } from "reselect";

import {
    ChangePropositionPensionType,
    ChangePropositionStatus,
    ChangePropositionType,
    ClientType,
    DdsDssFundTypes,
    Frequency,
    FrequencyNonLife,
    FundSellSwitch,
    LifeFrequency,
    MutualFundsPeriodicity,
    AmountFilterOperator,
    AmountWithOperationTypeFilterOperator,
    PaymentContribution,
    PensionInvestmentStrategy,
    SchedulerPaidState,
    SubjectType,
    UniqaInsuranceRisk,
    UniqaLineOfBusiness,
} from "enums";

import { formatPaymentsSchedulerAmountLegacy, formatPaymentsSchedulerFull, formatPaymentsSchedulerFullLegacy } from "core/formatters";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { formatLocalizedValueMap } from "core/formatters";

const frequencyComparator = (freq1, freq2) => parseInt(freq1.get("value")) - parseInt(freq2.get("value"));

export const getLocalizedClientTypeCodes = i18n.createGetLocalizedEnumOptions(ClientType);

export const getLocalizedFrequencyMap = i18n.createGetLocalizedEnumMap(Frequency);
export const getLocalizedLifeFrequencyMap = i18n.createGetLocalizedEnumMap(LifeFrequency);

export const getLocalizedPaymentContributionMap = i18n.createGetLocalizedEnumMap(PaymentContribution);

export const getLocalizedPaymentContributionOptions = i18n.createGetLocalizedEnumOptions(PaymentContribution);

export const getLocalizedSchedulerPaidStateMap = i18n.createGetLocalizedEnumMap(SchedulerPaidState);

export const getLocalizedSubjectType = i18n.createGetLocalizedEnumOptions(SubjectType);

export const getLocalizedPensionInvestmentStrategyOptions = i18n.createGetLocalizedEnumOptions(PensionInvestmentStrategy);

export const getLocalizedFundTypeDdsDssMap = i18n.createGetLocalizedEnumMap(DdsDssFundTypes);

export const getLocalizedUniqaLineOfBusinessMap = i18n.createGetLocalizedEnumMap(UniqaLineOfBusiness);

export const getLocalizedUniqaInsuranceRiskMap = i18n.createGetLocalizedEnumMap(UniqaInsuranceRisk);

export const getLocalizedFundSellSwitchMap = i18n.createGetLocalizedEnumMap(FundSellSwitch);

export const getLocalizedMutualFundsPeriodicityMap = i18n.createGetLocalizedEnumMap(MutualFundsPeriodicity);

export const getLocalizedChangePropositionStatusMap = i18n.createGetLocalizedEnumMap(ChangePropositionStatus);

export const getLocalizedChangePropositionTypeMap = i18n.createGetLocalizedEnumMap(ChangePropositionType);

export const getLocalizedChangePropositionPensionTypeMap = i18n.createGetLocalizedEnumMap(ChangePropositionPensionType);

export const getLocalizedAmountFilterOperatorOptions = i18n.createGetLocalizedEnumOptions(AmountFilterOperator);
export const getLocalizedAmountWithOperationTypeFilterOperatorOptions = i18n.createGetLocalizedEnumOptions(
    AmountWithOperationTypeFilterOperator,
);

export const getLocalizedFrequencyOptions = createSelector(i18n.createGetLocalizedEnumOptions(Frequency), (options) =>
    options.sort(frequencyComparator),
);

export const getLocalizedFrequencyNonLifeOptions = createSelector(i18n.createGetLocalizedEnumOptions(FrequencyNonLife), (options) =>
    options.sort(frequencyComparator),
);

export const getLocalizedSchedulerPaymentFull = (scheduler, displayPaymentMode = false, lifeContract = false) =>
    createSelector(
        getLocalizedFrequencyMap,
        getLocalizedLifeFrequencyMap,
        getLocalizedPaymentContributionMap,
        pageContext.getIsSlovakSite,
        (frequencyMap, lifeFrequencyMap, paymentContributionMap, isSlovakSite) =>
            formatPaymentsSchedulerFull(
                scheduler,
                lifeContract ? lifeFrequencyMap : frequencyMap,
                displayPaymentMode ? paymentContributionMap : {},
                isSlovakSite,
            ),
    );

export const getLocalizedSchedulerPaymentFullLegacy = (scheduler, displayPaymentMode = false, lifeContract = false) =>
    createSelector(
        getLocalizedFrequencyMap,
        getLocalizedLifeFrequencyMap,
        getLocalizedPaymentContributionMap,
        pageContext.getIsSlovakSite,
        (frequencyMap, lifeFrequencyMap, paymentContributionMap, isSlovakSite) =>
            formatPaymentsSchedulerFullLegacy(
                scheduler,
                lifeContract ? lifeFrequencyMap : frequencyMap,
                displayPaymentMode ? paymentContributionMap : {},
                isSlovakSite,
            ),
    );

export const getLocalizedSchedulerPaymentAmount = (scheduler) =>
    createSelector(pageContext.getIsSlovakSite, (isSlovakSite) => formatPaymentsSchedulerAmountLegacy(scheduler, isSlovakSite));

export const getLocalizedSchedulerPaymentFrequency = (scheduler) =>
    createSelector(getLocalizedFrequencyMap, (frequencyMap) => formatLocalizedValueMap(scheduler?.periodicity?.code, frequencyMap));

export const getLocalizedSchedulerPaymentMode = (scheduler) =>
    createSelector(getLocalizedPaymentContributionMap, (paymentContributionMap) =>
        formatLocalizedValueMap(scheduler?.paymentMode?.code, paymentContributionMap),
    );

export const getLocalizedSchedulerPaidState = (scheduler) =>
    createSelector(getLocalizedSchedulerPaidStateMap, (schedulerStatusMap) =>
        formatLocalizedValueMap(scheduler?.state, schedulerStatusMap),
    );
