import React from "react";
import Immutable from "immutable";
import PropTypes from "prop-types";

import { Box, Flexbox, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";
import { PopoverTooltip, Tab, TabPanel, Tabs } from "ui-library/atoms";

import i18n from "core/i18n";
import { dateTimeToLocalFullDate } from "core/formatters";

import { HarmonizedPaymentSchedulerDataTable } from "./HarmonizedPaymentSchedulerDataTable";
import { ContributionsEditDropdown } from "./dropdown/ContributionsEditDropdown";

export const HarmonizedActualAndAdvancedPaymentSchedulerPanel = ({
    actualScheduler,
    advancePaymentsScheduler,
    hasEditPermission,
    modalFormName,
}) => {
    const { t } = i18n.useTranslation();

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const scheduler = Immutable.Iterable.isIterable(advancePaymentsScheduler) ? advancePaymentsScheduler.first() : advancePaymentsScheduler;
    const startDate = dateTimeToLocalFullDate(scheduler.get("paymentStartDate"));

    return (
        <GridItem display={"flex"} xs={12} lg={7}>
            <InfoPanel
                icon={"contract"}
                title={
                    <Flexbox alignItems="center" gap={1}>
                        {t("insurance.paymentScheduler")}
                        <Box>
                            <PopoverTooltip
                                variant="info"
                                title=""
                                content={t("insurance.advancePaymentScheduler.footnote", { startDate })}
                            />
                        </Box>
                    </Flexbox>
                }
                headerAction={hasEditPermission && <ContributionsEditDropdown modalContractOwnerPaymentsFormName={modalFormName} />}
            >
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={t("common.current")} />
                    <Tab label={t("common.advance")} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    {actualScheduler.map((scheduler) => (
                        <Box pt={6} pb={2} px={3} key={scheduler.reactKey}>
                            <HarmonizedPaymentSchedulerDataTable schedulerData={scheduler} />
                        </Box>
                    ))}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {advancePaymentsScheduler.map((scheduler) => (
                        <Box pt={6} pb={2} px={3} key={scheduler.reactKey}>
                            <HarmonizedPaymentSchedulerDataTable schedulerData={scheduler} />
                        </Box>
                    ))}
                </TabPanel>
            </InfoPanel>
        </GridItem>
    );
};

HarmonizedActualAndAdvancedPaymentSchedulerPanel.propTypes = {
    actualScheduler: PropTypes.object.isRequired,
    modalFormName: PropTypes.string.isRequired,
    hasEditPermission: PropTypes.bool,
};

HarmonizedActualAndAdvancedPaymentSchedulerPanel.defaultProps = {
    hasEditPermission: false,
};
