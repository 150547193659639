import { all, call, put, select, spawn } from "redux-saga/effects";

import fetching from "core/fetching";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import { DDS_QUICK_VIEW_MOVEMENT_TYPES, DSS_QUICK_VIEW_MOVEMENT_TYPES } from "app/constants";
import { sentry } from "core/util";
import { Countries, PolicyUrlName } from "enums";
import {
    contractsApi,
    homeApi,
    lifeApi,
    mutualFundsDirectApi,
    mutualFundsRegularApi,
    pensionDdsApi,
    pensionDpsApi,
    pensionDssApi,
    pensionPpApi,
    uniqaLifeApi,
    vehicleApi,
} from "serverApi";

import { createGetContractById, createGetProductGroupUrlNameById, getActiveContractIds } from "./selectors";
import { slice } from "./slice";
import { FinancialMovementSimplified, FinancialOperationsOverview } from "types";
import * as mapper from "./mapper";
import * as helper from "./helper";

export function* loadUserContracts() {
    const contracts = yield call(contractsApi.getContracts);
    yield put(slice.actions.setContracts(contracts));
}

export function* loadContractOverviews() {
    const activeContracts: number[] = yield select(getActiveContractIds);

    const actions =
        activeContracts?.map((idObject) => {
            return spawn(getContractOverview, idObject);
        }) || [];
    yield all(actions);
}

function* getContractOverview(idObject: number) {
    const productGroup = yield select(createGetProductGroupUrlNameById(idObject));
    if (!productGroup) {
        return;
    }
    try {
        const contractOverview = yield call(contractsApi.getContractOverview, productGroup, idObject);
        yield put(slice.actions.updateContractOverview({ idObject, data: contractOverview }));
    } catch (e) {
        sentry.captureException(e);
    }
}

export function* loadFinancialOperationsOverview() {
    const activeContracts: number[] = yield select(getActiveContractIds);
    yield put(fetching.setFetching(fetching.FETCHING_CONTRACTS_FINANCIAL_OPERATIONS, true));
    const actions =
        activeContracts?.map((idObject) => {
            return call(getContractFinancialOperations, idObject);
        }) || [];
    yield all(actions);
    yield put(fetching.setFetching(fetching.FETCHING_CONTRACTS_FINANCIAL_OPERATIONS, false));
}

function* getContractFinancialOperations(idObject: number) {
    try {
        const productGroup = yield select(createGetProductGroupUrlNameById(idObject));
        if (!productGroup) {
            return;
        }
        const policy = yield select(createGetContractById(Number(idObject)));
        const siteId = yield select(pageContext.getSiteId);
        const locale = yield select(i18n.getLanguageCode);

        let thisMonthMovements: FinancialMovementSimplified[] = [];
        let lastMountMovements: FinancialMovementSimplified[] = [];

        switch (productGroup) {
            case PolicyUrlName.HOME:
                const homeData = yield call(homeApi.getHomeFinancialOperations, idObject);
                thisMonthMovements = mapper.formatNonLifeRows(
                    homeData.filter((row) => helper.isThisMonthMovement(row.get("movementDate"))),
                );
                lastMountMovements = mapper.formatNonLifeRows(
                    homeData.filter((row) => helper.isLastMonthMovement(row.get("movementDate"))),
                );
                break;

            case PolicyUrlName.VEHICLE:
                const vehicleData = yield call(vehicleApi.getVehicleFinancialOperations, idObject);
                thisMonthMovements = mapper.formatNonLifeRows(
                    vehicleData.filter((row) => helper.isThisMonthMovement(row.get("movementDate"))),
                );
                lastMountMovements = mapper.formatNonLifeRows(
                    vehicleData.filter((row) => helper.isLastMonthMovement(row.get("movementDate"))),
                );
                break;

            case PolicyUrlName.LIFE:
                if (policy.isUniqaContract) {
                    const lifeData = yield call(uniqaLifeApi.getLifeFinancialOperations, idObject);
                    thisMonthMovements = mapper.formatUniqaLifeRows(
                        lifeData.filter((row) => helper.isThisMonthMovement(row.get("movementDate"))),
                    );
                    lastMountMovements = mapper.formatUniqaLifeRows(
                        lifeData.filter((row) => helper.isLastMonthMovement(row.get("movementDate"))),
                    );
                } else {
                    const lifeData = yield call(lifeApi.getLifeFinancialOperations, idObject);
                    thisMonthMovements = mapper.formatAxaLifeRows(
                        lifeData.filter((row) => helper.isThisMonthMovement(row.get("valueDate"))),
                        locale,
                    );
                    lastMountMovements = mapper.formatAxaLifeRows(
                        lifeData.filter((row) => helper.isLastMonthMovement(row.get("valueDate"))),
                        locale,
                    );
                }
                break;

            case PolicyUrlName.MUTUAL_FUNDS_DIRECT:
                const directData = yield call(mutualFundsDirectApi.getMutualFundsDirectFinancialOperations, idObject);
                thisMonthMovements = mapper.formatMutualFundsRows(
                    directData.filter((row) => helper.isThisMonthMovement(row.get("valueDate"))),
                    locale,
                );
                lastMountMovements = mapper.formatMutualFundsRows(
                    directData.filter((row) => helper.isLastMonthMovement(row.get("valueDate"))),
                    locale,
                );
                break;

            case PolicyUrlName.MUTUAL_FUNDS_REGULAR:
                const regularData = yield call(mutualFundsRegularApi.getMutualFundsRegularFinancialOperations, idObject);
                thisMonthMovements = mapper.formatMutualFundsRows(
                    regularData.filter((row) => helper.isThisMonthMovement(row.get("valueDate"))),
                    locale,
                );
                lastMountMovements = mapper.formatMutualFundsRows(
                    regularData.filter((row) => helper.isLastMonthMovement(row.get("valueDate"))),
                    locale,
                );
                break;

            case PolicyUrlName.PENSION_PP:
                const pensionPpData = yield call(pensionPpApi.getPensionPpFinancialOperations, idObject);
                thisMonthMovements = mapper.formatPensionPp(
                    pensionPpData.filter((row) => helper.isThisMonthMovement(row.get("valueDate"))),
                );
                lastMountMovements = mapper.formatPensionPp(
                    pensionPpData.filter((row) => helper.isLastMonthMovement(row.get("valueDate"))),
                );
                break;

            // DPS & DDS
            case PolicyUrlName.PENSION_DPS:
                if (siteId === Countries.CZ.id) {
                    const pensionDpsData = yield call(pensionDpsApi.getPensionDpsFinancialOperations, idObject);
                    thisMonthMovements = mapper.formatPensionDps(
                        pensionDpsData.filter((row) => helper.isThisMonthMovement(row.get("valueDate"))),
                    );
                    lastMountMovements = mapper.formatPensionDps(
                        pensionDpsData.filter((row) => helper.isLastMonthMovement(row.get("valueDate"))),
                    );
                } else {
                    const pensionDdsData = yield call(pensionDdsApi.getPensionDdsFinancialOperations, idObject);
                    const filteredDdsData = pensionDdsData.filter((row) =>
                        DDS_QUICK_VIEW_MOVEMENT_TYPES.includes(parseInt(row?.type?.code)),
                    );
                    thisMonthMovements = mapper.formatPensionDssDds(
                        filteredDdsData.filter((row) => helper.isThisMonthMovement(row.get("paymentDate"))),
                        locale,
                    );
                    lastMountMovements = mapper.formatPensionDssDds(
                        filteredDdsData.filter((row) => helper.isLastMonthMovement(row.get("paymentDate"))),
                        locale,
                    );
                }
                break;

            case PolicyUrlName.PENSION_DSS:
                const pensionDssData = yield call(pensionDssApi.getPensionDssFinancialOperations, idObject);
                const filteredDssData = pensionDssData.filter((row) => DSS_QUICK_VIEW_MOVEMENT_TYPES.includes(parseInt(row?.type?.code)));
                thisMonthMovements = mapper.formatPensionDssDds(
                    filteredDssData.filter((row) => helper.isThisMonthMovement(row.get("paymentDate"))),
                    locale,
                );
                lastMountMovements = mapper.formatPensionDssDds(
                    filteredDssData.filter((row) => helper.isLastMonthMovement(row.get("paymentDate"))),
                    locale,
                );
                break;

            default:
                break;
        }

        const financialOperationsOverview: FinancialOperationsOverview = {
            idObject: idObject,
            isFailed: false,
            thisMonthMovements: thisMonthMovements,
            lastMountMovements: lastMountMovements,
        };

        yield put(slice.actions.updateContractFinancialOperations({ idObject, data: financialOperationsOverview }));
    } catch (e) {
        sentry.captureException(e);
        yield put(slice.actions.updateContractFinancialOperations({ idObject, isFailed: true }));
    }
}
