import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Flexbox } from "ui-library/layouts";
import { Button } from "ui-library/atoms";

import i18n from "core/i18n";
import { getFormFieldValue } from "core/form";
import { Pages } from "routeConstants";
import { pageRoutes } from "routeUrls";

import { FIELD_ELECTRONIC_COMMUNICATION } from "../constants";

type ButtonsBoxProps = {
    formName: string;
    handleSubmit: () => void;
};

export const ButtonsBox: FC<ButtonsBoxProps> = ({ formName, handleSubmit }) => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const isElComChecked = useSelector(getFormFieldValue(formName, FIELD_ELECTRONIC_COMMUNICATION));

    return (
        <Flexbox gap={2} justifyContent="space-between">
            <Button onClick={() => navigate(pageRoutes[Pages.CONTRACTS])} variant="outlined" color="blue">
                {t("common.back")}
            </Button>
            <Button color="blue" onClick={handleSubmit} disabled={!isElComChecked}>
                {t("form.electronicCommunication.submit")}
            </Button>
        </Flexbox>
    );
};
