import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import { AgentTab, ClaimsTab, DetailTab, DocumentsTab, FinancialOperationsTab, PolicyHolderTab, OverviewTab } from "./tabs";
import { FullPagePolicyHolderForm } from "./form";

export { default as DetailHomePage } from "./Container";

export const DetailHomeTabs = {
    OverviewTab,
    PolicyHolderTab,
    DetailTab,
    FinancialOperationsTab,
    ClaimsTab,
    DocumentsTab,
    AgentTab,
};

export const DetailHomeForms = {
    FullPagePolicyHolderForm,
};

export default {
    NAME,
    reducer,
    saga,
    innerRoutes,
};
