import React, { FC } from "react";
import { Set } from "immutable";
import { useSelector } from "react-redux";

import { Checkbox, CheckboxGroup } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import userContracts from "core/userContracts";

export interface ContractsPickerProps {
    input: any;
}

export const ContractsPicker: FC<ContractsPickerProps> = ({ input }) => {
    const selectedContracts = input.value || Set();

    const contractsWithoutElCom = useSelector(userContracts.getContractsWithoutElCom);

    const onChange = (contractId: number) =>
        selectedContracts.includes(contractId)
            ? input.onChange(selectedContracts.delete(contractId))
            : input.onChange(selectedContracts.add(contractId));

    return (
        <Box>
            <CheckboxGroup direction={"column"}>
                {contractsWithoutElCom.map((contract) => (
                    <Checkbox
                        key={contract.idObject}
                        id={`id_${contract.idObject}`}
                        hasGroup
                        label={`${contract.productGroup}, ${contract.contractNumber}`}
                        onChange={() => onChange(contract.idObject)}
                        checked={selectedContracts.includes(contract.idObject)}
                    />
                ))}
            </CheckboxGroup>
        </Box>
    );
};
