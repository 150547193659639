import createModel from "../createModel";

export const AccountInformation = createModel("AccountInformation", (json) => ({
    username: json.Username,
    lastPasswordChange: json.LastPasswordChange,
    lastLogin: json.LastLogin,
    lastLoginFailed: json.LastLoginFailed,
    failedLoginsCount: json.FailedLoginsCount,
    userEnabled: json.UserEnabled,
    isPasswordExpired: json.IsPasswordExpired,
    authorizationType: json.AuthorizationType,
    impersonator: json.Impersonator,
}));
