import { all, fork, put } from "redux-saga/effects";
import router from "core/router";
import { ActivateAccount } from "./modules";

export default router.routerWrapper({
    *onPageEnter() {
        yield all([
            fork(ActivateAccount.saga),
            put(ActivateAccount.cleanEpinIssueError()),
            put(ActivateAccount.setEpinPhase(ActivateAccount.EPIN_PHASE_ISSUE)),
        ]);
    },
});
