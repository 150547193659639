import slice from "./slice";
import * as selectors from "./selectors";
import { UnsuccessfulReason, LogoutAction } from "./constants";
import saga, { ssoLoginSaga } from "./saga";

const auth = {
    NAME: slice.name,
    reducer: slice.reducer,
    ...slice.actions,
    ...selectors,

    UnsuccessfulReason,
    LogoutAction,
    ssoLoginSaga,
    saga,
};

export default auth;
