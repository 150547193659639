import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import { dateTimeToLocalFullDate, localAmountNoDecimalWithCurrencyLegacy } from "core/formatters";
import i18n from "core/i18n";
import fetching from "core/fetching";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";
import { EditLinksDropdown } from "containers/EditLinksDropdown";

import { getFinancialStatementEntity } from "../selectors";
import { ENTITY_FINANCIAL_STATEMENT, FORM_EDIT_CONTRACT_NAME } from "../constants";

export const ContractDataGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const hasEditPermission = useHasEditContractPermission();

    const statement = useSelector(getFinancialStatementEntity);
    const isLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const actualInvestments = isLoading
        ? fetching.fetchingString
        : localAmountNoDecimalWithCurrencyLegacy(statement.get("actualValueOfInvestments"));

    const editLinks = [
        {
            formConst: FORM_EDIT_CONTRACT_NAME,
            name: "common.customContractNameFull",
        },
    ];

    return (
        <GridItem xs={12}>
            <InfoPanel
                icon={"contract"}
                title={t("common.contract")}
                headerAction={hasEditPermission && <EditLinksDropdown links={editLinks} />}
            >
                <Box>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    {contract?.endDate && <LabelTextItem label={t("common.end")} text={dateTimeToLocalFullDate(contract.endDate)} />}
                    <LabelTextItem label={t("account.state")} text={actualInvestments} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
