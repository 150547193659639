import { call, fork, put, select, takeEvery } from "redux-saga/effects";

import modal from "core/modal";
import router from "core/router";
import entity from "core/entity";
import userContracts from "core/userContracts";

import { ProductGroupLvl2 } from "enums";
import { Params, Tabs } from "routeConstants";
import { documentsApi, mutualFundsDirectApi, paymentApi } from "serverApi";

// forms
import activeOperationsPhoneNumberForm from "form/modal/activeOperationsPhoneNumber";
import contractNameForm from "form/modal/contractName";
import mutualFundsSellSwitch from "form/mutualFundsSellSwitch";
import mutualFundsClientStatementForm from "form/modal/clientStatementSettings";
import policyHolderForm from "form/fullPage/policyHolder";

import { clear, SEND_ENQUEUE_REQUEST, setMutualFundsDirectContractDetail } from "./actions";
import {
    getActiveOperationsPhoneNumber,
    getClientStatementSettings,
    getElectronicCommunication,
    getIdObject,
    getMutualFundsFinancialRequest,
    getPolicy,
    getPolicyHolder,
} from "./selectors";
import {
    ActualStatements,
    ChangePropositionModule,
    ExtraStatements,
    FinancialOperationsTableFilter,
    OtpModule,
    YearStatements,
} from "./modules";
import {
    BUY_SELL_CONFIRMATION,
    ENTITY_CHANGE_PROPOSITIONS,
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_ENQUEUED_DOCUMENTS,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_REQUESTS,
    ENTITY_FINANCIAL_STATEMENT,
    ENTITY_PAYMENT_INFORMATION,
    FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER,
    FORM_EDIT_CLIENT_STATEMENT_SETTINGS,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_POLICY_HOLDER,
    FORM_SELL_SWITCH,
    INCLUDE_BUY_SELL_CONFIRMATION,
    MODAL_ENTITY_PRINT_CONFIRMATION,
} from "./constants";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const contractDetail = yield call(mutualFundsDirectApi.getMutualFundsDirectContractDetail, idObject);
        return [setMutualFundsDirectContractDetail(contractDetail)];
    },
    *onPageEnter({ idObject }) {
        yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => mutualFundsDirectApi.getMutualFundsDirectFinancialOperations(idObject));
        yield fork(entity.load, ENTITY_FINANCIAL_STATEMENT, () => mutualFundsDirectApi.getMutualFundsDirectFinancialStatement(idObject));
        yield fork(entity.load, ENTITY_FINANCIAL_REQUESTS, () => mutualFundsDirectApi.getMutualFundsDirectFinancialRequests(idObject));
        yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
        yield fork(entity.load, ENTITY_ENQUEUED_DOCUMENTS, () => documentsApi.getContractEnqueuedDocuments(idObject));
        yield fork(loadPaymentInformation, idObject);

        yield fork(YearStatements.saga);
        yield fork(ExtraStatements.saga);
        yield fork(ActualStatements.saga);
        yield fork(OtpModule.saga);
        yield fork(ChangePropositionModule.sagaDataInitialization, idObject);
        yield fork(FinancialOperationsTableFilter.saga);
    },
    *onInnerRouteChange(name, params, query) {
        switch (name) {
            case Tabs.FINANCIAL_STATEMENT:
                yield put(modal.closeModalForm(MODAL_ENTITY_PRINT_CONFIRMATION));
                yield takeEvery(SEND_ENQUEUE_REQUEST, sendEnqueueRequestSaga);
                break;
            case Tabs.AGENT:
                yield call(ChangePropositionModule.saga, params.idObject);
                break;
            case Tabs.EDIT_POLICY_HOLDER_FORM:
                yield call(contractEditHolderFormSaga, params.idObject);
                break;
            case Tabs.SELL_SWITCH_FORM:
                yield call(fundsSellSwitchFormSaga, params.idObject, query[Params.CHANGE_PROPOSITION_ID]);
                break;
            case Tabs.SELL_SWITCH_FORM_WITH_ACTION:
                yield call(fundsSellSwitchFormSaga, params.idObject, params.requestId, params.actionType);
                break;
            default:
                break;
        }
    },
    *onModalOpen(modalName) {
        const idObject = yield select(getIdObject);
        switch (modalName) {
            case FORM_EDIT_CONTRACT_NAME:
                yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
                break;
            case FORM_EDIT_CLIENT_STATEMENT_SETTINGS:
                yield call(
                    mutualFundsClientStatementForm.createSaga,
                    FORM_EDIT_CLIENT_STATEMENT_SETTINGS,
                    idObject,
                    getClientStatementSettings,
                    INCLUDE_BUY_SELL_CONFIRMATION,
                );
                break;
            case FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER:
                yield call(
                    activeOperationsPhoneNumberForm.createSaga,
                    FORM_EDIT_ACTIVE_OPERATIONS_PHONE_NUMBER,
                    idObject,
                    getActiveOperationsPhoneNumber,
                    OtpModule.setResent,
                    OtpModule.setPhoneNumber,
                );
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_ENQUEUED_DOCUMENTS,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_FINANCIAL_STATEMENT,
                ENTITY_FINANCIAL_REQUESTS,
                ENTITY_PAYMENT_INFORMATION,
                ENTITY_CHANGE_PROPOSITIONS,
                MODAL_ENTITY_PRINT_CONFIRMATION,
            ),
        ];
    },
});

function* loadPaymentInformation(idObject) {
    const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
    yield call(entity.load, ENTITY_PAYMENT_INFORMATION, () => paymentApi.get(productGroup, idObject));
}

const contractEditHolderFormSaga = policyHolderForm.createSaga(
    FORM_EDIT_POLICY_HOLDER,
    getPolicyHolder,
    getPolicy,
    getElectronicCommunication,
);

const fundsSellSwitchFormSaga = mutualFundsSellSwitch.createSaga(
    FORM_SELL_SWITCH,
    OtpModule.setResent,
    OtpModule.setPhoneNumber,
    ProductGroupLvl2.MUTUAL_FUNDS,
    ChangePropositionModule.getChangeProposition,
    getMutualFundsFinancialRequest,
    loadChangePropositions,
    function* () {
        yield put(OtpModule.clear(FORM_SELL_SWITCH));
    },
);

function* loadChangePropositions(idObject) {
    yield fork(ChangePropositionModule.sagaDataInitialization, idObject);
}

function* sendEnqueueRequestSaga({ payload }) {
    yield put(modal.openModalForm(MODAL_ENTITY_PRINT_CONFIRMATION));
    yield call(entity.load, MODAL_ENTITY_PRINT_CONFIRMATION, () =>
        documentsApi.generateAndEnqueueDocument(payload.idObject, BUY_SELL_CONFIRMATION, { idOperation: payload.idOperation }),
    );
    yield call(entity.periodicallyCheckDocuments, 250, ENTITY_ENQUEUED_DOCUMENTS, () =>
        documentsApi.getContractEnqueuedDocuments(payload.idObject),
    );
}
