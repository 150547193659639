import innerRoutes from "./innerRoutes";
import saga from "./saga";
import { NAME } from "./constants";
import { FullPageElectronicCommunicationForm } from "./form";

export { EditPageContainer as EditPage } from "./Container";

export const EditForms = {
    FullPageElectronicCommunicationForm,
};

export default {
    NAME,
    innerRoutes,
    saga,
};
