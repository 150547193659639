import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Immutable from "immutable";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import { dateTimeToLocalFullDate } from "core/formatters";
import i18n from "core/i18n";
import localized from "core/localized";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";
import pageContext from "core/pageContext";

import { getActualPaymentScheduler } from "../selectors";
import { FORM_EDIT_CONTRACT_NAME, FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";
import { FeatureFlag } from "types";
import { EditLinksDropdown } from "containers/EditLinksDropdown";

export const ContractDataGridItem = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    const actualScheduler = useSelector(getActualPaymentScheduler);
    // @ts-ignore
    const scheduler = Immutable.Iterable.isIterable(actualScheduler) ? actualScheduler.first() : actualScheduler;
    const formattedSchedulerPayment = useSelector(localized.getLocalizedSchedulerPaymentFullLegacy(scheduler, false, true));

    const isContractEditable = contract.canEdit;
    const hasEditPermission = useHasEditContractPermission();
    const isHarmonizedContractsEditEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.HARMONIZED_CONTRACTS_EDIT_ENABLED);

    const editLinks = () => {
        let links = [];
        links.push({
            formConst: FORM_EDIT_CONTRACT_NAME,
            name: "common.customContractNameFull",
        });
        if (isContractEditable && isHarmonizedContractsEditEnabled) {
            links.push({
                formConst: FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
                name: "form.policyHolderPayments",
            });
        }
        return links;
    };

    return (
        <GridItem display={"flex"} xs={12}>
            <InfoPanel
                icon={"contract"}
                title={t("common.contract")}
                headerAction={hasEditPermission && <EditLinksDropdown links={editLinks()} />}
            >
                <Box>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    <LabelTextItem
                        label={t("insurance.insuranceTo")}
                        text={contract?.endDate ? dateTimeToLocalFullDate(contract.endDate) : t("common.unlimitedDate")}
                    />
                    {!isInFutureOrTerminated && <LabelTextItem label={t("insurance.insuranceCost")} text={formattedSchedulerPayment} />}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
