export const NAME = "page-detail-life";
export const ONE_TIME_PAYMENT_CONTRACTS_ENU_PG_LVL2 = [4, 26];

// Entity
export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_FINANCIAL_STATEMENT = `${NAME}/ENTITY_FINANCIAL_STATEMENT`;
export const ENTITY_CONTRACT_INCIDENTS = `${NAME}/ENTITY_CONTRACT_INCIDENTS`;
export const ENTITY_PAYMENT_INFORMATION = `${NAME}/ENTITY_PAYMENT_INFORMATION`;
export const ENTITY_TAX_CERTIFICATE_AVAILABLE_YEARS = `${NAME}/ENTITY_TAX_CERTIFICATE_AVAILABLE_YEARS`;
export const ENTITY_CONTRACT_OVERVIEW = `${NAME}/ENTITY_CONTRACT_OVERVIEW`;

// Entity state
export const ENTITY_CONTRACT_INCIDENTS_OPEN_STATE = `${NAME}/ENTITY_CONTRACT_INCIDENTS_OPEN_STATE`;

// Form
export const FORM_EDIT_POLICY_HOLDER = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_CONTRACT_OWNER_PAYMENTS = `${NAME}/FORM_EDIT_CONTRACT_OWNER_PAYMENTS`;

// Statement tabs
export const STATEMENT_TAB_AXA = {
    BASED_ON_FUNDS: 0,
    BASED_ON_TYPE: 1,
    INVESTMENT_STRATEGY: 2,
};

export const STATEMENT_TAB_UNIQA = {
    BASED_ON_FUNDS: 0,
    INVESTMENT_STRATEGY: 1,
};

// Columns
export const VALUE_DATE_COLUMN = "valueDate";
export const TYPE_COLUMN = "type";
export const AMOUNT_COLUMN = "amount";
