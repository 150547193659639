import { call, fork, select } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import userContracts from "core/userContracts";
import modal from "core/modal";

import contractNameForm from "form/modal/contractName";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import policyHolderForm from "form/fullPage/policyHolder";

import { Tabs } from "routeConstants";
import { contractsApi, documentsApi, paymentApi, travelApi } from "serverApi";

import { clear, setTravelContractDetail } from "./actions";
import {
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_CONTRACT_INCIDENTS,
    ENTITY_CONTRACT_OVERVIEW,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_STATEMENT,
    ENTITY_PAYMENT_INFORMATION,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    FORM_EDIT_POLICY_HOLDER,
} from "./constants";
import { getActualSchedulerRecord, getElectronicCommunication, getIdObject, getPolicy, getPolicyHolder } from "./selectors";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const contractDetail = yield call(travelApi.getTravelContractDetail, idObject);

        return [setTravelContractDetail(contractDetail)];
    },
    *onPageEnter({ idObject }) {
        yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => travelApi.getTravelFinancialOperations(idObject));
        yield fork(entity.load, ENTITY_CONTRACT_INCIDENTS, () => travelApi.getTravelIncidents(idObject));
        yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
        const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
        yield fork(entity.load, ENTITY_CONTRACT_OVERVIEW, () => contractsApi.getContractOverview(productGroup, idObject));
        yield fork(loadPaymentInformation, idObject);
        yield fork(entity.periodicallyCheckClaims, 60 * 1000, ENTITY_CONTRACT_INCIDENTS, () => travelApi.getTravelIncidents(idObject));
    },
    *onInnerRouteChange(name, params) {
        switch (name) {
            case Tabs.EDIT_POLICY_HOLDER_FORM:
                yield call(contractEditHolderFormSaga, params.idObject);
                break;
            default:
                break;
        }
    },
    *onModalOpen(modalName) {
        const idObject = yield select(getIdObject);

        switch (modalName) {
            case FORM_EDIT_CONTRACT_NAME:
                yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
                break;
            case FORM_EDIT_CONTRACT_OWNER_PAYMENTS:
                yield call(
                    contractOwnerPaymentsForm.createSagaLegacy,
                    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
                    idObject,
                    getActualSchedulerRecord,
                );
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_FINANCIAL_STATEMENT,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_CONTRACT_INCIDENTS,
                ENTITY_PAYMENT_INFORMATION,
            ),
        ];
    },
});

function* loadPaymentInformation(idObject) {
    const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
    yield call(entity.load, ENTITY_PAYMENT_INFORMATION, () => paymentApi.get(productGroup, idObject));
}

const contractEditHolderFormSaga = policyHolderForm.createSagaLegacy(
    FORM_EDIT_POLICY_HOLDER,
    getPolicyHolder,
    getPolicy,
    getElectronicCommunication,
);
