import { call, put, select, takeEvery } from "redux-saga/effects";
import { resetSection } from "redux-form/immutable";

import errorHandling from "core/errorHandling";
import { asyncFieldValidator, asyncValidateFields, fieldChangeMatcher, formWrapper, getPathFromFieldName, required } from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { contractDataChangeApi, validationApi } from "serverApi";

import { EMPLOYER_NAME, ENABLE_EMPLOYER_CONTRIBUTION, IDENTIFICATION_NUMBER } from "./constants";

export function* createSagaLegacy(formName, idObject, getAccountDetail) {
    try {
        yield call(formSaga(formName, getAccountDetail), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, getAccountDetail) =>
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, ENABLE_EMPLOYER_CONTRIBUTION), enabledChangedSaga, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));

            const detail = yield select(getAccountDetail);
            return {
                [ENABLE_EMPLOYER_CONTRIBUTION]: detail.employerPayments.enabled,
                [IDENTIFICATION_NUMBER]: detail.employerPayments.getIn(["payer", "companyID"]),
                [EMPLOYER_NAME]: detail.employerPayments.getIn(["payer", "companyName"]),
            };
        },
        *save(values, idObject) {
            const isContributionEnabled = values.get(ENABLE_EMPLOYER_CONTRIBUTION);
            const requestBody = {
                idObject,
                enableEmployerContribution: isContributionEnabled,
                employerName: isContributionEnabled ? values.get(EMPLOYER_NAME) : null,
                identificationNumber: isContributionEnabled ? values.get(IDENTIFICATION_NUMBER) : null,
            };
            yield call(contractDataChangeApi.updateEmployerPayments, requestBody);
        },
        *asyncValidation(values, field) {
            const asyncFieldValidators = [];
            if (values.getIn(getPathFromFieldName(ENABLE_EMPLOYER_CONTRIBUTION))) {
                asyncFieldValidators.push(
                    asyncFieldValidator(getPathFromFieldName(IDENTIFICATION_NUMBER), [
                        required,
                        validationApi.validateEmployerIdentificationNumber,
                    ]),
                );
            }
            return yield call(asyncValidateFields(...asyncFieldValidators), field, values);
        },
    });

function* enabledChangedSaga(formName) {
    yield put(resetSection(formName, EMPLOYER_NAME, IDENTIFICATION_NUMBER));
}
