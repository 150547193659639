import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { Container } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";
import lifeImage from "ui-library/assets/images/life-pozadie.svg";

import i18n from "core/i18n";
import localized from "core/localized";
import router from "core/router";
import { fn } from "core/util";
import userContracts from "core/userContracts";

import { ContractHeader, ProductDetailPageLayout } from "containers/contract";
import { AppSidemenuLink } from "containers/link";
import { PageBoundary } from "containers/PageBoundary";

import { Tabs } from "routeConstants";

import { createGetActualPaymentScheduler } from "./selectors";

export const DetailLifePage: FC = () => {
    const { t } = i18n.useTranslation();
    const isPageLoading = useSelector(router.getActivePageIsLoading);
    const policy = userContracts.useGetContractByUrlParam();
    const actualScheduler = useSelector(createGetActualPaymentScheduler);
    const formattedSchedulerPayment = useSelector(
        localized.getLocalizedSchedulerPaymentFullLegacy(fn.getItemOrFirstItemWhenIterable(actualScheduler), false, true),
    );

    const lobData = {
        label: t("insurance.insuranceCost"),
        value: formattedSchedulerPayment,
    };

    return (
        <ProductDetailPageLayout>
            <Container>
                <ContractHeader backgroundImage={lifeImage} policy={policy} lobData={lobData} loading={isPageLoading} />

                <PageBoundary>
                    <SubPageWrapper>
                        <Sidemenu>
                            <AppSidemenuLink tab={Tabs.OVERVIEW} title={t("menu.tab.overview")} />
                            <AppSidemenuLink tab={Tabs.HOLDER} title={t("menu.tab.holder.insured")} />
                            <AppSidemenuLink tab={Tabs.DETAIL} title={t("menu.tab.detail")} />
                            <AppSidemenuLink tab={Tabs.FINANCIAL_OPERATIONS} title={t("menu.tab.statementsAndPayments")} />
                            <AppSidemenuLink tab={Tabs.CLAIMS} title={t("menu.tab.claims")} />
                            <AppSidemenuLink tab={Tabs.DOCUMENTS} title={t("menu.tab.documents")} />
                            <AppSidemenuLink tab={Tabs.AGENT} title={t("menu.tab.agent")} />
                        </Sidemenu>

                        <Outlet />
                    </SubPageWrapper>
                </PageBoundary>
            </Container>
        </ProductDetailPageLayout>
    );
};

export default DetailLifePage;
