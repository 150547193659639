import { Locales, Site } from "./pageContext";

/**
 * Url definition for external links.
 * Link is defined per site. For site can be defined as string or
 * as object for cases when link is different based on location.
 **/
export const ExternalUrl = {
    UNIQA: { [Site.CZ]: "https://www.uniqa.cz", [Site.SK]: "https://www.uniqa.sk" },
    E_REGISTRY: { [Site.CZ]: "https://epodatelna.uniqa.cz", [Site.SK]: "https://epodatelna.uniqa.sk/" },
    ASSISTANCE_SERVICES: {
        [Site.CZ]: {
            [Locales.cs_CZ.id]: "https://www.uniqa.cz/kontakty/#asistence",
            [Locales.en_US.id]: "https://www.uniqa.cz/en/contacts/",
        },
        [Site.SK]: "https://www.uniqa.sk/kontakt/",
    },
    BRANCHES_MAP: {
        [Site.CZ]: {
            [Locales.cs_CZ.id]: "https://www.uniqa.cz/kontakty/#heading-2",
            [Locales.en_US.id]: "https://www.uniqa.cz/en/contacts/#heading-2",
        },
        [Site.SK]: "https://www.uniqa.sk/kontakt/#heading-2",
    },
    YOUTUBE: { [Site.CZ]: "https://www.youtube.com/user/UNIQAcz", [Site.SK]: "https://www.youtube.com/c/UNIQASlovensko" },
    FACEBOOK: { [Site.CZ]: "https://www.facebook.com/UNIQACesko", [Site.SK]: "https://www.facebook.com/UNIQASlovensko" },
    TWITTER: { [Site.CZ]: "https://twitter.com/uniqa_cz_sk", [Site.SK]: "https://twitter.com/uniqa_cz_sk" },
    DISCLAIMER_PUBLIC: { [Site.CZ]: "https://www.uniqa.cz/osobni-udaje/", [Site.SK]: "https://www.uniqa.sk/osobne-udaje/" },
    PATALIE: { [Site.CZ]: "https://patalie.cz", [Site.SK]: "https://patalie.sk" },
    PATALIE_TITLE: { [Site.CZ]: "patalie.cz", [Site.SK]: "patalie.sk" },
};

export const CallCenterContactData = {
    EMAIL: { [Site.CZ]: "info@uniqa.cz", [Site.SK]: "info@uniqa.sk" },
    PHONE: { [Site.CZ]: "(+420) 488 125 125", [Site.SK]: "(+421) 232 600 100" },
    PHONE_AS_LINK: { [Site.CZ]: "+420488125125", [Site.SK]: "+421232600100" },
};

export const PortalData = {
    PORTAL_NAME: { [Site.CZ]: "moje UNIQA", [Site.SK]: "moja UNIQA" },
};
