import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ModalBody, ModalFooter } from "ui-library/modules";
import { Box, ContentBox } from "ui-library/layouts";
import { Button, Loading } from "ui-library/atoms";

import { ModalFormLayout } from "containers/modal";
import {
    Field,
    form,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    lettersNumbersSpecialCharactersExtended,
    max10Characters,
    max50Characters,
    numberCharacters,
    required,
    SegmentedControl,
} from "core/form";
import i18n from "core/i18n";
import pageContext from "core/pageContext";

import { EMPLOYER_NAME, ENABLE_EMPLOYER_CONTRIBUTION, IDENTIFICATION_NUMBER } from "./constants";
import { FeatureFlag } from "types";

const EmployerPaymentsForm = ({ canSubmit, handleSubmit, submitting, employerPaymentsValue }) => {
    const { t } = i18n.useTranslation();
    const isSpecialCharactersEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.EDIT_COMPANY_NAME_SPECIAL_CHARACTERS);
    return (
        <>
            <ModalBody>
                <Loading loading={submitting}>
                    <Box>
                        <ContentBox>
                            <Field name={ENABLE_EMPLOYER_CONTRIBUTION} component={SegmentedControl} label={t("form.employerPayments")} />
                        </ContentBox>
                    </Box>

                    {employerPaymentsValue && (
                        <>
                            <Box>
                                <ContentBox>
                                    <Field
                                        name={IDENTIFICATION_NUMBER}
                                        component={InputText}
                                        label={t("holder.identificationNumber")}
                                        // validated async in saga!
                                        normalize={[numberCharacters, max10Characters]}
                                        onEnter={handleSubmit}
                                    />
                                </ContentBox>
                            </Box>

                            <Box>
                                <ContentBox>
                                    <Field
                                        name={EMPLOYER_NAME}
                                        component={InputText}
                                        label={t("holder.employerName")}
                                        validateStatic={[required]}
                                        normalize={[
                                            isSpecialCharactersEnabled
                                                ? lettersNumbersSpecialCharactersExtended
                                                : lettersNumbersBasicInterpunctionCharacters,
                                            max50Characters,
                                        ]}
                                        onEnter={handleSubmit}
                                    />
                                </ContentBox>
                            </Box>
                        </>
                    )}
                </Loading>
            </ModalBody>
            <ModalFooter>
                <Button color="blue" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
                    {t("common.submitChange")}
                </Button>
            </ModalFooter>
        </>
    );
};

EmployerPaymentsForm.propTypes = {
    canSubmit: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    employerPaymentsValue: PropTypes.bool,
};

EmployerPaymentsForm.defaultProps = {
    employerPaymentsValue: false,
};

export const createContainer = (formName) =>
    form(formName, { asyncValidationFields: [IDENTIFICATION_NUMBER] })((props) => {
        const { t } = i18n.useTranslation();
        const employerPaymentsValue = useSelector(getFormFieldValue(formName, ENABLE_EMPLOYER_CONTRIBUTION));

        return (
            <ModalFormLayout formName={formName} formTitle={t("form.employerPayments")} formIcon="circle-dollar">
                <EmployerPaymentsForm employerPaymentsValue={employerPaymentsValue} {...props} />
            </ModalFormLayout>
        );
    });
