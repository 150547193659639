import { FC } from "react";

import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";

export const ModalContractOwnerPaymentsFormContainer = contractOwnerPaymentsForm.createContainer(
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    "form.paymentsScheduler.pension",
);

export const ModalContractOwnerPaymentsForm: FC = () => <ModalContractOwnerPaymentsFormContainer showPaymentType={false} />;
