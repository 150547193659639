import { List } from "immutable";
import { useParams } from "react-router-dom";

import { PaymentContribution } from "enums";
import { FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import i18n from "core/i18n";
import userContracts from "core/userContracts";

export const ModalContractOwnerPaymentsFormContainer = contractOwnerPaymentsForm.createContainer(
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    "form.paymentsScheduler.nonLife",
);

export const ModalContractOwnerPaymentsForm = () => {
    const { t } = i18n.useTranslation();

    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);

    const lifePaymentOptions = List([PaymentContribution.ByPostalOrder.id, PaymentContribution.ByBankAccount.id]);

    return (
        <ModalContractOwnerPaymentsFormContainer
            isUniqaContract={contract?.isUniqaContract}
            canEditAmount={false}
            amountLabel={t("insurance.payments.amount")}
            paymentOptionsUniqaExAxa={lifePaymentOptions}
            displayDisclaimer
        />
    );
};
