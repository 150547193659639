// ensures that changed fields are displayed in the correct order
export const VISIBLE_CHANGED_FIELDS = [
    "person",
    "companyName",
    "permanentAddress",
    "contactAddress",
    "identificationCard",
    "mobile",
    "email",
    "electronicCommunication",
];
