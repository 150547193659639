import { call } from "redux-saga/effects";

import router from "core/router";
import electronicCommunicationForm from "form/fullPage/electronicCommunication";
import { Tabs } from "routeConstants";

import { FORM_EDIT_ELECTRONIC_COMMUNICATION } from "./constants";

export default router.routerWrapper({
    *onInnerRouteChange(name, params) {
        yield call(routeChangedSaga, name, params);
    },
});

function* routeChangedSaga(name) {
    switch (name) {
        case Tabs.FORM_ELECTRONIC_COMMUNICATION: {
            yield call(electronicCommunicationForm.createSaga, FORM_EDIT_ELECTRONIC_COMMUNICATION);
            break;
        }
        default:
            break;
    }
}
