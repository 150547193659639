import React, { FC } from "react";

import { Pages, Tabs } from "routeConstants";
import { BeneficiariesGridItem } from "containers/contract";

import { getBeneficiaries, getIdObject } from "../selectors";

export const PensionPpBeneficiariesGridItem: FC = () => (
    <BeneficiariesGridItem
        getIdObject={getIdObject}
        getBeneficiaries={getBeneficiaries}
        tooltipTextKey="pension.beneficiariesInfoText.CZ"
        isSlovakAndContainCompanyID={false}
        editFormRouteArray={[Pages.CONTRACT_PP, Tabs.PENSION_BENEFICIARIES_FORM]}
    />
);
