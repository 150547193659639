import { combineReducers } from "redux-immutable";

import { app } from "core/util";

import { CLEAR, SET_DETAIL_SELECTED_COVERAGE, SET_VEHICLE_CONTRACT_DETAIL } from "./actions";
import { HarmonizedContract } from "model/harmonized";

export default combineReducers({
    data: combineReducers({
        vehicleContractDetail: app.createDataReducer(SET_VEHICLE_CONTRACT_DETAIL, HarmonizedContract.fromServer(), CLEAR),
    }),
    appState: combineReducers({
        detailSelectedCoverage: app.createDataReducer(SET_DETAIL_SELECTED_COVERAGE, "", CLEAR),
    }),
});
