import React, { FC } from "react";

import { Pages, Tabs } from "routeConstants";
import { BeneficiariesGridItem } from "containers/contract";

import { getBeneficiaries, getIdObject } from "../selectors";

export const Beneficiaries: FC = () => (
    <BeneficiariesGridItem
        getIdObject={getIdObject}
        getBeneficiaries={getBeneficiaries}
        tooltipTextKey="pension.beneficiariesInfoText.SK.dds"
        isSlovakAndContainCompanyID
        editFormRouteArray={[Pages.CONTRACT_DDS, Tabs.PENSION_BENEFICIARIES_FORM]}
    />
);
