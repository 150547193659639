import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { List } from "immutable";

import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel } from "ui-library/modules";

import i18n from "core/i18n";

import { HarmonizedPaymentSchedulerDataTable } from "./HarmonizedPaymentSchedulerDataTable";
import { ContributionsEditDropdown } from "./dropdown/ContributionsEditDropdown";

export const HarmonizedActualPaymentOnlySchedulerPanel = ({ actualScheduler, hasEditPermission, modalFormName }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    return (
        <GridItem display={"flex"} xs={12} lg={7}>
            <InfoPanel
                icon={"contract"}
                title={t("insurance.actualPaymentScheduler")}
                headerAction={hasEditPermission && <ContributionsEditDropdown modalContractOwnerPaymentsFormName={modalFormName} />}
            >
                {actualScheduler instanceof List &&
                    actualScheduler.map((scheduler) => (
                        <Box key={scheduler.reactKey} mb={3}>
                            <HarmonizedPaymentSchedulerDataTable schedulerData={scheduler} />
                        </Box>
                    ))}
            </InfoPanel>
        </GridItem>
    );
};

HarmonizedActualPaymentOnlySchedulerPanel.propTypes = {
    actualScheduler: PropTypes.object.isRequired,
    modalFormName: PropTypes.string.isRequired,
    hasEditPermission: PropTypes.bool,
};

HarmonizedActualPaymentOnlySchedulerPanel.defaultProps = {
    hasEditPermission: false,
};
