import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import userContracts from "core/userContracts";
import { GenerateStatementGridItem, MutualFundsStatementSettings } from "containers/contract";
import { Pages, Tabs } from "routeConstants";

import { ActualStatements, ExtraStatements, YearStatements } from "../modules";
import { MovementsOverviewGridItem } from "../containers";
import { ModalPrintConfirmation } from "../containers/modal";
import { getClientStatementSettings, getIsDipContract, isElectronicCommunicationSet } from "../selectors";
import { ModalClientStatementSettingsForm } from "../form";
import { FORM_EDIT_CLIENT_STATEMENT_SETTINGS } from "../constants";

const getStatementOptions = (t: TFunction, setStatement, isDipContract: boolean) => {
    const statementOptions = [
        setStatement(t("statements.extraStatement"), ExtraStatements),
        setStatement(t("statements.actualStatement"), ActualStatements),
    ];
    if (!isDipContract) {
        statementOptions.unshift(setStatement(t("statements.yearlyStatement"), YearStatements));
    }
    return statementOptions;
};

export const StatementsAndPaymentsTab: FC = () => {
    const { t } = i18n.useTranslation();
    const { setStatement } = GenerateStatementGridItem;
    const isElcomSet = useSelector(isElectronicCommunicationSet);
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    const isDipContract = useSelector(getIsDipContract);

    return (
        <GridLayout defaultSpacing>
            <MovementsOverviewGridItem />

            <GenerateStatementGridItem statements={getStatementOptions(t, setStatement, isDipContract)} />

            {!isInFutureOrTerminated && (
                <MutualFundsStatementSettings
                    isElComSet={isElcomSet}
                    getClientStatementSettings={getClientStatementSettings}
                    clientStatementSettingsFormName={FORM_EDIT_CLIENT_STATEMENT_SETTINGS}
                    ModalClientStatementSettingsForm={ModalClientStatementSettingsForm}
                    editPolicyHolderLink={router.getStaticUrl(Pages.CONTRACT_DIRECT, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject })}
                    isContractEditable={contract.canEdit}
                />
            )}

            {/* Modals */}
            <ModalPrintConfirmation />
        </GridLayout>
    );
};
