import React, { FC } from "react";
import { useSelector } from "react-redux";

import { SidemenuLink } from "ui-library/atoms";

import pageContext from "core/pageContext";
import router from "core/router";
import { Tabs } from "routeConstants";
import { innerRoutes } from "routeUrls";

type AppSidemenuLinkProps = {
    tab: Tabs;
    title: string;
};

export const AppSidemenuLink: FC<AppSidemenuLinkProps> = ({ tab, title }) => {
    const locale = useSelector(pageContext.getLocale);
    const toRoute = router.enhanceUrlWithLangParam(innerRoutes[tab], locale);

    return (
        <SidemenuLink id={tab} to={toRoute}>
            {title}
        </SidemenuLink>
    );
};
