import { all, call, cancel, delay, fork, getContext, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { stringify } from "query-string";
import { Set } from "immutable";

import auth from "core/auth";
import modal from "core/modal";
import pageContext from "core/pageContext";
import { sentry } from "core/util";
import { pageRoutes } from "routeUrls";
import { Pages } from "routeConstants";

import slice from "./slice";
import { getPageInfo } from "./selectors";
import { getTopLevelRoutePackage, getTopPathParamNames } from "./staticRouteRegister";
import { getStaticUrl, parseLocation, routerWrapper } from "./util";
import { PageInformation, LANG_PARAM } from "./constants";

export function* routerSaga() {
    // @ts-ignore
    yield takeEvery(slice.actions.navigateExternal.type, onNavigateExternal);
    // @ts-ignore
    yield takeEvery(slice.actions.navigateSsoLogin.type, onNavigateSsoLogin);
    // @ts-ignore
    yield takeEvery(slice.actions.navigate.type, onNavigate);
    yield takeEvery(slice.actions.goBack.type, onBack);
    yield takeLatest([auth.logIn.type, auth.setLogged.type], sessionTimeoutCheck);
}

// it seems browser will trigger initial path in listen too, but for initial page load, we want to call setPageByLocationDirectly
export function* startRouting() {
    const historyChannel = yield call(createHistoryChannel);
    yield takeEvery(historyChannel, loadPage);
    yield takeLatest(modal.openModalForm.type, displayModalForm);
}

export function* setPageByLocationDirectly(location: Location) {
    const history = yield getContext("history");
    const resolved = parseLocation(location?.pathname, location?.search);

    if (resolved && !resolved.redirect) {
        yield call(loadPage, resolved);
    } else if (resolved && resolved.redirect) {
        yield call([history, history.push], resolved.redirect, { replace: true });
        const resolvedAfterRedirect = parseLocation(resolved.redirect, "");
        yield call(loadPage, resolvedAfterRedirect);
    } else {
        yield call([history, history.push], pageRoutes[Pages.CONTRACTS], { replace: true });
        const resolvedAfterRedirect = parseLocation(pageRoutes[Pages.CONTRACTS], "");
        yield call(loadPage, resolvedAfterRedirect);
    }
}

function* createHistoryChannel() {
    const history = yield getContext("history");
    return eventChannel((emitter) => {
        const unlisten = history.listen(({ location }) => {
            const resolved = parseLocation(location?.pathname, location?.search);
            if (resolved && !resolved.redirect) {
                emitter(resolved);
            } else if (resolved && resolved.redirect) {
                history.replace(resolved.redirect);
                const resolvedAfterRedirect = parseLocation(resolved.redirect, "");
                emitter(resolvedAfterRedirect);
            } else {
                history.replace(pageRoutes[Pages.CONTRACTS]);
                const resolvedAfterRedirect = parseLocation(pageRoutes[Pages.CONTRACTS], "");
                emitter(resolvedAfterRedirect);
            }
        });
        return () => unlisten();
    });
}

function* onNavigate({ payload }) {
    const history = yield getContext("history");
    yield call([history, history.push], getStaticUrl(payload.name, payload.innerName, payload.params, payload.query), {
        replace: payload.replace,
    });
}

function* onNavigateExternal({ payload }) {
    const stringQuery = Object.keys(payload.query).length > 0 ? `?${stringify(payload.query)}` : "";
    yield (window.location.href = `${payload.url}${stringQuery}`);
}

function* onNavigateSsoLogin({ payload }) {
    const { href } = window.location;
    const redirectUri = href.split("?").shift();
    const clientId = yield select(pageContext.getClientId);
    const authUri = yield select(pageContext.getAuthUri);
    const locale = yield select(pageContext.getLocale);

    const query = {
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: "code",
        scope: "openid",
        ui_locales: locale.languageCode,
    };

    // Payload - kerberos enabled.
    if (payload) {
        query["enable_kerberos"] = true;
    }

    const stringQuery = Object.keys(query).length > 0 ? `?${stringify(query)}` : "";
    yield (window.location.href = `${authUri}${stringQuery}`);
}

let packageSaga;
let previousRouteClear = null;
let previousModalSaga = null;
let previousPageSagaTask = null;
let previousInnerRouteSaga = null;

const isDifferentInTopPath = (page1: PageInformation, page2: PageInformation): boolean => {
    return page1.name !== page2.name || (page2.name && paramValuesDifferentInTopPath(page2.name, page1.params, page2.params));
};

const isDifferentInSubPath = (page1: PageInformation, page2: PageInformation): boolean => {
    return (
        page1.innerName &&
        (page1.innerName !== page2.innerName ||
            objectsShallowlyDifferent(page1.params, page2.params) ||
            queryObjectsDifferent(page1.query, page2.query))
    );
};

function* loadPage(newPage: PageInformation) {
    const currentPage = yield select(getPageInfo);
    if (isDifferentInTopPath(currentPage, newPage)) {
        try {
            yield put(slice.actions.setActivePageIsLoading());

            yield call(cancelPreviousPageSagaTask);
            yield call(cancelPreviousInnerRouteSaga);

            packageSaga = null;
            const { saga } = getTopLevelRoutePackage(newPage.name);
            if (!saga) {
                packageSaga = routerWrapper({});
            } else if (typeof saga === "function") {
                packageSaga = routerWrapper({
                    onPageEnter: saga,
                });
            } else {
                packageSaga = saga;
            }

            const dataPutActions = yield call(packageSaga.getDataForPage, newPage.params, newPage.query);
            yield put(dataPutActions);
            yield put(slice.actions.routeEntered(newPage));

            yield put(slice.actions.setActivePageLoadedSuccessfully());
            yield call(callPreviousPageClearIfDifferentRoute, currentPage.name, newPage.name, packageSaga.clearDataForPage);
            yield all([
                call(forkOnPageEnterSaga, packageSaga.onPageEnter, newPage.params, newPage.query),
                call(forkOnInnerRouteChangedSaga, packageSaga.onInnerRouteChange, newPage.innerName, newPage.params, newPage.query),
            ]);
        } catch (e) {
            sentry.captureException(e);
            // TODO: 401 Handling in response.
            // if (e instanceof fetch.NotAuthenticatedError) {
            //     yield put(auth.logOut());
            // }
            yield all([
                put(slice.actions.routeEntered(newPage)),
                put(
                    slice.actions.setActivePageLoadedWithError({
                        identifier: e.identifier,
                        msgKey: "error.contractDetailUnavailable.text",
                    }),
                ),
            ]);
        }
    } else if (isDifferentInSubPath(currentPage, newPage)) {
        try {
            yield put(slice.actions.routeEntered(newPage));
            yield call(cancelPreviousInnerRouteSaga);

            if (packageSaga) {
                yield call(forkOnInnerRouteChangedSaga, packageSaga.onInnerRouteChange, newPage.innerName, newPage.params, newPage.query);
            }
            yield put(slice.actions.setActivePageLoadedSuccessfully());
        } catch (e) {
            sentry.captureException(e);
            // TODO: 401 Handling in response.
            // if (e instanceof fetch.NotAuthenticatedError) {
            //     yield put(auth.logOut());
            // }
            yield put(
                slice.actions.setActivePageLoadedWithError({
                    identifier: e.identifier,
                    msgKey: "error.contractDetailUnavailable.text",
                }),
            );
        }
    }
}

function* displayModalForm(action) {
    yield call(cancelPreviousModalSagaTask);
    if (packageSaga && packageSaga.onModalOpen) {
        const modalName = (action.payload && action.payload) || "";
        previousModalSaga = yield fork(packageSaga.onModalOpen, modalName);
    }
}

function* callPreviousPageClearIfDifferentRoute(currentRoute: string, newRoute: string, clearDataForPage) {
    // if i am at the same route with different data, data are replaced.
    // Only if i go to different page type, clear data of previous that page;
    if (previousRouteClear && currentRoute && currentRoute !== newRoute) {
        try {
            const clearActions = yield call(previousRouteClear);
            yield put(clearActions);
        } catch (e) {
            console.error(e);
        }
    }
    previousRouteClear = clearDataForPage;
}

function* cancelPreviousPageSagaTask() {
    if (previousPageSagaTask) {
        yield cancel(previousPageSagaTask);
        previousPageSagaTask = null;
    }
}

function* cancelPreviousModalSagaTask() {
    if (previousModalSaga) {
        yield cancel(previousModalSaga);
        previousModalSaga = null;
    }
}

function* cancelPreviousInnerRouteSaga() {
    if (previousInnerRouteSaga) {
        yield cancel(previousInnerRouteSaga);
        previousInnerRouteSaga = null;
    }
}

// new function to allow forkPageSaga to be forkable/catcheable
function* forkOnPageEnterSaga(saga, params, query) {
    if (saga) {
        // Fork is here to avoid automatic cancellation (takeLatest). We want to cancel this saga after routeEntered(name, params, query)
        // to avoid page blinking when somebody is clearing some state on page saga cancellation (=backwards compatible behavior)
        previousPageSagaTask = yield fork(saga, params, query);
    }
}

function* forkOnInnerRouteChangedSaga(routeChangedSaga, innerName, params, query) {
    if (innerName && routeChangedSaga) {
        previousInnerRouteSaga = yield fork(routeChangedSaga, innerName, params, query);
    }
}

// TODO multiple steps
function* onBack() {
    const history = yield getContext("history");
    yield call([history, history.go], -1);
}

// TODO
function* onGoHome() {
    const history = yield getContext("history");
    yield call([history, history.push], "/");
}

// https://gist.github.com/Jahans3/f346b09af7ebdf2de369e7550844156c
function objectsShallowlyDifferent(firstObj, secondObj) {
    if (typeof firstObj !== "object" || firstObj === null || typeof secondObj !== "object" || secondObj === null) {
        return false;
    }
    return Object.entries(secondObj).reduce((shouldUpdate, [key, value]) => (firstObj[key] !== value ? true : shouldUpdate), false);
}

const queryObjectsDifferent = (currentQuery, newQuery) => {
    const queryKeys = [];

    Object.keys(currentQuery)
        .filter((key) => key !== LANG_PARAM)
        .forEach((key) => queryKeys.push(key));

    Object.keys(newQuery)
        .filter((key) => key !== LANG_PARAM)
        .forEach((key) => queryKeys.push(key));

    const queryKeySet = Set(queryKeys);
    return queryKeySet
        .map((key) => currentQuery[key] !== newQuery[key])
        .filter((item) => item === true)
        .first();
};

const paramValuesDifferentInTopPath = (name, actualParams, newParams) => {
    const paramNames = getTopPathParamNames(name);
    return !!paramNames.find((paramName) => actualParams[paramName] !== newParams[paramName]);
};

// Activity check and auto logout when inactive.
function* sessionTimeoutCheck() {
    const task = yield takeLatest([slice.actions.routeEntered.type], checkSessionTimeout);
    yield take(auth.logOut.type);
    yield cancel(task);
}

export function* checkSessionTimeout() {
    const sessionTimeoutInSeconds = yield select(auth.getInactiveInterval);
    yield delay(sessionTimeoutInSeconds * 1000);
    yield put(auth.logOut(auth.LogoutAction.AUTO_LOGOUT));
}
