import type { Selector } from "@reduxjs/toolkit";
import { Map } from "immutable";
import { createSelector } from "reselect";
import { v4 as uuid } from "uuid";

import entity from "core/entity";
import i18n from "core/i18n";
import userContracts from "core/userContracts";
import { DEFAULT_EMPTY_PARAM } from "app/constants";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import { app, date, DEFAULT_CHART_COLOR, model } from "core/util";
import {
    createGetIsAnotherRedeemInProcess,
    createGetIsRedeemPossible,
    createGetRedeemAllowedPensionTypes,
    createGetRedeemAllowedRedeemTypes,
    createGetRedeemParameters,
} from "core/util/selector";
import { DpsFunds, RedeemPensionDpsRedeemTypes } from "enums";
import { PensionDpsFinancialOperation, PensionDpsFinancialStatement } from "model/pension/dps";
import {
    ElectronicCommunication,
    Holder,
    Payments,
    PaymentsScheduler,
    PensionBeneficiary,
    PensionDpsAccountDetail,
    PensionDpsContract,
    PensionDpsSavingsStrategy,
    PensionDpsTypes,
    PensionStatementSettings,
    State,
} from "types";
import { PensionContractAdditionalInformation } from "model/pension/additionalInfomation";

import { ENTITY_CONTRACT_ADDITIONAL_INFO, ENTITY_FINANCIAL_OPERATIONS, ENTITY_FINANCIAL_STATEMENT, NAME } from "./constants";

/** contract data */
const getModel = app.createGetModel(NAME);
const getData = (state) => getModel(state).get("data");

export const getPensionDpsContractDetail: Selector<State, PensionDpsContract> = (state) => getData(state).pensionDpsContractDetail;

export const getIdObject: Selector<State, number> = (state) => getPensionDpsContractDetail(state).idObject || DEFAULT_EMPTY_PARAM;
export const getPolicyHolder: Selector<State, Holder> = (state) => getPensionDpsContractDetail(state).contractDetail.holder;
export const getElectronicCommunication: Selector<State, ElectronicCommunication> = (state) =>
    getPensionDpsContractDetail(state).contractDetail.electronicCommunication;
export const isElectronicCommunicationSet: Selector<State, boolean> = (state) =>
    getPensionDpsContractDetail(state).contractDetail.electronicCommunication.electronicCommunicationSet || false;
export const getPensionAccountDetail: Selector<State, PensionDpsAccountDetail> = (state) =>
    getPensionDpsContractDetail(state).contractDetail.pensionDpsAccountDetail;
export const getClientStatementSettings: Selector<State, PensionStatementSettings> = (state) =>
    getPensionDpsContractDetail(state).contractDetail.statementSettings;
export const getBeneficiaries: Selector<State, PensionBeneficiary[]> = (state) =>
    getPensionDpsContractDetail(state).contractDetail.beneficiaries;
export const getPensionTypes: Selector<State, PensionDpsTypes> = (state) => getPensionAccountDetail(state).pensionTypes;
export const getPaymentsScheduler: Selector<State, PaymentsScheduler> = (state) => getPensionAccountDetail(state).paymentsScheduler;
export const getEmployerPayments: Selector<State, Payments> = (state) => getPensionAccountDetail(state).employerPayments;
export const getPensionDpsSavingStrategy: Selector<State, PensionDpsSavingsStrategy> = (state) =>
    getPensionAccountDetail(state).pensionSavingsStrategy;

// is entity
export const getContractAdditionalInfo = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_CONTRACT_ADDITIONAL_INFO, PensionContractAdditionalInformation.fromServer());

export const getPensionDpsFinancialStatementEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_STATEMENT, PensionDpsFinancialStatement.fromServer());
const getPensionDpsFundsStatements = (state) =>
    getPensionDpsFinancialStatementEntity(state).get("pensionInvestmentsFundsInvestmentsStatements");
export const getPensionDpsContractBalanceSheet = (state) => getPensionDpsFinancialStatementEntity(state).get("contractBalanceSheet");

export const getFinancialOperationsEntity = (state) =>
    // @ts-ignore
    entity.getDataSelector(state, ENTITY_FINANCIAL_OPERATIONS, PensionDpsFinancialOperation.fromServerList());

/** user data */
export const getPolicy = createSelector(userContracts.getContractNormalizedData, getIdObject, (policies, idObject) =>
    idObject ? policies[idObject] : null,
);

export const getPolicyBeginDateYear = (state) => date.getYearFromStringDateTime(getPolicy(state)?.beginDate);

const getFunds = i18n.createGetLocalizedEnum(DpsFunds, "fundLink");

export const getPensionDpsFundsStatementsData = createSelector(getPensionDpsFundsStatements, getFunds, (fundsStatements, funds) =>
    fundsStatements.map((fundStatement) => ({
        reactKey: uuid(),
        fundName: {
            text: fundStatement.fundName,
            href: model.getHyperLinkForPensionsCodeList(funds, fundStatement.fundCode),
        },
        sumShares: fundStatement.sumShares,
        pricePerShare: fundStatement.pricePerShare,
        actualInvestmentsValue: fundStatement.actualInvestmentsValue,
    })),
);

export const getFinancialStatementChartItems = (financialStatement, funds) =>
    financialStatement.pensionInvestmentsFundsInvestmentsStatements
        .map((item) => {
            const dpsFund = funds.find((fund) => fund.fundCode === item.fundCode);
            return {
                name: item.fundName,
                color: dpsFund ? dpsFund.fundColor : DEFAULT_CHART_COLOR,
                value: item.actualInvestmentsValue.value,
                fullValue: localAmountWithCurrencyLegacy(item.actualInvestmentsValue),
                currency: item.actualInvestmentsValue.currencyCode,
            };
        })
        .toArray();

export const getFinancialStatementChartData = createSelector(getPensionDpsFinancialStatementEntity, getFunds, (financialStatement, funds) =>
    Map({
        sumValue: financialStatement.actualInvestmentsValue.value,
        sumCurrency: financialStatement.actualInvestmentsValue.currencyCode,
        sumAmount: `${localAmountWithCurrencyLegacy(financialStatement.actualInvestmentsValue)}`,
        items: getFinancialStatementChartItems(financialStatement, funds),
    }),
);

export const getFinancialOperations = createSelector(getFinancialOperationsEntity, (financialOperations) => {
    return financialOperations.map((financialOperation) => ({
        id: financialOperation.id,
        investmentFundName: financialOperation.investmentFundName,
        valueDate: financialOperation.valueDate,
        grossAmount: Map({
            amount: financialOperation.grossAmount,
            operationType: financialOperation.typeCode,
        }),
        pricePerShareAtOperationDateTime: financialOperation.pricePerShareAtOperationDateTime,
        numberOfShares: financialOperation.numberOfShares,
        actualPricePerShare: financialOperation.actualPricePerShare,
        type: financialOperation.type,
    }));
});
export const getFinancialOperationsFundNames = createSelector(getFinancialOperations, (financialOperations) =>
    financialOperations
        .map((operation) => Map().set("label", operation.investmentFundName).set("value", operation.investmentFundName))
        .toSet(),
);
export const getFinancialOperationsTypes = createSelector(getFinancialOperations, (financialOperations) =>
    financialOperations.map((operation) => Map().set("label", operation.type).set("value", operation.type)).toSet(),
);

export const getPensionDpsStatementData = createSelector(getPensionDpsFinancialStatementEntity, (financialStatement) => {
    if (!financialStatement) {
        return Map();
    }
    return [
        {
            reactKey: uuid(),
            investmentsSumInvested: financialStatement.investmentsSumInvested,
            investmentsSumNotInvested: financialStatement.investmentsSumNotInvested,
            actualInvestmentsValue: financialStatement.actualInvestmentsValue,
            valuationPercentage: financialStatement.valuationPercentage,
        },
    ];
});

/** Redeem savings **/
export const getRedeemAllowedPensionTypes = createGetRedeemAllowedPensionTypes(getContractAdditionalInfo, RedeemPensionDpsRedeemTypes);
export const getRedeemAllowedRedeemTypes = (pensionType) =>
    createGetRedeemAllowedRedeemTypes(pensionType, getContractAdditionalInfo, RedeemPensionDpsRedeemTypes);
export const getNotApplicableRedeems = createSelector(getContractAdditionalInfo, (contractAdditionalInfo) =>
    contractAdditionalInfo.redeemInvestmentsOptions.filter((redeemOption) => redeemOption.isApplicable === 0),
);

export const getIsAnotherRedeemInProcess = createGetIsAnotherRedeemInProcess(getContractAdditionalInfo);
export const getIsRedeemPossible = createGetIsRedeemPossible(getRedeemAllowedPensionTypes, getIsAnotherRedeemInProcess);
export const getRedeemParameters = (redeemType) => createGetRedeemParameters(redeemType, getContractAdditionalInfo);
