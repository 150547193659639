import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import localized from "core/localized";
import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { useHasEditContractPermission } from "core/hooks";
import { dateTimeToLocalFullDate } from "core/formatters";
import { date, fn } from "core/util";
import userContracts from "core/userContracts";
import pageContext from "core/pageContext";

import { FeatureFlag } from "types";
import { getActualPaymentScheduler } from "../selectors";
import { FORM_EDIT_CONTRACT_NAME, FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";

export const ContractDataGridItem = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);
    const hasEditPermission = useHasEditContractPermission();

    const actualScheduler = useSelector(getActualPaymentScheduler);
    const formattedSchedulerPayment = useSelector(
        localized.getLocalizedSchedulerPaymentFullLegacy(fn.getItemOrFirstItemWhenIterable(actualScheduler)),
    );
    const isHarmonizedContractsEditEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.HARMONIZED_CONTRACTS_EDIT_ENABLED);

    const editLinks = () => {
        let links = [];
        links.push({
            formConst: FORM_EDIT_CONTRACT_NAME,
            name: "common.customContractNameFull",
        });
        if (contract?.canEdit && isHarmonizedContractsEditEnabled) {
            links.push({
                formConst: FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
                name: "form.policyHolderPayments",
            });
        }
        return links;
    };

    return (
        <GridItem display={"flex"} xs={12} md={6}>
            <InfoPanel
                icon={"contract"}
                title={t("common.contract")}
                headerAction={hasEditPermission && <EditLinksDropdown links={editLinks()} />}
            >
                <Box>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    <LabelTextItem
                        label={t("insurance.insuranceTo")}
                        text={date.formatToDateWithUnlimited(contract?.endDate, t("common.unlimitedDate"))}
                    />

                    {!isInFutureOrTerminated && <LabelTextItem label={t("insurance.insuranceCost")} text={formattedSchedulerPayment} />}
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
