import { createContainer } from "./createContainer";
import { createSaga } from "./createSaga";

/**
 * Saga and Container creator for technical documentation number form
 */
const vehicleLicenceNumberForm = {
    createSaga,
    createContainer,
};
export default vehicleLicenceNumberForm;
