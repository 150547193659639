import React, { FC } from "react";

import { Container, Loading } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";

import i18n from "core/i18n";
import { form } from "core/form";

import { FullPageFormLayout } from "containers/fullPageForm";

import { ButtonsBox } from "./components";
import { ElectronicCommunicationFormBox, SelectContractsFormBox } from "./containers";

type EditFormProps = {
    handleSubmit: () => void;
    submitting: boolean;
    formName: string;
};

const EditForm: FC<EditFormProps> = ({ handleSubmit, submitting, formName }) => (
    <Container maxWidth="md">
        <Loading fullPage loading={submitting} />
        <FormBlock>
            <ElectronicCommunicationFormBox />

            <SelectContractsFormBox formName={formName} />

            <ButtonsBox handleSubmit={handleSubmit} formName={formName} />
        </FormBlock>
    </Container>
);

const createContainer = (formName: string) =>
    form(formName)((props) => {
        const { t } = i18n.useTranslation();

        return (
            <FullPageFormLayout formName={formName} formTitle={t("form.electronicCommunication")}>
                <EditForm {...props} formName={formName} />
            </FullPageFormLayout>
        );
    });

export default createContainer;
