import React, { FC } from "react";
import { Selector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { PopoverTooltip, StandaloneLink } from "ui-library/atoms";
import { DataTable, InfoPanel } from "ui-library/modules";
import { Flexbox, GridItem } from "ui-library/layouts";

import i18n, { TFunction } from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import router from "core/router";
import { formatBirthNumberOrCompanyId, oneLineNameWithTitles, pensionBeneficiaryShare } from "core/formatters";
import userContracts from "core/userContracts";

import { PensionBeneficiary, State } from "types";
import { Pages, Tabs } from "routeConstants";

const columns = (t: TFunction, isSlovakAndContainCompanyID: boolean) => [
    {
        field: "beneficiaryEntity",
        headerName: t("holder.fullName"),
        flex: 2,
        renderCell: (params) => oneLineNameWithTitles(params?.row?.beneficiaryEntity),
    },
    {
        field: "beneficiaryEntity.isPerson",
        headerName: isSlovakAndContainCompanyID ? t("holder.birthNumberAndID") : t("holder.birthNumber"),
        flex: 2,
        renderCell: (params) => formatBirthNumberOrCompanyId(params?.row?.beneficiaryEntity),
    },
    {
        field: "beneficiaryShare",
        headerName: t("investments.percentage"),
        renderCell: (params) => pensionBeneficiaryShare(params?.row?.beneficiaryShare),
        flex: 0.5,
    },
];

type BeneficiariesGridItemProps = {
    getBeneficiaries: Selector<State, PensionBeneficiary[]>;
    getIdObject: Selector<State, number>;
    tooltipTextKey: string;
    isSlovakAndContainCompanyID: boolean;
    editFormRouteArray: [Pages, Tabs];
};

export const BeneficiariesGridItem: FC<BeneficiariesGridItemProps> = ({
    getBeneficiaries,
    getIdObject,
    tooltipTextKey,
    isSlovakAndContainCompanyID = false,
    editFormRouteArray,
}) => {
    const { t } = i18n.useTranslation();
    const beneficiaries = useSelector(getBeneficiaries);
    const idObject = useSelector(getIdObject);
    const hasEditPermission = useHasEditContractPermission();
    // @ts-ignore
    const isContractEditable = userContracts.useGetContractById(idObject)?.canEdit;

    return (
        <GridItem display="flex" xs={12}>
            <InfoPanel
                icon="users"
                headerAction={
                    hasEditPermission &&
                    isContractEditable && (
                        <StandaloneLink color="blue" to={router.getStaticUrl(editFormRouteArray[0], editFormRouteArray[1], { idObject })}>
                            {t("common.edit")}
                        </StandaloneLink>
                    )
                }
                title={
                    <Flexbox gap={1}>
                        {t("person.authorizedPersons")}
                        {/* @ts-ignore TODO: martin.rodin type PopoverTooltip later */}
                        <PopoverTooltip title={t("pension.beneficiariesTooltipTitle")} content={t(tooltipTextKey)} />
                    </Flexbox>
                }
            >
                <DataTable
                    withWrapper={false}
                    getRowId={(row) => row.reactKey}
                    hideFooter={true}
                    rows={beneficiaries}
                    columns={columns(t, isSlovakAndContainCompanyID)}
                />
            </InfoPanel>
        </GridItem>
    );
};
