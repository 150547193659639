import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, ErrorContent, Loading } from "ui-library/atoms";
import { BannerFlexbox, Box, Flexbox, GridItem, GridLayout } from "ui-library/layouts";
import { SectionBanner } from "ui-library/modules";
import bannerImg from "ui-library/assets/images/banner-prihlasenie.svg";

import auth from "core/auth";
import i18n from "core/i18n";
import { LoginPageError } from "containers/error";
import { PageBoundary } from "containers";

import { startSsoLogin } from "../actions";
import { AlertBox, WrongRoleError } from "../components";
import pageContext from "core/pageContext";

const WRONG_USER_DATA = "WRONG_USER_DATA";

export const SsoLoginSection = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();
    const submitting = false;
    const loginError = useSelector(auth.getLoginUnsuccessfulReason);
    const wrongUserRole = loginError === WRONG_USER_DATA;
    const infoTexts = useSelector(pageContext.getInfoTexts);

    return (
        <SectionBanner title={t("page.login.welcomeTitle")} description={t("page.login.welcomeText")} imageUrl={bannerImg}>
            <PageBoundary ErrorComponent={LoginPageError}>
                <>
                    <Loading fullPage loading={submitting} />

                    {/* Info box with alert text */}
                    {infoTexts?.enableLoginAlert && <AlertBox text={infoTexts.loginText} />}

                    {/* Login button - SSO login */}
                    {!wrongUserRole && (
                        <BannerFlexbox>
                            <GridLayout spacing={1}>
                                <GridItem xs={12} md={2}>
                                    <Box mt={3.625}>
                                        <Button
                                            onClick={() => dispatch(startSsoLogin())}
                                            color="green"
                                            variant="contained"
                                            size="large"
                                            id="sso_login"
                                        >
                                            {t("common.login")}
                                        </Button>
                                    </Box>
                                </GridItem>
                            </GridLayout>
                        </BannerFlexbox>
                    )}

                    {/* Login error message */}
                    {loginError && (
                        <Flexbox alignItems={"top"} gap={2} mt={2}>
                            {!wrongUserRole && <ErrorContent content={t("error.page.login.wrongCredentials")} />}
                            {wrongUserRole && <WrongRoleError />}
                        </Flexbox>
                    )}
                </>
            </PageBoundary>
        </SectionBanner>
    );
};
