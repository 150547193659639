import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ContentBox } from "ui-library/layouts";
import { Typography } from "ui-library/atoms";

import { FundSellTarget } from "enums";
import coreEnum from "core/enum";
import {
    Field,
    getFormFieldValue,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max50Characters,
    required,
    SingleSelect,
} from "core/form";
import i18n from "core/i18n";
import { createGetFundsOptionsForInvestment } from "core/util/selector";

import AccountSection from "./AccountSection";

const TargetSectionDirect = ({
    formName,
    getShouldCheckIbanCountry,
    getFundSellTargetOptions,
    createGetTargetFundOptions,
    getTargetFundCodes,
}) => {
    const { t } = i18n.useTranslation();

    const targetFieldSelector = getFormFieldValue(formName, "target");
    const targetFieldValue = useSelector(targetFieldSelector);

    const targetOptions = useSelector(getFundSellTargetOptions);

    const targetFundsCodes = useSelector(getTargetFundCodes);
    const getTargetFundOptions = createGetTargetFundOptions("sourceFund", targetFundsCodes);
    const targetFundOptions = useSelector(getTargetFundOptions);
    const filteredTargetFundOptions = useSelector(createGetFundsOptionsForInvestment(targetFundOptions, "value"));

    return (
        <>
            <ContentBox>
                <Typography variant="h3">{t("investments.sellSwitch.target")}</Typography>
            </ContentBox>
            <ContentBox fullWidth>
                <Field
                    component={SingleSelect}
                    name="target"
                    placeholder={t("common.choose")}
                    label={t("investments.sellSwitch.targetLabel")}
                    options={targetOptions}
                />
            </ContentBox>

            {targetFieldValue === FundSellTarget.SWITCH.id && (
                <>
                    <ContentBox fullWidth>
                        <Field
                            component={SingleSelect}
                            name="targetFund"
                            label={t("common.toFund")}
                            placeholder={t("investments.sellSwitch.targetPlaceholder")}
                            validateStatic={[required]}
                            options={filteredTargetFundOptions}
                            md={6}
                        />
                    </ContentBox>
                    <ContentBox fullWidth>
                        <Field
                            name="note"
                            label={t("investments.transferName")}
                            component={InputText}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                            md={6}
                        />
                    </ContentBox>
                </>
            )}
            {targetFieldValue === FundSellTarget.SELL.id && (
                <AccountSection bankListType={coreEnum.BANK_LIST} getShouldCheckIbanCountry={getShouldCheckIbanCountry} />
            )}
        </>
    );
};

TargetSectionDirect.propTypes = {
    formName: PropTypes.string.isRequired,
    getFundSellTargetOptions: PropTypes.func.isRequired,
    createGetTargetFundOptions: PropTypes.func.isRequired,
    getTargetFundCodes: PropTypes.func.isRequired,
    getShouldCheckIbanCountry: PropTypes.func.isRequired,
};

export default TargetSectionDirect;
