import React, { FC } from "react";
import { GridLayout } from "ui-library/layouts";

import { BeneficiariesGridItem, ContractDataGridItem, ContributionsGridItem } from "../containers";
import { ModalContractNameForm, ModalContractOwnerPaymentsForm } from "../form";

export const DetailTab: FC = () => (
    <GridLayout defaultSpacing>
        <ContractDataGridItem />
        <ContributionsGridItem />
        <BeneficiariesGridItem />

        {/* Modals */}
        <ModalContractNameForm />
        <ModalContractOwnerPaymentsForm />
    </GridLayout>
);
