import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { DataTable } from "ui-library/modules";
import { Button, StandaloneHtmlLink, StandaloneLink, Typography } from "ui-library/atoms";
import { Box, ButtonsLayout, GridItem } from "ui-library/layouts";

import {
    dateTimeToLocalFullDate,
    localAmountInvestmentWithCurrencyEurLegacy,
    localAmountNoDecimal,
    localAmountWithCurrencyLegacy,
    profitHyperLinkFormatter,
} from "core/formatters";
import i18n, { TFunction } from "core/i18n";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";
import { Pages, Params, Tabs } from "routeConstants";

import { getFinancialStatementEntity, getInvestmentDetailData } from "../selectors";

const createColumns = (t: TFunction, getProfitDetailUrl: (fund: string) => string, idObject: number, isInFutureOrTerminated: boolean) => [
    {
        headerName: t("common.fund"),
        field: "fundName",
        minWidth: 260,
        sortable: false,
        renderCell: (params) => (
            <StandaloneHtmlLink color="blue" href={params.value.get("href")} target={"_blank"}>
                {params.value.get("text")}
            </StandaloneHtmlLink>
        ),
    },
    {
        headerName: t("investments.investedAmount"),
        field: "investedAmount",
        align: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("investments.entryFee"),
        field: "entryFee",
        align: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("investments.outputFee"),
        field: "outputFee",
        align: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("investments.numberOfShares"),
        field: "numberOfShares",
        align: "right",
        minWidth: 140,
        renderCell: (params) => localAmountNoDecimal(params.value),
    },
    {
        headerName: t("investments.actualShareValue"),
        field: "actualShareValue",
        align: "right",
        minWidth: 140,
        renderCell: (params) => localAmountInvestmentWithCurrencyEurLegacy(params.value),
    },
    {
        headerName: t("investments.actualInvestmentsValue"),
        field: "actualValueOfInvestments",
        align: "right",
        minWidth: 140,
        renderCell: (params) => localAmountWithCurrencyLegacy(params.value),
    },
    {
        headerName: t("common.profit"),
        field: "profit",
        align: "right",
        headerAlign: "right",
        minWidth: 140,
        renderCell: (params) => profitHyperLinkFormatter(params.value, getProfitDetailUrl),
    },
    {
        headerName: t("common.payment"),
        field: "payment",
        align: "right",
        minWidth: 140,
        headerAlign: "right",
        renderCell: (params) =>
            !isInFutureOrTerminated &&
            params.value && (
                <StandaloneLink color="blue" to={getPaymentUrl(idObject, params.payment)}>
                    {t("common.invest")}
                </StandaloneLink>
            ),
    },
];

const getPaymentUrl = (contractId: number, fundId: string): string =>
    router.getStaticUrl(
        Pages.ONLINE_PAYMENT,
        Tabs.PAYMENTS_PAY,
        {},
        {
            [Params.PAYMENT_CONTRACT_ID]: contractId,
            [Params.PAYMENT_FUND_ID]: fundId,
        },
    );

export const ActualInvestmentValueTable: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const data = useSelector(getInvestmentDetailData);
    const { idObject } = useParams();
    const hasEditPermission = useHasEditContractPermission();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);
    const financialStatementDate = useSelector(getFinancialStatementEntity)?.statementDate;

    const getProfitDetailUrl = (fund) => router.getStaticUrl(Pages.CONTRACT_DIRECT, Tabs.DIRECT_PROFIT_DETAIL, { idObject, fund });
    const onSellRequest = () => navigate(router.getStaticUrl(Pages.CONTRACT_DIRECT, Tabs.SELL_SWITCH_FORM, { idObject }));

    return (
        <GridItem xs={12}>
            <DataTable
                icon={"coins"}
                title={t("investments.actualInvestmentsValue")}
                headerHeight={60}
                disableColumnReorder
                pageSize={20}
                hideFooter
                getRowId={(row) => row.id}
                rows={data ? data.toArray() : []}
                columns={createColumns(t, getProfitDetailUrl, Number(idObject), isInFutureOrTerminated)}
                preContent={
                    hasEditPermission &&
                    contract.canEdit && (
                        <ButtonsLayout mb={4}>
                            <Button color="orange" variant="contained" onClick={onSellRequest}>
                                {t("investments.newSellRequest")}
                            </Button>
                        </ButtonsLayout>
                    )
                }
                actions={
                    financialStatementDate ? (
                        <Box mr={2}>
                            <Typography component="p" color="gray">
                                {t("statements.dateTime", { statementDate: dateTimeToLocalFullDate(financialStatementDate) })}
                            </Typography>
                        </Box>
                    ) : null
                }
            />
        </GridItem>
    );
};
