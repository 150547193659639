import React from "react";
import { useSelector } from "react-redux";
import { GridLayout } from "ui-library/layouts";

import { ContractDataGridItem, InsuranceCoverageGridItem, VehicleGridItem, VehicleOwnerOperatorGridItem } from "../containers";
import { ModalContractNameForm, ModalContractOwnerPaymentsForm, ModalLicencePlateNumberForm, ModalVehicleLicenceNumberForm } from "../form";
import { getInsuredVehicle, getNumberOfInsuredVehicles } from "../selectors";

const { EntityType } = VehicleOwnerOperatorGridItem;

export const DetailTab = () => {
    const numberOfInsuredVehicles = useSelector(getNumberOfInsuredVehicles);
    const onlyOneVehicle = numberOfInsuredVehicles === 1;

    const insuredVehicle = useSelector(getInsuredVehicle);
    const vehicleOwners = onlyOneVehicle && insuredVehicle && insuredVehicle.get("vehicleOwners");
    const vehicleOperators = onlyOneVehicle && insuredVehicle && insuredVehicle.get("vehicleOperators");

    return (
        <GridLayout defaultSpacing>
            <InsuranceCoverageGridItem />

            {onlyOneVehicle && <VehicleGridItem insuredVehicle={insuredVehicle} />}

            <ContractDataGridItem />

            {vehicleOwners &&
                vehicleOwners.map((vehicleOwner) => (
                    <VehicleOwnerOperatorGridItem
                        key={vehicleOwner.isPerson ? vehicleOwner.birthDate : vehicleOwner.companyID}
                        entityType={EntityType.VEHICLE_OWNER}
                        entity={vehicleOwner}
                    />
                ))}

            {vehicleOperators &&
                vehicleOperators.map((vehicleOperator) => (
                    <VehicleOwnerOperatorGridItem
                        key={vehicleOperator.isPerson ? vehicleOperator.birthDate : vehicleOperator.companyID}
                        entityType={EntityType.VEHICLE_OPERATOR}
                        entity={vehicleOperator}
                    />
                ))}

            {/* Modals */}
            <ModalLicencePlateNumberForm />
            <ModalVehicleLicenceNumberForm />
            <ModalContractNameForm />
            <ModalContractOwnerPaymentsForm />
        </GridLayout>
    );
};
