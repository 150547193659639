import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";
import { FullPageBeneficiariesForm, FullPagePolicyHolderForm, FullPageRedeemSavingsForm } from "./form";

import {
    AgentTab,
    DetailTab,
    DocumentsTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    OverviewTab,
    PolicyHolderTab,
    RedeemSavingsTab,
} from "./tabs";

export { DetailPpContainer as DetailPensionPpPage } from "./Container";

export const DetailPensionPpTabs = {
    OverviewTab,
    PolicyHolderTab,
    DetailTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    DocumentsTab,
    RedeemSavingsTab,
    AgentTab,
};

export const DetailPensionPpForms = {
    FullPagePolicyHolderForm,
    FullPageBeneficiariesForm,
    FullPageRedeemSavingsForm,
};

export default {
    NAME,
    reducer,
    saga,
    innerRoutes,
};
