import { createSaga } from "./createSaga";
import { createSagaLegacy } from "./createSagaLegacy";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for Holder edit form
 */
const contractOwnerPaymentsForm = {
    createSaga,
    createSagaLegacy,
    createContainer,
};
export default contractOwnerPaymentsForm;
