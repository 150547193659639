import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import vehicleImage from "ui-library/assets/images/auto-pozadie.svg";
import { Container, SidemenuLink } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";

import i18n from "core/i18n";
import router from "core/router";
import { fn } from "core/util";
import userContracts from "core/userContracts";

import { PageError } from "containers/error";
import { ContractHeader, ProductDetailPageLayout } from "containers/contract";
import { PageBoundary } from "containers/PageBoundary";

import { innerRoutes } from "routeUrls";
import { Tabs } from "routeConstants";

import { getVehicles } from "./selectors";
import { AppSidemenuLink } from "containers/link";

export default () => {
    const { t } = i18n.useTranslation();

    const policy = userContracts.useGetContractByUrlParam();
    const isPageLoading = useSelector(router.getActivePageIsLoading);
    const vehicles = useSelector(getVehicles);
    const vehiclesNames = vehicles
        .map((vehicle) => `${vehicle.manufacturer || ""} ${vehicle.model || ""} ${vehicle.licencePlateNumber}`)
        .join(", ");

    const lobData = {
        label: t("insurance.contractedObject"),
        value: fn.substringWithEtc(vehiclesNames, 200),
    };
    const hasDetail = policy.hasContractDetail;

    return (
        <ProductDetailPageLayout>
            <Container>
                <ContractHeader backgroundImage={vehicleImage} policy={policy} lobData={lobData} loading={isPageLoading} />

                {hasDetail ? (
                    <PageBoundary>
                        <SubPageWrapper>
                            <Sidemenu>
                                <AppSidemenuLink tab={Tabs.OVERVIEW} title={t("menu.tab.overview")} />
                                <AppSidemenuLink tab={Tabs.HOLDER} title={t("menu.tab.holder.insured")} />
                                <AppSidemenuLink tab={Tabs.DETAIL} title={t("menu.tab.detail")} />
                                <AppSidemenuLink tab={Tabs.FINANCIAL_OPERATIONS} title={t("menu.tab.insuranceAmountAndPayments")} />
                                <AppSidemenuLink tab={Tabs.CLAIMS} title={t("menu.tab.claims")} />
                                <AppSidemenuLink tab={Tabs.DOCUMENTS} title={t("menu.tab.documents")} />
                                <AppSidemenuLink tab={Tabs.AGENT} title={t("menu.tab.agent")} />
                            </Sidemenu>

                            <Outlet />
                        </SubPageWrapper>
                    </PageBoundary>
                ) : (
                    <PageError
                        title={"error.contractDetailPermanentlyUnavailable.title"}
                        text={"error.contractDetailPermanentlyUnavailable.text"}
                    />
                )}
            </Container>
        </ProductDetailPageLayout>
    );
};
