import beneficiariesDdsForm from "form/fullPage/pensionBeneficiaries";

import { FORM_EDIT_BENEFICIARIES } from "../constants";
import { getBeneficiaries, getIdObject } from "../selectors";

export const FullPageBeneficiariesForm = beneficiariesDdsForm.createContainerDdsDss(
    FORM_EDIT_BENEFICIARIES,
    getBeneficiaries,
    getIdObject,
    true,
);
