import createContainer from "./createContainer";
import createContainerLegacy from "./createContainerLegacy";
import createSaga from "./createSaga";
import createSagaLegacy from "./createSagaLegacy";

const policyHolder = {
    createContainer,
    createContainerLegacy,
    createSaga,
    createSagaLegacy,
};

export default policyHolder;
