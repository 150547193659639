import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { dateTimeToLocalFullDate } from "core/formatters";
import userContracts from "core/userContracts";

import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { getPensionDpsSavingStrategy } from "../selectors";
import { FORM_EDIT_CONTRACT_NAME } from "../constants";

export const ContractDataGridItem: FC = () => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const savingStrategy = useSelector(getPensionDpsSavingStrategy);
    const hasEditPermission = useHasEditContractPermission();

    const editLinks = [
        {
            formConst: FORM_EDIT_CONTRACT_NAME,
            name: "common.customContractNameFull",
        },
    ];

    return (
        <GridItem display="flex" xs={12} md={6}>
            <InfoPanel
                icon="contract"
                title={t("common.contract")}
                headerAction={hasEditPermission && <EditLinksDropdown links={editLinks} />}
            >
                <ContentBox>
                    <LabelTextItem label={t("common.customContractName")} text={contract?.contractName || "-"} />
                    <LabelTextItem label={t("common.contractNumber")} text={contract?.contractNumber} />
                    <LabelTextItem label={t("common.begin")} text={dateTimeToLocalFullDate(contract?.beginDate)} />
                    {contract?.endDate && <LabelTextItem label={t("common.end")} text={dateTimeToLocalFullDate(contract.endDate)} />}
                    <LabelTextItem label={t("pension.savingStrategy")} text={savingStrategy.savingStrategyName} />
                    <LabelTextItem
                        label={t("pension.portfolioInvestmentFunds")}
                        text={savingStrategy.savingStrategyPortfolioInvestmentFunds}
                    />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
