import policyHolderForm from "form/fullPage/policyHolder";
import { IndividualPersonType } from "enums";

import { FORM_EDIT_POLICY_HOLDER } from "../constants";
import { getIdObject, getPolicyHolder } from "../selectors";

export const FullPagePolicyHolderForm = policyHolderForm.createContainerLegacy(
    FORM_EDIT_POLICY_HOLDER,
    IndividualPersonType.PARTICIPANT,
    getIdObject,
    getPolicyHolder,
);
