import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Loading } from "ui-library/atoms";
import { Section } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, getFormFieldValue } from "core/form";

import { SectionTitle } from "../components";
import { FIELD_ID_OBJECTS, FIELD_LOADING } from "../constants";

import { ContractsPicker } from "./ContractsPicker";

type SelectContractsFormBoxProps = {
    formName: string;
};

export const SelectContractsFormBox: FC<SelectContractsFormBoxProps> = ({ formName }) => {
    const { t } = i18n.useTranslation();
    const isLoading = useSelector(getFormFieldValue(formName, FIELD_LOADING));

    return (
        <Section>
            <SectionTitle title={t("common.yourContracts")} />
            <Loading loading={isLoading}>
                <Field name={FIELD_ID_OBJECTS} component={ContractsPicker} />
            </Loading>
        </Section>
    );
};
