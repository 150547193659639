import { call, delay, fork, put, select, takeEvery } from "redux-saga/effects";
import { Set } from "immutable";
import { change } from "redux-form/immutable";

import errorHandling from "core/errorHandling";
import userInfo from "core/userInfo";
import userContracts from "core/userContracts";
import { FORM_STEPS_BACK } from "app/constants";
import { fieldChangeMatcher, formWrapper, getFormFieldValue, scrollToFirstError } from "core/form";
import { sentry } from "core/util";
import { contractDataChangeApi } from "serverApi";

import { FIELD_ELECTRONIC_COMMUNICATION, FIELD_ID_OBJECTS, FIELD_LOADING } from "./constants";

export function* createSaga(formName: string) {
    try {
        yield call(formSaga(formName));
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName: string) =>
    formWrapper(formName, {
        *persistentEffects() {
            yield takeEvery(fieldChangeMatcher(formName, FIELD_ELECTRONIC_COMMUNICATION), preSelectAllContracts, formName);
        },
        *initialize() {
            yield call(userInfo.checkUserVerified, FORM_STEPS_BACK);
            yield put(errorHandling.removeServiceErrors(formName));
            yield fork(handleLoading, formName);

            return {
                [FIELD_ELECTRONIC_COMMUNICATION]: false,
                [FIELD_ID_OBJECTS]: Set(),
            };
        },
        // @ts-ignore we won't type sagas
        *onSubmitFail() {
            yield call(scrollToFirstError);
        },
        *save(values) {
            yield call(contractDataChangeApi.updateContractElectronicCommunication, values);
        },
    });

function* preSelectAllContracts(formName: string) {
    const isElComChecked = yield select(getFormFieldValue(formName, FIELD_ELECTRONIC_COMMUNICATION));
    if (isElComChecked) {
        const contractsWithoutElCom = yield select(userContracts.getContractsWithoutElCom);
        const contractIds = contractsWithoutElCom.map((item) => item.idObject);
        yield put(change(formName, FIELD_ID_OBJECTS, Set(contractIds)));
    }
}

function* handleLoading(formName: string) {
    yield put(change(formName, FIELD_LOADING, true));
    yield yield delay(2000);
    yield put(change(formName, FIELD_LOADING, false));
}

export default createSaga;
