import { call, fork, select } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import userContracts from "core/userContracts";
import modal from "core/modal";

import policyHolderForm from "form/fullPage/policyHolder";
import contractNameForm from "form/modal/contractName";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import employerPaymentsForm from "form/modal/employerPayments";
import pensionBeneficiaries from "form/fullPage/pensionBeneficiaries";
import pensionStatementSettings from "form/modal/pensionStatementSettings";
import taxOptimizationsForm from "form/modal/taxOptimizations";
import pensionRedeemSavings from "form/fullPage/pensionRedeemSavings";
import { Tabs } from "routeConstants";
import { documentsApi, paymentApi, pensionPpApi } from "serverApi";

import { clear, setPensionPpContractDetail } from "./actions";
import {
    ENTITY_CONTRACT_ADDITIONAL_INFO,
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_ENQUEUED_DOCUMENTS,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_STATEMENT,
    ENTITY_PAYMENT_INFORMATION,
    FORM_EDIT_BENEFICIARIES,
    FORM_EDIT_CLIENT_STATEMENT_SETTINGS,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    FORM_EDIT_EMPLOYER_PAYMENTS,
    FORM_EDIT_POLICY_HOLDER,
    FORM_EDIT_TAX_OPTIMIZATION,
    FORM_REDEEM_SAVINGS,
} from "./constants";
import { ActualStatements, ExtraStatements, FinancialOperationsTableFilter, TaxStatements, YearStatements } from "./modules";
import {
    getBeneficiaries,
    getClientStatementSettings,
    getContractAdditionalInfo,
    getElectronicCommunication,
    getIdObject,
    getPaymentsScheduler,
    getPensionAccountDetail,
    getPolicy,
    getPolicyHolder,
    getRedeemAllowedPensionTypes,
    getRedeemAllowedRedeemTypes,
} from "./selectors";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const pensionPpContractDetail = yield call(pensionPpApi.getPensionPpContractDetail, idObject);

        return [setPensionPpContractDetail(pensionPpContractDetail)];
    },
    *onPageEnter({ idObject }) {
        yield fork(loadContractAdditionInformation, idObject);
        yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
        yield fork(entity.load, ENTITY_ENQUEUED_DOCUMENTS, () => documentsApi.getContractEnqueuedDocuments(idObject));
        yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => pensionPpApi.getPensionPpFinancialOperations(idObject));
        yield fork(entity.load, ENTITY_FINANCIAL_STATEMENT, () => pensionPpApi.getPensionPpFinancialStatement(idObject));
        yield fork(loadPaymentInformation, idObject);

        yield fork(YearStatements.saga);
        yield fork(ExtraStatements.saga);
        yield fork(TaxStatements.saga);
        yield fork(ActualStatements.saga);
        yield fork(FinancialOperationsTableFilter.saga);
    },
    *onInnerRouteChange(name, params) {
        switch (name) {
            case Tabs.EDIT_POLICY_HOLDER_FORM:
                yield call(contractEditHolderFormSaga, params.idObject);
                break;
            case Tabs.PENSION_BENEFICIARIES_FORM:
                yield call(pensionBeneficiariesFormSaga, params.idObject);
                break;
            case Tabs.PENSION_REDEEM_SAVINGS_FORM:
                yield call(redeemSavingsFormSaga, params.idObject);
                break;
            default:
                break;
        }
    },
    *onModalOpen(modalName) {
        const idObject = yield select(getIdObject);
        switch (modalName) {
            case FORM_EDIT_CONTRACT_NAME:
                yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
                break;
            case FORM_EDIT_CONTRACT_OWNER_PAYMENTS:
                yield call(contractOwnerPaymentsForm.createSaga, FORM_EDIT_CONTRACT_OWNER_PAYMENTS, idObject, getPaymentsScheduler);
                break;
            case FORM_EDIT_EMPLOYER_PAYMENTS:
                yield call(employerPaymentsForm.createSaga, FORM_EDIT_EMPLOYER_PAYMENTS, idObject, getPensionAccountDetail);
                break;
            case FORM_EDIT_TAX_OPTIMIZATION:
                yield call(taxOptimizationsForm.createSaga, FORM_EDIT_TAX_OPTIMIZATION, idObject, getPensionAccountDetail, getPolicyHolder);
                break;
            case FORM_EDIT_CLIENT_STATEMENT_SETTINGS:
                yield call(pensionStatementSettings.createSaga, FORM_EDIT_CLIENT_STATEMENT_SETTINGS, idObject, getClientStatementSettings);
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(
                ENTITY_CONTRACT_ADDITIONAL_INFO,
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_ENQUEUED_DOCUMENTS,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_FINANCIAL_STATEMENT,
                ENTITY_PAYMENT_INFORMATION,
            ),
        ];
    },
});

function* loadPaymentInformation(idObject) {
    const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
    yield call(entity.load, ENTITY_PAYMENT_INFORMATION, () => paymentApi.get(productGroup, idObject));
}

function* loadContractAdditionInformation(idObject) {
    yield call(entity.load, ENTITY_CONTRACT_ADDITIONAL_INFO, () => pensionPpApi.getPensionPpContractAdditionalInfo(idObject));
}

const contractEditHolderFormSaga = policyHolderForm.createSaga(
    FORM_EDIT_POLICY_HOLDER,
    getPolicyHolder,
    getPolicy,
    getElectronicCommunication,
);

const pensionBeneficiariesFormSaga = (idObject) => pensionBeneficiaries.createSagaPp(FORM_EDIT_BENEFICIARIES, getBeneficiaries, idObject);

const redeemSavingsFormSaga = (idObject) =>
    pensionRedeemSavings.createSagaPp(
        FORM_REDEEM_SAVINGS,
        idObject,
        getPolicyHolder,
        loadContractAdditionInformation,
        getContractAdditionalInfo,
        getRedeemAllowedPensionTypes,
        getRedeemAllowedRedeemTypes,
    );
