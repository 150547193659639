import { model } from "core/util";
import { PensionDpsAccountDetail as PensionDpsAccountDetailType, PensionDpsAccountDetailDto } from "types";

import { Payments, PaymentsScheduler } from "../../common";
import { PensionDpsTypes } from "./PensionDpsTypes";
import { PensionDpsSavingsStrategy } from "./PensionDpsSavingsStrategy";

export const PensionDpsAccountDetail = model.createModel<PensionDpsAccountDetailDto, PensionDpsAccountDetailType>((json) => ({
    pensionSavingsStrategy: PensionDpsSavingsStrategy.fromServer(json.PensionSavingStrategy),
    pensionTypes: PensionDpsTypes.fromServer(json.PensionTypes),
    paymentsScheduler: PaymentsScheduler.fromServer(json.PaymentsScheduler),
    employerPayments: Payments.fromServer(json.EmployerPayments),
    precedentPensionInformation: json.PrecedentPensionInformation,
    isTaxOptimizationEnabled: json.IsTaxOptimizationEnabled,
    isPassive: json.IsPassive,
}));
