import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import userContracts from "core/userContracts";
import router from "core/router";
import { GenerateStatementGridItem, PensionCzStatementSettings } from "containers/contract";
import { Pages, Tabs } from "routeConstants";

import { ENTITY_FINANCIAL_OPERATIONS, FORM_EDIT_CLIENT_STATEMENT_SETTINGS } from "../constants";
import { PensionFinancialOperationsGridItem } from "../containers";
import { ModalPensionStatementSettingsForm } from "../form";
import { ActualStatements, ExtraStatements, FinancialOperationsTableFilter, TaxStatements, YearStatements } from "../modules";
import { getClientStatementSettings, getFinancialOperations, isElectronicCommunicationSet } from "../selectors";

export const FinancialOperationsTab: FC = () => {
    const { t } = i18n.useTranslation();
    const { setStatement } = GenerateStatementGridItem;
    const financialOperations = useSelector(getFinancialOperations);
    const isElComSet = useSelector(isElectronicCommunicationSet);
    const { idObject } = useParams();
    const { canEdit: isContractEditable } = userContracts.useGetContractById(idObject);

    return (
        <GridLayout defaultSpacing>
            <PensionFinancialOperationsGridItem
                entityName={ENTITY_FINANCIAL_OPERATIONS}
                financialOperations={financialOperations}
                Filter={FinancialOperationsTableFilter.Container}
                filterModelSelector={FinancialOperationsTableFilter.getFilterModel}
            />

            <GenerateStatementGridItem
                statements={[
                    setStatement(t("statements.yearlyStatement"), YearStatements),
                    setStatement(t("statements.extraStatement"), ExtraStatements),
                    setStatement(t("statements.taxStatement"), TaxStatements),
                    setStatement(t("statements.actualStatement"), ActualStatements),
                ]}
            />

            <PensionCzStatementSettings
                isElComSet={isElComSet}
                getClientStatementSettings={getClientStatementSettings}
                clientStatementSettingsFormName={FORM_EDIT_CLIENT_STATEMENT_SETTINGS}
                ModalClientStatementSettingsForm={ModalPensionStatementSettingsForm}
                editPolicyHolderLink={router.getStaticUrl(Pages.CONTRACT_PP, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject })}
                isContractEditable={isContractEditable}
            />
        </GridLayout>
    );
};
