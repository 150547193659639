import React, { FC } from "react";
import { useSelector } from "react-redux";

import { GridLayout } from "ui-library/layouts";

import {
    BeneficiariesGridItem,
    ContractDataGridItem,
    FinancialStatementGridItem,
    InsuredPersonGridItemAxa,
    InsuredPersonGridItemUniqa,
} from "../containers";
import { ModalContractNameForm, ModalContractOwnerPaymentsForm } from "../form";
import { createGetStatementDate, getIsUniqaContract, getLifeAccountDetail } from "../selectors";

export const DetailTab: FC = () => {
    const statementDate = useSelector(createGetStatementDate);
    const lifeAccountDetail = useSelector(getLifeAccountDetail);
    const isAnySurrenderValueSet =
        Number(lifeAccountDetail?.surrenderAmount?.value) > 0 || Number(lifeAccountDetail?.deathSurrenderAmount?.value) > 0;

    const isUniqaContract = useSelector(getIsUniqaContract);

    return (
        <GridLayout defaultSpacing>
            {(statementDate || isAnySurrenderValueSet) && <FinancialStatementGridItem />}

            {isUniqaContract ? <InsuredPersonGridItemUniqa /> : <InsuredPersonGridItemAxa />}

            <BeneficiariesGridItem />

            <ContractDataGridItem />

            {/*/!* Modals *!/*/}
            <ModalContractNameForm />
            <ModalContractOwnerPaymentsForm />
        </GridLayout>
    );
};
