import React, { FC, PropsWithChildren } from "react";
import { DatePicker as DatePickerMUI, DatePickerProps as MuiDatePickerProps } from "@mui/x-date-pickers";
import i18n from "core/i18n";
import { ErrorContent, Icon, PopoverTooltip, Typography } from "ui-library/atoms";
import { StyledDatePicker } from "ui-library/Styled";
import { Box, Flexbox } from "ui-library/layouts";
import { Label } from "./Label";

const icon = () => {
    return <Icon size={16} icon={"calendar"} />;
};

export interface DatePickerProps extends PropsWithChildren /*, MuiDatePickerProps*/ {
    id: string;
    label?: string;
    tooltip?: string;
    error?: boolean;
    errorContent?: string;
    labelDesc?: string;
    labelOptional?: string;
    size?: string;
    views?: [];
    inputFormat?: string;
    mask?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
    labelDesc,
    errorContent = "Zadaný dátum nie je validný",
    id,
    label,
    tooltip,
    error,
    children,
    labelOptional,
    size = "medium",
    views,
    inputFormat = "dd.MM.yyyy",
    mask = "__.__.____",
    ...props
}) => {
    const { t } = i18n.useTranslation();

    const renderInput = (params) => {
        return (
            <>
                <StyledDatePicker
                    id={id}
                    $size={size}
                    inputProps={{
                        ...params.inputsProps,
                        label: null,
                        hiddenLabel: true,
                        variant: "outlined",
                        errorContent: errorContent,
                        error: params.error,
                    }}
                    {...params}
                    error={error || params.error}
                />
                {error || params.error ? <ErrorContent content={errorContent} /> : ""}
            </>
        );
    };

    return (
        <div>
            <Box>
                <Flexbox alignItems={"flex-start"} gap={1}>
                    <Label label={label} id={id} labelOptional={labelOptional} />
                    <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
                </Flexbox>
                {labelDesc && (
                    <Box mt={0.5} mb={1}>
                        <Typography color={"gray"} variant="caption">
                            {labelDesc}
                        </Typography>
                    </Box>
                )}
            </Box>

            <DatePickerMUI
                components={{
                    OpenPickerIcon: icon,
                }}
                inputFormat={inputFormat}
                mask={mask}
                renderInput={renderInput}
                toolbarTitle={t("common.chooseDate")}
                views={views}
                {...props}
            />
        </div>
    );
};

DatePicker.defaultProps = {
    errorContent: "Zadaný dátum nie je validný",
    size: "medium",
    inputFormat: "dd.MM.yyyy",
    mask: "__.__.____",
};
