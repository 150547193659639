import React from "react";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { IndividualPersonType } from "enums";
import { Section } from "ui-library/layouts";

import { SectionDescription, SectionTitle } from "../components";

import IndividualPersonFields from "./IndividualPersonFields";

const IndividualParticipantFormBox = ({ individualPersonType }) => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            <SectionTitle title={t(individualPersonType.msg)} />
            <SectionDescription content={t("form.editPolicyHolder.changePersonalDetails")} />
            <IndividualPersonFields />
        </Section>
    );
};

IndividualParticipantFormBox.propTypes = {
    individualPersonType: PropTypes.oneOf(Object.values(IndividualPersonType)).isRequired,
};

export default IndividualParticipantFormBox;
