import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import pensionImage from "ui-library/assets/images/dochodok-pozadie.svg";
import { Container } from "ui-library/atoms";
import { SubPageWrapper } from "ui-library/layouts";
import { Sidemenu } from "ui-library/modules";

import fetching from "core/fetching";
import i18n from "core/i18n";
import { localAmountWithCurrencyLegacy } from "core/formatters";
import userContracts from "core/userContracts";
import pageContext from "core/pageContext";

import { FeatureFlag } from "types";
import { ContractHeader, ProductDetailPageLayout } from "containers/contract";
import { AppSidemenuLink } from "containers/link";
import { PageBoundary } from "containers/PageBoundary";

import { Tabs } from "routeConstants";

import { ENTITY_FINANCIAL_STATEMENT } from "./constants";
import { getContractBalanceSheet, getPensionAccountDetail } from "./selectors";

export const DetailPpContainer: FC = () => {
    const { t } = i18n.useTranslation();
    const policy = userContracts.useGetContractByUrlParam();
    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(policy);

    const isStatementLoading = useSelector(fetching.isFetching(ENTITY_FINANCIAL_STATEMENT, true));
    const contractBalanceSheet = useSelector(getContractBalanceSheet);
    const isRedeemDisplayEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.PENSION_DISPLAY_REDEEM);

    const pensionBeingPaidText = pensionAccountDetail.isPassive ? `, ${t("common.pensionBeingPaid")}` : "";
    const lobData = {
        label: t("account.amountTotal"),
        value: `${localAmountWithCurrencyLegacy(contractBalanceSheet.getIn(["netAmountTotal"], null))}${pensionBeingPaidText}`,
    };

    return (
        <ProductDetailPageLayout>
            <Container>
                <ContractHeader backgroundImage={pensionImage} policy={policy} lobData={lobData} loading={isStatementLoading} />

                <PageBoundary>
                    <SubPageWrapper>
                        <Sidemenu>
                            <AppSidemenuLink tab={Tabs.OVERVIEW} title={t("menu.tab.overview")} />
                            <AppSidemenuLink tab={Tabs.HOLDER} title={t("menu.tab.holder.participant")} />
                            <AppSidemenuLink tab={Tabs.DETAIL} title={t("menu.tab.detail")} />
                            <AppSidemenuLink tab={Tabs.FINANCIAL_STATEMENT} title={t("menu.tab.accountStatement")} />
                            <AppSidemenuLink tab={Tabs.FINANCIAL_OPERATIONS} title={t("menu.tab.statementsAndPayments")} />
                            <AppSidemenuLink tab={Tabs.DOCUMENTS} title={t("menu.tab.documents")} />
                            {isRedeemDisplayEnabled && !isInFutureOrTerminated && (
                                <AppSidemenuLink tab={Tabs.REDEEM_SAVINGS} title={t("menu.tab.redeemSavings")} />
                            )}
                            <AppSidemenuLink tab={Tabs.AGENT} title={t("menu.tab.agent")} />
                        </Sidemenu>

                        <Outlet />
                    </SubPageWrapper>
                </PageBoundary>
            </Container>
        </ProductDetailPageLayout>
    );
};
