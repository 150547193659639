// top level

export enum Pages {
    LOGIN = "PAGE_LOGIN",
    REGISTRATION = "PAGE_REGISTRATION",
    RESET_PASSWORD = "PAGE_RESET_PASSWORD",
    IMPERSONATE = "PAGE_IMPERSONATE",
    CONTRACTS = "PAGE_CONTRACTS",
    CONTACT = "PAGE_CONTACT",
    CLAIMS = "PAGE_CLAIMS",
    MESSAGES = "PAGE_MESSAGES",
    INCIDENT_DETAIL = "PAGE_INCIDENT_DETAIL",
    CONTRACT_VEHICLE = "PAGE_CONTRACT_VEHICLE",
    CONTRACT_HOME = "PAGE_CONTRACT_HOME",
    CONTRACT_LIFE = "PAGE_CONTRACT_LIFE",
    CONTRACT_DIRECT = "PAGE_CONTRACT_DIRECT",
    CONTRACT_REGULAR = "PAGE_CONTRACT_REGULAR",
    CONTRACT_DDS = "PAGE_CONTRACT_DDS",
    CONTRACT_DPS = "PAGE_CONTRACT_DPS",
    CONTRACT_DSS = "PAGE_CONTRACT_DSS",
    CONTRACT_PP = "PAGE_CONTRACT_PP",
    CONTRACT_TRAVEL = "PAGE_CONTRACT_TRAVEL",
    ONLINE_PAYMENT = "PAGE_ONLINE_PAYMENT",
    ONLINE_PAYMENT_NOTIFICATION = "PAGE_ONLINE_PAYMENT_NOTIFICATION",
    DISCLAIMER = "PAGE_DISCLAIMER",
    USER = "PAGE_USER",
    EPIN_EXTERNAL = "PAGE_EPIN_EXTERNAL",
    NOTIFICATION = "NOTIFICATION",
    CAMPAIGN = "CAMPAIGN",
    EDIT = "EDIT",
}

export enum Tabs {
    // all lobs
    OVERVIEW = "OVERVIEW_TAB",
    HOLDER = "POLICY_HOLDER_TAB",
    DOCUMENTS = "DOCUMENTS_TAB",
    AGENT = "AGENT_TAB",
    NOTIFICATION = "NOTIFICATION",
    EDIT_ELECTRONIC_COMMUNICATION_FORM = "EDIT_ELECTRONIC_COMMUNICATION_FORM",
    EDIT_POLICY_HOLDER_FORM = "EDIT_POLICY_HOLDER_FORM",

    // all lobs except funds
    DETAIL = "DETAIL_TAB",

    // life & non-life, pension pp
    CLAIMS = "CLAIMS_TAB",
    FINANCIAL_OPERATIONS = "FINANCIAL_OPERATIONS_TAB",
    PAYMENTS = "PAYMENTS_TAB",
    REDEEM_SAVINGS = "REDEEM_SAVINGS_TAB",

    // funds
    INVESTMENTS = "INVESTMENTS_TAB",
    PORTFOLIO = "PORTFOLIO_TAB",
    DIRECT_PROFIT_DETAIL = "DIRECT_PROFIT_DETAIL",
    REGULAR_PROFIT_DETAIL = "REGULAR_PROFIT_DETAIL",
    SELL_SWITCH_FORM = "SELL_SWITCH_FORM",
    INVESTMENT_PROFILE_FORM = "INVESTMENT_PROFILE_FORM",
    SELL_SWITCH_FORM_WITH_ACTION = "SELL_SWITCH_FORM_WITH_ACTION",
    SELL_SWITCH_FORM_PARAMETER_PROPOSITION = "SELL_SWITCH_FORM_PARAMETER_PROPOSITION",
    REGULAR_FINANCIAL_OPERATION_DETAIL_BUY = "REGULAR_FINANCIAL_OPERATION_DETAIL_BUY",
    REGULAR_FINANCIAL_OPERATION_DETAIL_SELL = "REGULAR_FINANCIAL_OPERATION_DETAIL_SELL",

    // pensions
    FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT_TAB",
    PENSION_BENEFICIARIES_FORM = "PENSION_BENEFICIARIES_FORM",
    PENSION_DSS_FUNDS_CHANGE_FORM = "PENSION_DSS_FUNDS_CHANGE_FORM",
    PENSION_DDS_FUNDS_CHANGE_FORM = "PENSION_DDS_FUNDS_CHANGE_FORM",
    PENSION_FUNDS_CHANGE_FORM = "PENSION_FUNDS_CHANGE_FORM",
    PENSION_REDEEM_SAVINGS_FORM = "PENSION_REDEEM_SAVINGS_FORM",

    // dss
    PIS = "PIS_TAB",

    // payment
    PAYMENTS_PAY = "PAYMENTS_PAY_TAB",

    // Claim
    REPORT_CLAIM = "REPORT_CLAIM_TAB",
    REPORT_CLAIM_OLD = "REPORT_CLAIM_TAB_OLD",
    CLAIMS_LIST = "CLAIMS_LIST_TAB",

    // Messages
    ANNOUNCEMENTS_TAB = "ANNOUNCEMENTS_TAB",
    REQUESTS = "REQUESTS_TAB",

    // user settings
    USER_ACCOUNT = "USER_ACCOUNT_TAB",
    VERIFICATION = "VERIFICATION_TAB",
    DATA_PROCESSING = "DATA_PROCESSING_TAB",
    UNVERIFIED_USER = "UNVERIFIED_USER_TAB",
    USER_VERIFICATION_FORM = "USER_VERIFICATION_FORM",

    // page epin external
    EPIN_EXTERNAL_HASH = "EPIN_EXTERNAL_HASH",

    // impersonate
    IMPERSONATE_UUID = "IMPERSONATE_UUID",

    // Notification
    NOTIFICATION_CHANGE_PASSWORD_SUCCESS = "NOTIFICATION_CHANGE_PASSWORD_SUCCESS",
    NOTIFICATION_CHANGE_USERNAME_SUCCESS = "NOTIFICATION_CHANGE_USERNAME_SUCCESS",

    // ELECTRONIC_COMMUNICATION
    ELECTRONIC_COMMUNICATION = "ELECTRONIC_COMMUNICATION",
    ELECTRONIC_COMMUNICATION_SUCCESS = "ELECTRONIC_COMMUNICATION_SUCCESS",
    FORM_ELECTRONIC_COMMUNICATION = "FORM_ELECTRONIC_COMMUNICATION",
}

export enum Params {
    PAYMENT_CONTRACT_ID = "contractId",
    CHANGE_PROPOSITION_ID = "propositionId",
    PAYMENT_FUND_ID = "fundId",
    PAYMENT_AMOUNT_TYPE = "amountType",
    CLAIM_REPORT_CATEGORY = "category",
    CLAIM_LINE_OF_BUSINESS_CLASS = "lineOfBusinessClass",
    ID_OBJECT = "idObject",
}

export type ParamStrings = `${Params}`;

// Full page forms and pages.
export const FULL_PAGE_FORMS = [
    Tabs.SELL_SWITCH_FORM,
    Tabs.SELL_SWITCH_FORM_WITH_ACTION,
    Tabs.INVESTMENT_PROFILE_FORM,
    Tabs.EDIT_POLICY_HOLDER_FORM,
    Tabs.PENSION_BENEFICIARIES_FORM,
    Tabs.PENSION_DSS_FUNDS_CHANGE_FORM,
    Tabs.PENSION_DDS_FUNDS_CHANGE_FORM,
    Tabs.PENSION_REDEEM_SAVINGS_FORM,
    Tabs.UNVERIFIED_USER,
    Tabs.USER_VERIFICATION_FORM,
];
