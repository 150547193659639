import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import userContracts from "core/userContracts";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";
import { ContractPolicyHolderTabLegacy } from "containers/contract";

import { getElectronicCommunication, getPolicyHolder } from "../selectors";

export const PolicyHolderTab = () => {
    const { idObject } = useParams();

    const policyHolder = useSelector(getPolicyHolder);
    const electronicCommunication = useSelector(getElectronicCommunication);
    const canEditContract = userContracts.useGetContractById(idObject)?.canEdit;

    return (
        <ContractPolicyHolderTabLegacy
            holder={policyHolder}
            editLink={router.getStaticUrl(Pages.CONTRACT_HOME, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject })}
            isContractEditable={canEditContract}
            electronicCommunication={electronicCommunication}
        />
    );
};
