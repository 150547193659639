import React from "react";
import { useDispatch } from "react-redux";

import i18n from "core/i18n";
import modal from "core/modal";
import { Dropdown, DropdownItem } from "ui-library/modules";
import { StandaloneLink } from "ui-library/atoms";

import { FORM_EDIT_CONTRACT_OWNER_PAYMENTS, FORM_EDIT_EMPLOYER_PAYMENTS, FORM_EDIT_TAX_OPTIMIZATION } from "../../constants";

export const ContributionsEditDropdown = () => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isDropdownOpen = Boolean(anchorEl);

    const openDropdown = (event) => setAnchorEl(event.currentTarget);
    const closeDropdown = () => setAnchorEl(null);

    const openModalForm = (formName) => {
        closeDropdown();
        dispatch(modal.openModalForm(formName));
    };

    return (
        <>
            <StandaloneLink color="blue" onClick={openDropdown}>
                {t("common.edit")}
            </StandaloneLink>

            <Dropdown position={"left"} anchorEl={anchorEl} open={isDropdownOpen} onClose={closeDropdown}>
                <DropdownItem onClick={() => openModalForm(FORM_EDIT_CONTRACT_OWNER_PAYMENTS)}>{t("account.holderPayments")}</DropdownItem>
                <DropdownItem onClick={() => openModalForm(FORM_EDIT_EMPLOYER_PAYMENTS)}>{t("account.employerPayments")}</DropdownItem>
                <DropdownItem onClick={() => openModalForm(FORM_EDIT_TAX_OPTIMIZATION)}>{t("pension.taxOptimization")}</DropdownItem>
            </Dropdown>
        </>
    );
};
