import React, { FC } from "react";
import { useSelector } from "react-redux";

import init from "core/init";
import { Loading } from "ui-library/atoms";

import { AppRoutes } from "routes";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { LoadingLayout } from "./LoadingLayout";

export const MainLayout: FC = () => {
    const initialized = useSelector(init.isInitialized);

    return (
        <>
            <Loading fullPage loading={!initialized} />
            <Header />

            {initialized ? <AppRoutes /> : <LoadingLayout />}

            <Footer />
        </>
    );
};
