import React from "react";
import { useSelector } from "react-redux";

import { Container } from "ui-library/atoms";
import { Box, Flexbox, GridItem, GridLayout, Section, StaticPageLayout } from "ui-library/layouts";
import useBreakpoints from "ui-library/theme/breakpoints";

import pageContext from "core/pageContext";
import userInfo from "core/userInfo";
import { PatalieNewsGridItem } from "containers/cms";
import { ContractListContentType } from "./constants";

import { Filter } from "./containers/Filter";
import { InfoMessageInfoPanel, SideInfoPanels, UnverifiedUserPanel } from "./components";
import { Contracts, Documents } from "./containers";
import { getDisplayedContentType } from "./selectors";
import { ViewType } from "ui-library/modules/enums";

export default () => {
    const displayedContentType = useSelector(getDisplayedContentType);
    const [viewType, setViewType] = React.useState(ViewType.BASE);

    const isContractsDisplaySet = (displayType) =>
        displayType === ContractListContentType.ACTIVE_CONTRACTS || displayType === ContractListContentType.INACTIVE_CONTRACTS;
    const isDocumentsDisplaySet = (displayType) => displayType === ContractListContentType.DOCUMENTS;
    const isUserVerified = useSelector(userInfo.getIsVerifiedUser);
    const infoTexts = useSelector(pageContext.getInfoTexts);

    return (
        <StaticPageLayout>
            <Container>
                <GridLayout defaultSpacing>
                    {/* Unverified user warning - mobile only  */}
                    {!useBreakpoints("md") && !isUserVerified && (
                        <GridItem xs={12}>
                            <UnverifiedUserPanel />
                        </GridItem>
                    )}

                    {/* Top Info Message for clients. */}
                    {infoTexts?.enableHomepageAlert && (
                        <GridItem xs={12}>
                            <InfoMessageInfoPanel text={infoTexts.homepageText} />
                        </GridItem>
                    )}

                    {/* Contract display style select */}
                    <GridItem xs={12} md={8}>
                        <Filter viewType={viewType} setViewType={setViewType} />
                    </GridItem>

                    {/* Contract and documents list */}
                    <GridItem xs={12} md={8}>
                        <Section>
                            <Flexbox direction="column" gap={4}>
                                {isContractsDisplaySet(displayedContentType) && (
                                    <Contracts displayedContentType={displayedContentType} viewType={viewType} />
                                )}
                                {isDocumentsDisplaySet(displayedContentType) && <Documents />}
                            </Flexbox>
                        </Section>

                        {/* Patalie full page */}
                        {useBreakpoints("md") && (
                            <Section>
                                <PatalieNewsGridItem />
                            </Section>
                        )}
                    </GridItem>

                    {/* Right side info panels */}
                    <GridItem xs={12} md={4}>
                        <Flexbox mb={{ xs: 3, md: 0 }} direction={"column"} gap={{ xs: 2, md: 4 }}>
                            <SideInfoPanels />
                        </Flexbox>
                    </GridItem>

                    {/* Patalie mobile */}
                    {!useBreakpoints("md") && (
                        <GridItem xs={12} md={8}>
                            <Box>
                                <PatalieNewsGridItem />
                            </Box>
                        </GridItem>
                    )}
                </GridLayout>
            </Container>
        </StaticPageLayout>
    );
};
