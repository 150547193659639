import React, { FC } from "react";

import { StandaloneLink } from "ui-library/atoms";
import { Box, GridItem, GridLayout } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { localPhone } from "core/formatters";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { model } from "core/util";
import { AddressTypeCodeEnum, ElectronicCommunication, Holder } from "types";

import { HolderPersonSection } from "./HolderPersonSection";
import { HolderCompanySection } from "./HolderCompanySection";

type EditLinkProps = {
    editLink: string;
};

const EditLink: FC<EditLinkProps> = ({ editLink }) => {
    const { t } = i18n.useTranslation();

    return (
        <StandaloneLink to={editLink} color="blue">
            {t("common.edit")}
        </StandaloneLink>
    );
};

type ContractPolicyHolderTabProps = {
    holder: Holder;
    isExtended?: boolean;
    showInsuranceNumber?: boolean;
    editLink: string;
    electronicCommunication?: ElectronicCommunication;
    isContractEditable: boolean;
};

export const ContractPolicyHolderTab: FC<ContractPolicyHolderTabProps> = ({
    holder,
    isExtended = false,
    showInsuranceNumber = true,
    editLink,
    electronicCommunication = null,
    isContractEditable,
}) => {
    const { t } = i18n.useTranslation();
    const hasEditPermission = useHasEditContractPermission();

    return (
        <GridLayout defaultSpacing>
            <GridItem xs={12}>
                <InfoPanel
                    icon="user"
                    title={t("common.personalData")}
                    headerAction={
                        hasEditPermission &&
                        isContractEditable && (
                            <StandaloneLink color="blue" to={editLink}>
                                {t("common.edit")}
                            </StandaloneLink>
                        )
                    }
                >
                    <>
                        {holder.isPerson && (
                            <HolderPersonSection holder={holder} isExtended={isExtended} showInsuranceNumber={showInsuranceNumber} />
                        )}
                        {!holder.isPerson && <HolderCompanySection holder={holder} />}
                    </>
                </InfoPanel>
            </GridItem>
            <GridItem xs={12}>
                <InfoPanel
                    icon="messages"
                    title={t("common.contact")}
                    headerAction={hasEditPermission && isContractEditable && <EditLink editLink={editLink} />}
                >
                    <Box>
                        <LabelTextItem label={t("holder.phone")} text={localPhone(holder.phone)} />
                        <LabelTextItem label={t("holder.email")} text={holder.email} />
                        <LabelTextItem
                            label={t("holder.contactAddress")}
                            text={getLocalizedOneLineAddressFull(t, model.getAddressByTypeCode(holder, AddressTypeCodeEnum.CON))}
                        />

                        <LabelTextItem
                            label={t("common.eCommunication")}
                            text={electronicCommunication?.electronicCommunicationSet ? t("common.yes") : t("common.no")}
                        />
                    </Box>
                </InfoPanel>
            </GridItem>
        </GridLayout>
    );
};
