import { call, fork, put, select } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import userContracts from "core/userContracts";
import modal from "core/modal";

import policyHolderForm from "form/fullPage/policyHolder";
import contractNameForm from "form/modal/contractName";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import pensionBeneficiaries from "form/fullPage/pensionBeneficiaries";
import pensionFundsChangeForm from "form/fullPage/pensionFundsChange";
import statementPasswordForm from "form/modal/statementPassword";
import pensionPisSettingConfirmationForm from "form/modal/pensionPisSettingConfirmation";
import investmentStrategyForm from "form/modal/pensionInvestmentStrategy";
import { documentsApi, paymentApi, pensionDssApi } from "serverApi";
import { Params, Tabs } from "routeConstants";

import { clear, setFundsChangeFormChangeType, setPensionDssContractDetail, setPisFormChangeResult } from "./actions";
import {
    ENTITY_CHANGE_PROPOSITIONS,
    ENTITY_CONTRACT_DOCUMENTS,
    ENTITY_ENQUEUED_DOCUMENTS,
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_FINANCIAL_STATEMENT,
    ENTITY_FUNDS_CHANGE_PERMITTED,
    ENTITY_PAYMENT_INFORMATION,
    FORM_EDIT_BENEFICIARIES,
    FORM_EDIT_CONTRACT_NAME,
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    FORM_EDIT_FUNDS_CHANGE,
    FORM_EDIT_INVESTMENT_STRATEGY,
    FORM_EDIT_POLICY_HOLDER,
    FORM_EDIT_STATEMENT_PASSWORD,
    FORM_PIS_CONFIRMATION,
} from "./constants";
import {
    getBeneficiaries,
    getDisplayEnableButton,
    getElectronicCommunication,
    getIdObject,
    getInvestmentStrategy,
    getPaymentsScheduler,
    getPolicy,
    getPolicyHolder,
    getStatementPassword,
} from "./selectors";
import {
    ActualStatements,
    ChangePropositionModule,
    ExtraStatements,
    FinancialOperationsTableFilter,
    OtpModule,
    YearStatements,
} from "./modules";

export default router.routerWrapper({
    *getDataForPage({ idObject }) {
        const contractDetail = yield call(pensionDssApi.getPensionDssContractDetail, idObject);
        return [setPensionDssContractDetail(contractDetail)];
    },
    *onPageEnter({ idObject }) {
        yield fork(entity.load, ENTITY_FINANCIAL_STATEMENT, () => pensionDssApi.getPensionDssFinancialStatement(idObject));
        yield fork(entity.load, ENTITY_CONTRACT_DOCUMENTS, () => documentsApi.getContractDocuments(idObject));
        yield fork(entity.load, ENTITY_ENQUEUED_DOCUMENTS, () => documentsApi.getContractEnqueuedDocuments(idObject));
        yield fork(entity.load, ENTITY_FINANCIAL_OPERATIONS, () => pensionDssApi.getPensionDssFinancialOperations(idObject));
        yield fork(loadPaymentInformation, idObject);

        yield fork(ExtraStatements.saga);
        yield fork(ActualStatements.saga);
        yield fork(YearStatements.saga);
        yield fork(OtpModule.saga);
        yield fork(ChangePropositionModule.sagaDataInitialization, idObject);
        yield fork(FinancialOperationsTableFilter.saga);
    },
    *onInnerRouteChange(name, params, query) {
        switch (name) {
            case Tabs.AGENT:
                yield call(ChangePropositionModule.saga, params.idObject);
                break;
            case Tabs.EDIT_POLICY_HOLDER_FORM:
                yield call(contractEditPolicyHolderFormSaga, params.idObject);
                break;
            case Tabs.PENSION_BENEFICIARIES_FORM:
                yield call(pensionBeneficiariesFormSaga, params.idObject);
                break;
            case Tabs.PENSION_DSS_FUNDS_CHANGE_FORM:
                yield put(setFundsChangeFormChangeType(params.changeType));
                yield fork(entity.load, ENTITY_FUNDS_CHANGE_PERMITTED, () =>
                    pensionDssApi.getIsPensionDssFundsChangePermittedByType(params.idObject, params.changeType),
                );
                yield call(pensionFundsChangeFormSaga, params.changeType, params.idObject, query[Params.CHANGE_PROPOSITION_ID]);
                break;
            default:
                break;
        }
    },
    *onModalOpen(modalName) {
        const idObject = yield select(getIdObject);
        switch (modalName) {
            case FORM_EDIT_CONTRACT_NAME:
                yield call(contractNameForm.createSaga, FORM_EDIT_CONTRACT_NAME, idObject);
                break;
            case FORM_EDIT_CONTRACT_OWNER_PAYMENTS:
                yield call(contractOwnerPaymentsForm.createSaga, FORM_EDIT_CONTRACT_OWNER_PAYMENTS, idObject, getPaymentsScheduler);
                break;
            case FORM_EDIT_STATEMENT_PASSWORD:
                yield call(statementPasswordForm.createSaga, FORM_EDIT_STATEMENT_PASSWORD, idObject, getStatementPassword);
                break;
            case FORM_EDIT_INVESTMENT_STRATEGY:
                yield call(
                    investmentStrategyForm.createSaga,
                    FORM_EDIT_INVESTMENT_STRATEGY,
                    idObject,
                    getInvestmentStrategy,
                    refreshContractData,
                );
                break;
            case FORM_PIS_CONFIRMATION:
                yield call(
                    pensionPisSettingConfirmationForm.createSaga,
                    FORM_PIS_CONFIRMATION,
                    idObject,
                    getDisplayEnableButton,
                    refreshContractData,
                    setPisFormChangeResult,
                );
                break;
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(
                ENTITY_FINANCIAL_STATEMENT,
                ENTITY_PAYMENT_INFORMATION,
                ENTITY_CONTRACT_DOCUMENTS,
                ENTITY_ENQUEUED_DOCUMENTS,
                ENTITY_FINANCIAL_OPERATIONS,
                ENTITY_FUNDS_CHANGE_PERMITTED,
                ENTITY_CHANGE_PROPOSITIONS,
            ),
        ];
    },
});

function* loadPaymentInformation(idObject) {
    const productGroup = yield select(userContracts.createGetProductGroupUrlNameById(idObject));
    yield call(entity.load, ENTITY_PAYMENT_INFORMATION, () => paymentApi.get(productGroup, idObject));
}

const contractEditPolicyHolderFormSaga = policyHolderForm.createSaga(
    FORM_EDIT_POLICY_HOLDER,
    getPolicyHolder,
    getPolicy,
    getElectronicCommunication,
);

const pensionBeneficiariesFormSaga = (idObject) =>
    pensionBeneficiaries.createSagaDdsDss(FORM_EDIT_BENEFICIARIES, getBeneficiaries, idObject, false);

const pensionFundsChangeFormSaga = (changeType, idObject, changePropositionId) =>
    pensionFundsChangeForm.createSagaDss(
        FORM_EDIT_FUNDS_CHANGE,
        OtpModule.setResent,
        OtpModule.setPhoneNumber,
        ChangePropositionModule.getChangeProposition,
        loadChangePropositions,
        changeType,
        idObject,
        changePropositionId,
    );

function* loadChangePropositions(idObject) {
    yield fork(ChangePropositionModule.sagaDataInitialization, idObject);
}

function* refreshContractData(idObject) {
    const contractDetail = yield call(pensionDssApi.getPensionDssContractDetail, idObject);
    yield put(setPensionDssContractDetail(contractDetail));
}
