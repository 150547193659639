import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { MenuLink } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { Dropdown, DropdownItem, LoggedUser } from "ui-library/modules";
import { MenuWrapper } from "ui-library/Styled/Modules/Header";

import auth from "core/auth";
import i18n from "core/i18n";
import pageContext from "core/pageContext";
import router from "core/router";
import userInfo from "core/userInfo";
import user from "core/user";
import { AUTHORIZATION_TYPE_IMPERSONATION } from "app/constants";
import { ChangeLanguageButton } from "containers/buttons";
import { oneLineNameWithTitles } from "core/formatters";
import { Pages, Tabs } from "routeConstants";
import { FeatureFlag } from "types";

export interface LoggedInMenuProps {
    isMenuOpened: boolean;
    isDisclaimerAccepted?: boolean;
}

export const LoggedInMenu: FC<LoggedInMenuProps> = ({ isMenuOpened, isDisclaimerAccepted = false }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const isLanguageChangeEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.ENABLE_LANGUAGE_CHANGE);

    const authenticatedUser = useSelector(userInfo.getAuthenticatedUser);
    const userName = oneLineNameWithTitles(authenticatedUser.get("client"));
    const unreadMessageCount = useSelector(userInfo.getUnreadMessageCount);
    const unreadMessageCountString = unreadMessageCount > 1 ? `${unreadMessageCount}` : null;

    const userAccount = useSelector(user.getUserAccount);
    const isImpersonation = userAccount?.authorizationType === AUTHORIZATION_TYPE_IMPERSONATION;
    const impersonator = userAccount?.impersonator;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isUserMenuOpened = Boolean(anchorEl);
    const handleOpenUserMenu = (event) => setAnchorEl(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorEl(null);

    let navigate = useNavigate();
    const handleUserMenuClick = (path) => {
        navigate(path);
        handleCloseUserMenu();
    };
    const isClaimReportNewEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.CLAIM_REPORT_NEW);

    const MainMenu = () => (
        <MenuWrapper order={{ xs: 1, md: 0 }} className={isMenuOpened ? "opened" : ""}>
            <MenuLink to={router.getStaticUrl(Pages.CONTRACTS)}>{t("menu.contracts")}</MenuLink>
            <MenuLink to={router.getStaticUrl(Pages.ONLINE_PAYMENT, Tabs.PAYMENTS_PAY)}>{t("menu.payment")}</MenuLink>
            <MenuLink notificationCount={unreadMessageCountString} to={router.getStaticUrl(Pages.MESSAGES, Tabs.ANNOUNCEMENTS_TAB)}>
                {t("menu.messages")}
            </MenuLink>
            {isClaimReportNewEnabled ? (
                <MenuLink to={router.getStaticUrl(Pages.CLAIMS, Tabs.REPORT_CLAIM)}>{t("menu.claims")}</MenuLink>
            ) : (
                <MenuLink to={router.getStaticUrl(Pages.CLAIMS, Tabs.REPORT_CLAIM_OLD)}>{t("menu.claims")}</MenuLink>
            )}
            <MenuLink to={router.getStaticUrl(Pages.CONTACT)}>{t("menu.contact")}</MenuLink>
        </MenuWrapper>
    );

    const UserMenuItemsDisclaimerAccepted = () => (
        <>
            <DropdownItem icon={"address-card"} onClick={() => handleUserMenuClick(router.getStaticUrl(Pages.USER, Tabs.VERIFICATION))}>
                {t("holder.verification")}
            </DropdownItem>
            <DropdownItem icon={"settings"} onClick={() => handleUserMenuClick(router.getStaticUrl(Pages.USER, Tabs.USER_ACCOUNT))}>
                {t("holder.settings")}
            </DropdownItem>
            <DropdownItem icon={"user-settings"} onClick={() => handleUserMenuClick(router.getStaticUrl(Pages.USER, Tabs.DATA_PROCESSING))}>
                {t("userSettings.personalDataProcessing.title")}
            </DropdownItem>
        </>
    );

    return (
        <>
            {isDisclaimerAccepted && <MainMenu />}
            <Flexbox flexDirection={{ xs: "row", md: "row" }} alignItems={"center"}>
                {isLanguageChangeEnabled && (
                    <Box>
                        <ChangeLanguageButton />
                    </Box>
                )}
                <Box order={{ xs: 0, md: 1 }} mr={{ xs: 0, md: 0 }}>
                    <LoggedUser
                        name={userName}
                        open={isUserMenuOpened}
                        onClick={handleOpenUserMenu}
                        isImpersonation={isImpersonation}
                        impersonator={impersonator}
                    />
                    <Dropdown
                        position={"right"}
                        title={t("holder.personalProfile")}
                        anchorEl={anchorEl}
                        open={isUserMenuOpened}
                        onClose={handleCloseUserMenu}
                    >
                        {isDisclaimerAccepted && <UserMenuItemsDisclaimerAccepted />}
                        <DropdownItem icon={"logout"} onClick={() => dispatch(auth.logOut(auth.LogoutAction.USER_LOGOUT))}>
                            {t("common.logout")}
                        </DropdownItem>
                    </Dropdown>
                </Box>
            </Flexbox>
        </>
    );
};
