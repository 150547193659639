import React, { FC } from "react";
import { GridLayout } from "ui-library/layouts";

import { PensionPpBeneficiariesGridItem, ContractDataGridItem, ContributionsGridItem, PensionTypesGridItem } from "../containers";
import { ModalContractNameForm, ModalContractOwnerPaymentsForm, ModalEmployerPaymentsForm, ModalTaxOptimizationsForm } from "../form";

export const DetailTab: FC = () => (
    <GridLayout defaultSpacing>
        <ContractDataGridItem />

        <PensionTypesGridItem mdSize={6} />

        <ContributionsGridItem />

        <PensionPpBeneficiariesGridItem />

        {/* Modals */}
        <ModalContractNameForm />
        <ModalContractOwnerPaymentsForm />
        <ModalEmployerPaymentsForm />
        <ModalTaxOptimizationsForm />
    </GridLayout>
);
