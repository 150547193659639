import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import useBreakpoints from "ui-library/theme/breakpoints";
import { InfoPanel } from "ui-library/modules";
import { ContentBox } from "ui-library/layouts";
import { Button, Typography } from "ui-library/atoms";

import i18n from "core/i18n";
import router from "core/router";
import { Pages, Tabs } from "routeConstants";

export const SetElectronicCommunicationInfoPanel: FC = () => {
    const { t } = i18n.useTranslation();
    const navigate = useNavigate();
    const breakpointMd = useBreakpoints("md");

    return (
        <InfoPanel
            droppable={!breakpointMd}
            bgVariant={"greenGradient"}
            icon={"mail"}
            title={t("page.contractsList.electronicCommunication.title")}
            actionButton={
                <Button
                    variant={"outlined"}
                    color={"green"}
                    onClick={() => navigate(router.getStaticUrl(Pages.EDIT, Tabs.FORM_ELECTRONIC_COMMUNICATION))}
                >
                    {t("common.setElectronicCommunication")}
                </Button>
            }
        >
            <ContentBox>
                <Typography color={"gray"} variant={"p"} markDown={t("page.contractsList.electronicCommunication.text")} />
            </ContentBox>
        </InfoPanel>
    );
};
