import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { Selector } from "@reduxjs/toolkit";

import { Typography } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { formatLocalizedValueMap, formatYesNo, localFullDate } from "core/formatters";
import { getLocalizedPensionStatementFrequencyMap } from "core/localized";
import { PensionStatementSettings, State } from "types";

import { EditLinksDropdown } from "containers/EditLinksDropdown";

export interface PensionCzStatementSettingsProps {
    isElComSet: boolean;
    getClientStatementSettings: Selector<State, PensionStatementSettings>;
    clientStatementSettingsFormName: string;
    ModalClientStatementSettingsForm: FC;
    editPolicyHolderLink: string;
    isContractEditable: boolean;
}

export const PensionCzStatementSettings: FC<PensionCzStatementSettingsProps> = ({
    isElComSet,
    getClientStatementSettings,
    clientStatementSettingsFormName,
    ModalClientStatementSettingsForm,
    editPolicyHolderLink,
    isContractEditable,
}) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const statementSettings = useSelector(getClientStatementSettings);
    const frequencyMap = useSelector(getLocalizedPensionStatementFrequencyMap);
    const formattedPeriodicity = formatLocalizedValueMap(statementSettings.statementPeriodicity, frequencyMap);
    const hasEditPermission = useHasEditContractPermission();

    const missingElComText =
        hasEditPermission && isContractEditable
            ? t("general.clientStatement.missingElectronicCommunication", { link: editPolicyHolderLink })
            : t("general.clientStatement.missingElectronicCommunicationNoEdit");

    const editLinksElcomSet = [
        {
            formConst: clientStatementSettingsFormName,
            name: "form.clientStatementSettings",
        },
    ];

    return (
        <GridItem display={"flex"} xs={12}>
            <InfoPanel
                icon={"document"}
                title={t("statements.statementSettings")}
                headerAction={hasEditPermission && isElComSet && isContractEditable && <EditLinksDropdown links={editLinksElcomSet} />}
            >
                {isElComSet ? (
                    <ContentBox>
                        <LabelTextItem
                            label={t("general.statements.receiveStatement")}
                            text={formatYesNo(t, statementSettings.receiveStatement)}
                        />
                        <LabelTextItem label={t("general.statements.statementPeriodicity")} text={formattedPeriodicity} />
                        <LabelTextItem
                            label={t("general.statements.lastStatementSendDate")}
                            text={localFullDate(statementSettings.lastSendDate)}
                        />
                    </ContentBox>
                ) : (
                    <>
                        <ContentBox>
                            <Typography markDown={missingElComText} />
                        </ContentBox>
                    </>
                )}
            </InfoPanel>

            {/* Modals */}
            {isElComSet && <ModalClientStatementSettingsForm />}
        </GridItem>
    );
};
