import { FC } from "react";
import { useParams } from "react-router-dom";

import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";
import userContracts from "core/userContracts";

export const ModalContractOwnerPaymentsFormContainer = contractOwnerPaymentsForm.createContainer(
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    "form.paymentsScheduler.pension",
);

export const ModalContractOwnerPaymentsForm: FC = () => {
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);

    return <ModalContractOwnerPaymentsFormContainer isUniqaContract={contract?.isUniqaContract} />;
};
