import { createContainer } from "./createContainer";
import { createSaga } from "./createSaga";

/**
 * Saga and Container creator for Licence plate number form
 */
const licencePlateNumberForm = {
    createSaga,
    createContainer,
};
export default licencePlateNumberForm;
