import { call, put, select } from "redux-saga/effects";

import errorHandling from "core/errorHandling";
import { formWrapper } from "core/form";
import userInfo from "core/userInfo";
import { sentry } from "core/util";
import { contractDataChangeApi } from "serverApi";

export function* createSaga(formName, idObject, getInsuredVehicle) {
    try {
        yield call(formSaga(formName, getInsuredVehicle), idObject);
    } catch (e) {
        sentry.captureException(e);
        yield put(errorHandling.addServiceError(formName, e.identifier));
    }
}

const formSaga = (formName, getInsuredVehicle) =>
    formWrapper(formName, {
        *initialize(idObject) {
            yield call(userInfo.checkUserVerified);
            yield put(errorHandling.removeServiceErrors(formName));

            const insuredVehicle = yield select(getInsuredVehicle);

            return {
                idObject,
                vinNumber: insuredVehicle?.vinNumber,
                vehicleLicenceNumber: insuredVehicle?.vehicleLicenceNumber,
            };
        },
        *save(values) {
            yield call(contractDataChangeApi.updateVehicleLicenceNumber, values);
            return values;
        },
    });
