import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ContractPolicyHolderTabLegacy } from "containers/contract";

import { getElectronicCommunication, getPolicyHolder } from "../selectors";
import router from "core/router";
import userContracts from "core/userContracts";
import { Pages, Tabs } from "routeConstants";

export const PolicyHolderTab = () => {
    const { idObject } = useParams();
    const policyHolder = useSelector(getPolicyHolder);
    const electronicCommunication = useSelector(getElectronicCommunication);
    const { canEdit: canEditContract } = userContracts.useGetContractById(idObject);
    return (
        <ContractPolicyHolderTabLegacy
            holder={policyHolder}
            editLink={router.getStaticUrl(Pages.CONTRACT_LIFE, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject })}
            isContractEditable={canEditContract}
            electronicCommunication={electronicCommunication}
        />
    );
};
