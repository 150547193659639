import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Selector } from "@reduxjs/toolkit";

import { Container, Loading } from "ui-library/atoms";
import { FormBlock } from "ui-library/modules";

import { form } from "core/form";
import i18n from "core/i18n";
import userContracts from "core/userContracts";

import { BulkChangeFormBox } from "containers/bulkChange";
import { FullPageFormLayout } from "containers/fullPageForm";
import { Holder, State } from "types";
import { CLIENT_TYPE_COMPANY, CLIENT_TYPE_INDIVIDUAL } from "./constants";
import { getClientTypeCallback, isIdentificationCardIdDirtyCallback } from "./selectors";
import { BackToPolicyHolderButton, ButtonsBox } from "./components";
import {
    AttachmentsFormBox,
    CompanyParticipantFormBox,
    ContactAddressFormBox,
    ElectronicCommunicationFormBox,
    EmailFormBox,
    IdentityCardTypeFormBox,
    IndividualParticipantFormBox,
    PermanentAddressFormBox,
    TelephoneFormBox,
} from "./containers";

export const getAreInasNumbersTheSame = (policyHolder: Holder) => {
    // @ts-ignore TODO: martin.rodin these fields are not present in any model, check with Karol
    if (!policyHolder.InasId && !policyHolder.InasContactId) {
        return true;
    }
    // @ts-ignore TODO: martin.rodin these fields are not present in any model, check with Karol
    return policyHolder.InasId === policyHolder.InasContactId;
};

type EditFormProps = {
    canSubmit: boolean;
    handleSubmit: () => void;
    submitting: boolean;
    formName: string;
    individualPersonType: any;
    getIdObject: Selector<State, number>;
    getPolicyHolder: Selector<State, Holder>;
    isIdentificationDocShown: boolean;
};

const EditForm: FC<EditFormProps> = ({
    canSubmit,
    handleSubmit,
    submitting,
    formName,
    individualPersonType,
    getIdObject,
    getPolicyHolder,
    isIdentificationDocShown,
}) => {
    const idCardDirty = useSelector(isIdentificationCardIdDirtyCallback(formName));
    const clientType = useSelector(getClientTypeCallback(formName));
    const idObject = useSelector(getIdObject);
    const policyHolder = useSelector(getPolicyHolder);
    const contract = userContracts.useGetContractById(idObject);
    const areInasNumbersTheSame = getAreInasNumbersTheSame(policyHolder);

    // For cases when rendering of form is sooner that store contains idObject. Happens on browser back.
    if (!contract) {
        return null;
    }

    return (
        <Container maxWidth="md">
            <Loading fullPage loading={submitting} />
            <FormBlock>
                {clientType === CLIENT_TYPE_INDIVIDUAL ? (
                    <IndividualParticipantFormBox individualPersonType={individualPersonType} />
                ) : (
                    <CompanyParticipantFormBox individualPersonType={individualPersonType} />
                )}

                <PermanentAddressFormBox isCompany={clientType === CLIENT_TYPE_COMPANY} formName={formName} />

                <ContactAddressFormBox
                    isCompany={clientType === CLIENT_TYPE_COMPANY}
                    formName={formName}
                    isUniqaContract={contract.isUniqaContract}
                    contractCountry={contract.countryCode}
                />

                {isIdentificationDocShown && clientType === CLIENT_TYPE_INDIVIDUAL && <IdentityCardTypeFormBox idCardDirty={idCardDirty} />}

                <TelephoneFormBox />

                <EmailFormBox />
                
                <ElectronicCommunicationFormBox />

                <AttachmentsFormBox
                    formName={formName}
                    isCompany={clientType === CLIENT_TYPE_COMPANY}
                    isIdentificationDocShown={isIdentificationDocShown}
                />

                {/* @ts-ignore TODO: martin.rodin type this later if necessary */}
                <BulkChangeFormBox
                    idObject={idObject}
                    formName={formName}
                    isCompany={clientType === CLIENT_TYPE_COMPANY}
                    individualPersonType={individualPersonType}
                    isUniqaContract={contract.isUniqaContract}
                    areInasNumbersTheSame={areInasNumbersTheSame}
                />

                <ButtonsBox handleSubmit={handleSubmit} formName={formName} idObject={idObject} canSubmit={canSubmit} />
            </FormBlock>
        </Container>
    );
};

const createContainer = (
    formName: string,
    individualPersonType: any,
    getIdObject: Selector<State, number>,
    getPolicyHolder: Selector<State, Holder>,
    isIdentificationDocShown = false,
) =>
    form(formName, {
        asyncValidationFields: ["mobile", "identificationCard.cardId"],
    })((props) => {
        const { t } = i18n.useTranslation();
        const idObject = useSelector(getIdObject);

        return (
            <FullPageFormLayout
                formName={formName}
                formTitle={t("form.editContractData")}
                BackButton={<BackToPolicyHolderButton idObject={idObject} />}
            >
                <EditForm
                    {...props}
                    formName={formName}
                    individualPersonType={individualPersonType}
                    getIdObject={getIdObject}
                    getPolicyHolder={getPolicyHolder}
                    isIdentificationDocShown={isIdentificationDocShown}
                />
            </FullPageFormLayout>
        );
    });

export default createContainer;
