import { createSaga } from "./createSaga";
import { createContainer } from "./createContainer";

/**
 * Saga and Container creator for Pension statements settings
 */
const clientStatementSettingsForm = {
    createSaga,
    createContainer,
};
export default clientStatementSettingsForm;
