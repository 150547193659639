import { model } from "core/util";
import { PensionPpAccountDetail as PensionPpAccountDetailType, PensionPpAccountDetailDto } from "types";

import { Payments, PaymentsScheduler } from "../../common";
import { PensionPpTypes } from "./PensionPpTypes";

export const PensionPpAccountDetail = model.createModel<PensionPpAccountDetailDto, PensionPpAccountDetailType>((json) => ({
    pensionTypes: PensionPpTypes.fromServer(json.PensionTypes),
    paymentsScheduler: PaymentsScheduler.fromServer(json.PaymentsScheduler),
    thirdPartyPayments: Payments.fromServer(json.ThirdPartyPayments),
    employerPayments: Payments.fromServer(json.EmployerPayments),
    precedentPensionInformation: json.PrecedentPensionInformation,
    isTaxOptimizationEnabled: json.IsTaxOptimizationEnabled,
    isPassive: json.IsPassive,
}));
