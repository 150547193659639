import React from "react";

import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, SegmentedControl } from "core/form";

import { SectionDescription, SectionTitle } from "../components";
import { ELECTRONIC_COMMUNICATION_FIELD } from "../constants";

const ElectronicCommunicationFormBox = () => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            <SectionTitle title={t("form.editPolicyHolder.electronicCommunication.title")} />
            <SectionDescription content={t("form.editPolicyHolder.electronicCommunication.introduction")} />
            <ContentBox>
                <Field
                    name={ELECTRONIC_COMMUNICATION_FIELD}
                    component={SegmentedControl}
                    label={t("form.editPolicyHolder.electronicCommunication.check")}
                    footNote={t("form.editPolicyHolder.electronicCommunication.footNote")}
                />
            </ContentBox>
            <SectionDescription content={t("form.editPolicyHolder.electronicCommunication.text")} />
        </Section>
    );
};

export default ElectronicCommunicationFormBox;
