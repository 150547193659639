import { NAME } from "./constants";
import reducer from "./reducer";
import { getUserAccount, hasPermission } from "./selectors";
import { loadUserAccount } from "./util";

export default {
    NAME,
    reducer,
    loadUserAccount,
    hasPermission,
    getUserAccount,
};
