import { call, fork, select, takeLatest } from "redux-saga/effects";

import entity from "core/entity";
import router from "core/router";
import modal from "core/modal";
import claimNoteForm from "form/modal/claimNote";
import uploadClaimDocuments from "form/modal/uploadClaimDocuments";
import { incidentsApi } from "serverApi";

import { clear, SET_SELECTED_CLAIM_ID, setIncidentDetail, setSelectedClaimId } from "./actions";
import {
    ENTITY_CLAIM_INSPECTION_ORDERS,
    ENTITY_CLAIM_OUTGOING_DOCUMENTS,
    ENTITY_CLAIM_REQUIRED_DOCUMENTS,
    FORM_EDIT_CLAIM_NOTE,
    FORM_UPLOAD_CLAIM_DOCUMENTS,
} from "./constants";
import { getClaimData, getDocumentDataForSelectedRequiredDocument, getIncidentDetail, getSelectedClaimId } from "./selectors";

export default router.routerWrapper({
    *getDataForPage({ incidentId }) {
        const incidentDetail = yield call(incidentsApi.getIncidentDetail, incidentId);

        // Check if this is one claim incident - in that case set claim id - select will not be displayed.
        if (incidentDetail.get("claims").size === 1) {
            const claim = incidentDetail.get("claims").first();
            return [setIncidentDetail(incidentDetail), setSelectedClaimId(claim.get("claimId"))];
        }

        return [setIncidentDetail(incidentDetail)];
    },
    *onPageEnter() {
        yield fork(loadClaimData);
        yield takeLatest(SET_SELECTED_CLAIM_ID, loadClaimData);
    },
    *onModalOpen(modalName) {
        const claimData = yield select(getClaimData);
        switch (modalName) {
            case FORM_EDIT_CLAIM_NOTE:
                yield call(claimNoteForm.createSaga, FORM_EDIT_CLAIM_NOTE, claimData.incidentId, claimData.claimId);
                break;
            case FORM_UPLOAD_CLAIM_DOCUMENTS:
                const requiredDocument = yield select(getDocumentDataForSelectedRequiredDocument);
                yield call(
                    uploadClaimDocuments.createSaga,
                    FORM_UPLOAD_CLAIM_DOCUMENTS,
                    claimData.incidentId,
                    claimData.claimId,
                    requiredDocument,
                );
            default:
                break;
        }
    },
    clearDataForPage() {
        return [
            clear(),
            modal.closeAllModalForms(),
            entity.clearData(ENTITY_CLAIM_INSPECTION_ORDERS, ENTITY_CLAIM_REQUIRED_DOCUMENTS, ENTITY_CLAIM_OUTGOING_DOCUMENTS),
        ];
    },
});

function* loadClaimData() {
    const incidentData = yield select(getIncidentDetail);
    const incidentId = incidentData.get("incidentId");
    const claimId = yield select(getSelectedClaimId);

    yield fork(entity.load, ENTITY_CLAIM_INSPECTION_ORDERS, () => incidentsApi.getClaimInspectionOrders(incidentId, claimId));
    yield fork(entity.load, ENTITY_CLAIM_REQUIRED_DOCUMENTS, () => incidentsApi.getClaimRequiredDocuments(incidentId, claimId));
    yield fork(entity.load, ENTITY_CLAIM_OUTGOING_DOCUMENTS, () => incidentsApi.getClaimOutgoingDocuments(incidentId, claimId));
}
