/** composes normalizing function */
export const compose =
    (...normalizations) =>
    (value, prevValue, allValues, prevAllValues) =>
        normalizations.reduce((result, normalize) => normalize(result, prevValue, allValues, prevAllValues), value);

/** adds default normalization to all fields in the app (currently only normalizeEmptyStrings) */
export const addDefaultNormalization = (normalization) => {
    if (typeof normalization === "function") {
        return [normalizeEmptyStrings, normalization];
    }
    if (Array.isArray(normalization)) {
        return [normalizeEmptyStrings, ...normalization];
    }
    return [normalizeEmptyStrings];
};

const normalizeEmptyStrings = (value) => (value === "" ? null : value);

const wrapUndefined = (normalize) => (value) => value ? normalize(value) : value;
const wrapRegexReplace = (regex) => wrapUndefined((value) => value.replace(new RegExp(regex, "g"), ""));

export const lettersNumbersCharacters = wrapRegexReplace(/[^ a-zA-Z0-9]/);
// \u21b5 is enter (maybe redux form?) http://www.ltg.ed.ac.uk/~richard/utf-8.cgi?input=%E2%86%B5&mode=char
export const maxLengthCharacters = (numberOfCharacters) => wrapUndefined((value) => value.slice(0, numberOfCharacters));
// \u000A is LF, needed for textarea http://www.ltg.ed.ac.uk/~richard/utf-8.cgi?input=10&mode=decimal
/* eslint-disable no-control-regex */
export const lettersDotSpaceCharacters = wrapRegexReplace(/[^ .a-zA-Z\u00C0-\u017F\u000A]/);
export const lettersNumbersBasicInterpunctionCharacters = wrapRegexReplace(/[^ /\-.,()0-9a-zA-Z\u00C0-\u017F\u000A]/);
export const lettersNumbersSpecialCharacters = wrapRegexReplace(/[^ /\-=@€;°%´"§+:_.,()?!0-9a-zA-Z\u00C0-\u017F\u000A]/);
export const lettersNumbersSpecialCharactersExtended = wrapRegexReplace(/[^ /\-=@°%´"§+:_.,()?!&~'>*#$0-9a-zA-Z\u00C0-\u017F\u000A]/);
export const lettersNumbersDash = wrapRegexReplace(/[^ \-0-9a-zA-Z]/);
/* eslint-enable no-control-regex */
export const upperLettersNumbers = wrapRegexReplace(/[^0-9A-Z]/);
export const numberCharacters = wrapRegexReplace(/[^0-9]/);
export const numberSpaceCharacters = wrapRegexReplace(/[^ 0-9]/);
export const positiveNegativeNumberCharacters = wrapRegexReplace(/[^\-0-9]/);
export const amountCharacters = wrapRegexReplace(/[^0-9,.]/);
export const positiveNegativeAmountCharacters = wrapRegexReplace(/[^\-0-9,.]/);
export const phoneNumberCharacters = wrapRegexReplace(/[^+ 0-9]/);
export const emailCharacters = wrapRegexReplace(/[^a-zA-Z0-9.@_\-]/);
export const max6Characters = maxLengthCharacters(6);
export const max9Characters = maxLengthCharacters(9);
export const max10Characters = maxLengthCharacters(10);
export const max12Characters = maxLengthCharacters(12);
export const max20Characters = maxLengthCharacters(20);
export const max50Characters = maxLengthCharacters(50);
export const max100Characters = maxLengthCharacters(100);
export const max120Characters = maxLengthCharacters(120);
export const max200Characters = maxLengthCharacters(200);
export const max255Characters = maxLengthCharacters(255);
export const max1000Characters = maxLengthCharacters(1000);
export const maxValue100 = (value) => (Number(value) > 100 ? "100" : value);
