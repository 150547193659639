import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import userContracts from "core/userContracts";
import pageContext from "core/pageContext";
import { fn } from "core/util";
import { useHasEditContractPermission } from "core/hooks";

import { FeatureFlag, GridItemVariant } from "types";

import {
    BalanceOverdueGridItem,
    ContractPaymentSchedulerGridItem,
    HarmonizedContractPaymentSchedulerGridItem,
    InsuranceProductPaymentGridItem,
    TotalInsuranceWithBalanceOverdueGridItem,
} from "containers/contract";

import {
    createGetActualPaymentScheduler,
    createGetAdvancedPaymentScheduler,
    createGetBalanceOnContract,
    getIsTaxCertificateAvailable,
} from "../selectors";
import {
    ENTITY_FINANCIAL_OPERATIONS,
    ENTITY_PAYMENT_INFORMATION,
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    ONE_TIME_PAYMENT_CONTRACTS_ENU_PG_LVL2,
} from "../constants";
import { FinancialOperationsGridItem, TaxStatementGridItem } from "../containers";
import { ModalContractOwnerPaymentsForm } from "../form";

export const FinancialOperationsTab: FC = () => {
    const { idObject } = useParams();

    const actualScheduler = useSelector(createGetActualPaymentScheduler);
    const advancedScheduler = useSelector(createGetAdvancedPaymentScheduler);
    const balanceOnContract = useSelector(createGetBalanceOnContract);
    const isContractBalanceZeroOrValueNull = !balanceOnContract || fn.isContractBalanceZeroOrValueNull(balanceOnContract);
    const isTaxCertificateAvailable = useSelector(getIsTaxCertificateAvailable);
    const hasEditPermission = useHasEditContractPermission();
    const contract = userContracts.useGetContractById(idObject);
    const isUniqaContract = contract?.isUniqaContract;
    const isOneTimePayment = ONE_TIME_PAYMENT_CONTRACTS_ENU_PG_LVL2.includes(contract?.idEnuProductGroupLevelTwo);

    const isDisplayTotalInsuranceEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.DISPLAY_TOTAL_INSURANCE);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    return (
        <GridLayout defaultSpacing>
            {!isInFutureOrTerminated && (
                <>
                    {isUniqaContract ? (
                        <HarmonizedContractPaymentSchedulerGridItem
                            actualPaymentScheduler={actualScheduler}
                            advancePaymentsScheduler={advancedScheduler}
                            hasEditPermission={hasEditPermission && !isOneTimePayment}
                            modalFormName={FORM_EDIT_CONTRACT_OWNER_PAYMENTS}
                        />
                    ) : (
                        <ContractPaymentSchedulerGridItem
                            actualPaymentScheduler={actualScheduler}
                            advancePaymentsScheduler={advancedScheduler}
                            hasEditPermission={hasEditPermission && !isOneTimePayment}
                            modalFormName={FORM_EDIT_CONTRACT_OWNER_PAYMENTS}
                        />
                    )}
                    <InsuranceProductPaymentGridItem
                        idObject={Number(idObject)}
                        entityName={ENTITY_PAYMENT_INFORMATION}
                        variant={GridItemVariant.SEPARATED}
                    />
                </>
            )}

            {isUniqaContract && !isDisplayTotalInsuranceEnabled && !isContractBalanceZeroOrValueNull && (
                <BalanceOverdueGridItem contractBalance={balanceOnContract.balanceOnContract} />
            )}

            {(!isUniqaContract || isDisplayTotalInsuranceEnabled) && (
                <TotalInsuranceWithBalanceOverdueGridItem contractBalance={balanceOnContract} />
            )}

            <FinancialOperationsGridItem entityName={ENTITY_FINANCIAL_OPERATIONS} />

            {/* ECM tax statement */}
            {isTaxCertificateAvailable && <TaxStatementGridItem />}

            {/* Modals */}
            <ModalContractOwnerPaymentsForm />
        </GridLayout>
    );
};
