import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GridLayout } from "ui-library/layouts";

import {
    BalanceOverdueGridItem,
    HarmonizedContractPaymentSchedulerGridItem,
    InsuranceProductPaymentGridItem,
    NonLifeFinancialOperationsGridItem,
    TotalInsuranceWithBalanceOverdueGridItem,
} from "containers/contract";
import { fn } from "core/util";
import { useHasEditContractPermission } from "core/hooks";
import userContracts from "core/userContracts";
import pageContext from "core/pageContext";

import { FeatureFlag, GridItemVariant } from "types";

import { getActualPaymentScheduler, getAdvancedPaymentScheduler, getBalanceOnContract, getFinancialOperations } from "../selectors";
import { ENTITY_FINANCIAL_OPERATIONS, ENTITY_PAYMENT_INFORMATION, FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";
import { ModalContractOwnerPaymentsForm } from "../form";

export const FinancialOperationsTab: FC = () => {
    const { idObject } = useParams();
    const actualScheduler = useSelector(getActualPaymentScheduler);
    const advancedScheduler = useSelector(getAdvancedPaymentScheduler);
    const financialOperations = useSelector(getFinancialOperations);
    const balanceOnContract = useSelector(getBalanceOnContract);
    const hasEditPermission = useHasEditContractPermission();

    const isDisplayTotalInsuranceEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.DISPLAY_TOTAL_INSURANCE);
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    return (
        <GridLayout defaultSpacing>
            {!isInFutureOrTerminated && (
                <>
                    <HarmonizedContractPaymentSchedulerGridItem
                        actualPaymentScheduler={actualScheduler}
                        advancePaymentsScheduler={advancedScheduler}
                        hasEditPermission={hasEditPermission}
                        modalFormName={FORM_EDIT_CONTRACT_OWNER_PAYMENTS}
                    />
                    <InsuranceProductPaymentGridItem
                        idObject={Number(idObject)}
                        entityName={ENTITY_PAYMENT_INFORMATION}
                        variant={GridItemVariant.SEPARATED}
                    />
                </>
            )}

            {!isDisplayTotalInsuranceEnabled && !fn.isBalanceZeroOrValueNull(balanceOnContract?.balanceOnContract) && (
                <BalanceOverdueGridItem contractBalance={balanceOnContract?.balanceOnContract} />
            )}

            {isDisplayTotalInsuranceEnabled && <TotalInsuranceWithBalanceOverdueGridItem contractBalance={balanceOnContract} />}

            <NonLifeFinancialOperationsGridItem entityName={ENTITY_FINANCIAL_OPERATIONS} financialOperations={financialOperations} />

            {/* Modals */}
            <ModalContractOwnerPaymentsForm />
        </GridLayout>
    );
};
