import React, { FC, PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalHeader } from "ui-library/modules";

import modal from "core/modal";
import { hasSubmitSucceeded, isSubmitting } from "core/form";
import { ModalErrorBoundary } from "core/errorHandling";

import { ModalEditFormError } from "./ModalEditFormError";
import { ModalSuccess } from "./ModalSuccess";

export interface ModalFormLayoutProps extends PropsWithChildren {
    formName: string;
    formTitle: string;
    formIcon: any;
    SuccessComponent?: any;
}

export const ModalFormLayout: FC<ModalFormLayoutProps> = ({ children, formName, formTitle, formIcon, SuccessComponent = ModalSuccess }) => {
    const dispatch = useDispatch();

    const isModalFormOpen = useSelector(modal.isModalFormOpen(formName));
    const isFormSubmitted = useSelector(hasSubmitSucceeded(formName));
    const isFormSubmitting = useSelector(isSubmitting(formName));

    const closeModalForm = () => {
        if (!isFormSubmitting) {
            dispatch(modal.closeModalForm(formName));
        }
    };

    return (
        <Modal size={"small"} open={isModalFormOpen} onClose={closeModalForm}>
            <ModalHeader closeAction={closeModalForm} icon={formIcon} title={formTitle} />
            <ModalErrorBoundary ErrorComponent={ModalEditFormError} closeModal={closeModalForm} service={formName}>
                {isFormSubmitted ? <SuccessComponent closeModal={closeModalForm} /> : children}
            </ModalErrorBoundary>
        </Modal>
    );
};
