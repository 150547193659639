import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

type SectionTitleProps = {
    title: string;
};

export const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
    <ContentBox>
        <Typography variant="h3">{title}</Typography>
    </ContentBox>
);
