import { NAME } from "./constants";
import reducer from "./reducer";
import saga from "./saga";
import innerRoutes from "./innerRoutes";

import { AgentTab, DetailTab, DocumentsTab, FinancialOperationsTab, FinancialStatementTab, OverviewTab, PolicyHolderTab } from "./tabs";
import { FullPageBeneficiariesForm, FullPageFundsChangeForm, FullPagePolicyHolderForm } from "./form";

export { DetailPensionDdsPage } from "./Container";

export const DetailPensionDdsTabs = {
    OverviewTab,
    PolicyHolderTab,
    DetailTab,
    FinancialOperationsTab,
    FinancialStatementTab,
    DocumentsTab,
    AgentTab,
};

export const DetailPensionDdsForms = {
    FullPagePolicyHolderForm,
    FullPageBeneficiariesForm,
    FullPageFundsChangeForm,
};

const detailDds = {
    NAME,
    reducer,
    saga,
    innerRoutes,
};

export default detailDds;
