export const NAME = "page-detail-dss";

// Entity
export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_ENQUEUED_DOCUMENTS = `${NAME}/ENTITY_ENQUEUED_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_FINANCIAL_STATEMENT = `${NAME}/ENTITY_FINANCIAL_STATEMENT`;
export const ENTITY_PAYMENT_INFORMATION = `${NAME}/ENTITY_PAYMENT_INFORMATION`;
export const ENTITY_FUNDS_CHANGE_PERMITTED = `${NAME}/ENTITY_FUNDS_CHANGE_PERMITTED`;
export const ENTITY_CHANGE_PROPOSITIONS = `${NAME}/ENTITY_CHANGE_PROPOSITIONS`;

// Full page forms
export const FORM_EDIT_POLICY_HOLDER = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_BENEFICIARIES = `${NAME}/FORM_EDIT_BENEFICIARIES`;
export const FORM_EDIT_FUNDS_CHANGE = `${NAME}/FORM_EDIT_FUNDS_CHANGE`;

// Modal forms
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_CONTRACT_OWNER_PAYMENTS = `${NAME}/FORM_EDIT_CONTRACT_OWNER_PAYMENTS`;
export const FORM_EDIT_STATEMENT_PASSWORD = `${NAME}/FORM_EDIT_STATEMENT_PASSWORD`;
export const FORM_PIS_CONFIRMATION = `${NAME}/FORM_PIS_CONFIRMATION`;
export const FORM_EDIT_INVESTMENT_STRATEGY = `${NAME}/FORM_EDIT_INVESTMENT_STRATEGY`;

// Columns
export const INVESTMENT_FUND_COLUMN = "investmentFund";
export const PAYMENT_DATE_COLUMN = "paymentDate";
export const TYPE_COLUMN = "type";
export const GROSS_AMOUNT_COLUMN = "grossAmount";
