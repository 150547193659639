import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Box, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import localized from "core/localized";
import { formatPaymentsSchedulerDate } from "core/formatters";

import { ContributionsEditDropdown } from "./dropdown/ContributionsEditDropdown";

export const ActualPaymentOnlySchedulerPanel = ({ actualScheduler, hasEditPermission, modalFormName }) => {
    const { t } = i18n.useTranslation();
    const dispatch = useDispatch();

    const actualSchedulerAmount = useSelector(localized.getLocalizedSchedulerPaymentAmount(actualScheduler));
    const actualSchedulerFrequency = useSelector(localized.getLocalizedSchedulerPaymentFrequency(actualScheduler));
    const actualSchedulerPaymentMode = useSelector(localized.getLocalizedSchedulerPaymentMode(actualScheduler));

    return (
        <GridItem display={"flex"} xs={12} lg={7}>
            <InfoPanel
                icon={"contract"}
                title={t("insurance.actualPaymentScheduler")}
                headerAction={hasEditPermission && <ContributionsEditDropdown modalContractOwnerPaymentsFormName={modalFormName} />}
            >
                <Box>
                    <LabelTextItem label={t("investments.investmentPeriod")} text={formatPaymentsSchedulerDate(actualScheduler)} />
                    <LabelTextItem label={t("insurance.insuranceCost")} text={actualSchedulerAmount} />
                    <LabelTextItem label={t("investments.frequency")} text={actualSchedulerFrequency} />
                    <LabelTextItem label={t("payments.type")} text={actualSchedulerPaymentMode} />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};

ActualPaymentOnlySchedulerPanel.propTypes = {
    actualScheduler: PropTypes.object.isRequired,
    hasEditPermission: PropTypes.bool,
    modalFormName: PropTypes.string.isRequired,
};

ActualPaymentOnlySchedulerPanel.defaultProps = {
    hasEditPermission: false,
};
