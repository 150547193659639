import React, { FC } from "react";

import { ContentBox, Section } from "ui-library/layouts";

import i18n from "core/i18n";
import { Field, SegmentedControl } from "core/form";

import { SectionDescription } from "../components";
import { FIELD_ELECTRONIC_COMMUNICATION } from "../constants";

export const ElectronicCommunicationFormBox: FC = () => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            {/* Introduction text  */}
            <SectionDescription content={t("form.electronicCommunication.introduction")} />

            {/* Checker */}
            <ContentBox mt={5} mb={5}>
                <Field
                    name={FIELD_ELECTRONIC_COMMUNICATION}
                    label={t("holder.eCommunication")}
                    component={SegmentedControl}
                    footNote={t("form.electronicCommunication.checker.tooltip")}
                />
            </ContentBox>

            {/* Support text */}
            <SectionDescription content={t("form.electronicCommunication.supportText")} />
        </Section>
    );
};
