import { NAME } from "./constants";

export const SET_VEHICLE_CONTRACT_DETAIL = `${NAME}/SET_VEHICLE_CONTRACT_DETAIL`;
export const SET_DETAIL_SELECTED_COVERAGE = `${NAME}/SET_DETAIL_SELECTED_COVERAGE`;
export const CLEAR = `${NAME}/CLEAR`;

export const setVehicleContractDetail = (contractDetail) => ({
    type: SET_VEHICLE_CONTRACT_DETAIL,
    payload: contractDetail,
});

export const setDetailSelectedCoverage = (selectedCoverage) => ({
    type: SET_DETAIL_SELECTED_COVERAGE,
    payload: selectedCoverage,
});

export const clear = () => ({
    type: CLEAR,
});
