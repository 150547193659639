import { app } from "core/util";
import { createSelector } from "reselect";

import { AccountInformation } from "model/user";

import { NAME } from "./constants";

const LOGIN_TYPE_CREDENTIALS = "CREDENTIALS";

export const getModel = app.createGetModel(NAME);

export const getUserAccount = (state) => getModel(state).get("userAccount", new AccountInformation());

// TODO - first verion dummy - when impersonated cannot edit contracts.
export const hasPermission = (permissionsToCheck) =>
    createSelector(getUserAccount, (userAccount) => userAccount.authorizationType === LOGIN_TYPE_CREDENTIALS);
