import { FC } from "react";

import i18n from "core/i18n";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";

import { FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";

const ModalContractOwnerPaymentsFormContainer = contractOwnerPaymentsForm.createContainer(
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    "form.paymentsScheduler.pension",
);

export const ModalContractOwnerPaymentsForm: FC = () => {
    const { t } = i18n.useTranslation();

    return (
        <ModalContractOwnerPaymentsFormContainer
            showFrequency={false}
            showPaymentType={false}
            amountLabel={t("pension.dss.payments.amount")}
        />
    );
};
