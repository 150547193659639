import { List } from "immutable";

import { PaymentContribution } from "enums";
import { FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";
import contractOwnerPaymentsForm from "form/modal/contractOwnerPayments";
import i18n from "core/i18n";

export const ModalContractOwnerPaymentsFormContainer = contractOwnerPaymentsForm.createContainer(
    FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
    "form.paymentsScheduler.nonLife",
);

export const ModalContractOwnerPaymentsForm = () => {
    const { t } = i18n.useTranslation();
    const paymentOptions = List([PaymentContribution.ByBankAccount.id, PaymentContribution.ByDirectDebit.id]);

    return (
        <ModalContractOwnerPaymentsFormContainer
            isUniqaContract={true}
            canEditAmount={false}
            amountLabel={t("insurance.payments.amount")}
            paymentOptionsUniqaExAxa={paymentOptions}
            displayDisclaimer
        />
    );
};
