import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import { useHasEditContractPermission } from "core/hooks";
import { formatYesNo } from "core/formatters";
import userContracts from "core/userContracts";
import pageContext from "core/pageContext";

import { FeatureFlag } from "types";
import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { FORM_EDIT_LICENCE_PLATE_NUMBER, FORM_EDIT_VEHICLE_LICENCE_NUMBER } from "../constants";

export interface VehicleGridItemProps {
    insuredVehicle: any;
}

export const VehicleGridItem: FC<VehicleGridItemProps> = ({ insuredVehicle }) => {
    const { t } = i18n.useTranslation();
    const { idObject } = useParams();
    const contract = userContracts.useGetContractById(idObject);
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(contract);

    const hasEditPermission = useHasEditContractPermission();
    const isHarmonizedContractsEditEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.HARMONIZED_CONTRACTS_EDIT_ENABLED);

    const editLinks = [
        {
            formConst: FORM_EDIT_LICENCE_PLATE_NUMBER,
            name: "vehicle.licencePlateNumber",
        },
        {
            formConst: FORM_EDIT_VEHICLE_LICENCE_NUMBER,
            name: "vehicle.vehicleLicenceNumber",
        },
    ];

    return (
        <GridItem display={"flex"} xs={12} md={6}>
            <InfoPanel
                icon={"car"}
                title={t("insurance.insurance")}
                headerAction={
                    isHarmonizedContractsEditEnabled &&
                    !isInFutureOrTerminated &&
                    hasEditPermission && <EditLinksDropdown links={editLinks} />
                }
            >
                <Box>
                    <LabelTextItem
                        label={t("vehicle.insuredVehicle")}
                        text={`${insuredVehicle.manufacturer || ""} ${insuredVehicle.model || ""}`}
                    />
                    <LabelTextItem label={t("vehicle.vin")} text={insuredVehicle.vinNumber} />
                    <LabelTextItem label={t("vehicle.licencePlateNumber")} text={insuredVehicle.licencePlateNumber} />
                    <LabelTextItem label={t("vehicle.vehicleLicenceNumber")} text={insuredVehicle.vehicleLicenceNumber} />
                    <LabelTextItem
                        label={t("vehicle.leasing")}
                        text={formatYesNo(t, insuredVehicle.leasingInformations && !insuredVehicle.leasingInformations.isEmpty())}
                    />
                </Box>
            </InfoPanel>
        </GridItem>
    );
};
