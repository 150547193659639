import React, { FC } from "react";
import { GridLayout } from "ui-library/layouts";

import { ActualInvestmentValueTable, ContractDataGridItem, InvestmentsOverviewPieChartGridItem } from "../containers";
import { ModalContractNameForm } from "../form";
import userContracts from "core/userContracts";

export const PortfolioTab: FC = () => {
    const policy = userContracts.useGetContractByUrlParam();
    const { isInFutureOrTerminated } = userContracts.useGetContractDerivedData(policy);

    return (
        <GridLayout defaultSpacing>
            {!isInFutureOrTerminated && <ActualInvestmentValueTable />}
            {!isInFutureOrTerminated && <InvestmentsOverviewPieChartGridItem />}
            <ContractDataGridItem />

            {/* Modals */}
            <ModalContractNameForm />
        </GridLayout>
    );
};
