import React, { FC, useRef } from "react";

export interface CheckboxItemProps extends React.HTMLAttributes<HTMLInputElement> {
    checkedUpdate?;
    checked?: boolean;
    error?: any;
    theme?;
}

export const CheckboxItem: FC<CheckboxItemProps> = ({ id, checked, checkedUpdate, theme, error, ...props }) => {
    const inputRef = useRef(null);

    const clicked = () => {
        checkedUpdate(inputRef.current.checked);
    };

    return (
        <>
            <input id={id} checked={checked} ref={inputRef} onClick={clicked} type="checkbox" {...props} />
            <span></span>
        </>
    );
};
