import React from "react";

import i18n from "core/i18n";
import { Field, InputText, max20Characters, phoneNumberCharacters, required } from "core/form";
import { ContentBox, Section } from "ui-library/layouts";

import { SectionDescription, SectionTitle } from "../components";

const TelephoneFormBox = () => {
    const { t } = i18n.useTranslation();

    return (
        <Section>
            <SectionTitle title={t("holder.mobile")} />
            <SectionDescription content={t("form.editPolicyHolder.changePhoneNumber")} />
            <ContentBox>
                <Field
                    name="mobile"
                    component={InputText}
                    label={t("holder.tel")}
                    // validated async in saga!
                    normalize={[phoneNumberCharacters, max20Characters]}
                    validateStatic={[required]}
                    preventDefaultNormalization
                />
            </ContentBox>
        </Section>
    );
};

export default TelephoneFormBox;
