import React, { FC } from "react";

import { Typography } from "ui-library/atoms";
import { ContentBox } from "ui-library/layouts";

type SectionDescriptionProps = {
    content: string;
};

export const SectionDescription: FC<SectionDescriptionProps> = ({ content }) => (
    <ContentBox>
        <Typography component="p" markDown={content} />
    </ContentBox>
);
