import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import router from "core/router";
import userContracts from "core/userContracts";
import { ContractPolicyHolderTab } from "containers/contract";
import { Pages, Tabs } from "routeConstants";
import { getElectronicCommunication, getPolicyHolder } from "../selectors";

export const PolicyHolderTab: FC = () => {
    const { idObject } = useParams();
    const policyHolder = useSelector(getPolicyHolder);
    const electronicCommunication = useSelector(getElectronicCommunication);
    const { canEdit: canEditContract } = userContracts.useGetContractById(idObject);

    return (
        <ContractPolicyHolderTab
            holder={policyHolder}
            isExtended
            editLink={router.getStaticUrl(Pages.CONTRACT_DSS, Tabs.EDIT_POLICY_HOLDER_FORM, { idObject })}
            electronicCommunication={electronicCommunication}
            isContractEditable={canEditContract}
        />
    );
};
