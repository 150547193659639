import React, { FC, useState } from "react";
import { StyledCheckbox, StyledCheckboxGroup, StyledCheckboxLabel } from "ui-library/Styled";
import { ErrorContent, PopoverTooltip, Typography } from "ui-library/atoms";
import { Box, Flexbox } from "ui-library/layouts";
import { Label } from "./Label";
import { CheckboxItemProps } from "ui-library/atoms/Inputs/CheckboxItem";
import { FormGroupProps } from "@mui/material/FormGroup/FormGroup";

export interface CheckboxProps extends CheckboxItemProps {
    id: string;
    label?: string;
    labelOptional?: string;
    tooltip?: string;
    error?: boolean;
    errorContent?: string;
    hasTooltip?: boolean;
    checked?: boolean;
    hasGroup?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
    hasGroup,
    id,
    label,
    labelOptional,
    tooltip,
    error,
    errorContent,
    hasTooltip,
    checked,
    ...props
}) => {
    const [checkedStyle, setCheckedStyle] = useState(checked);

    const checkedUpdate = (ref) => {
        if (checked) {
            setCheckedStyle(checked);
        } else {
            setCheckedStyle(ref);
        }
    };

    return (
        <div className="checkbox-wrapper">
            <Flexbox alignItems={"center"} gap={1}>
                <StyledCheckboxLabel
                    $hasGroup={hasGroup}
                    $hasTooltip={hasTooltip}
                    className={checkedStyle && !error ? "checked" : "unchecked"}
                    control={<StyledCheckbox checked={checked} checkedUpdate={checkedUpdate} error={error} id={id} {...props} />}
                    label={label}
                />

                {tooltip && <PopoverTooltip className="checkbox-tooltip" title={tooltip} />}
            </Flexbox>

            {error ? <ErrorContent content={errorContent} /> : ""}
        </div>
    );
};

export interface CheckboxGroupProps extends FormGroupProps {
    label?: string;
    labelDesc?: string;
    labelOptional?: string;
    tooltip?: string;
    direction?: string;
    error?: any;
    errorContent?: any;
}

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
    label,
    tooltip,
    labelDesc,
    labelOptional,
    children,
    direction = "row",
    error,
    errorContent,
    ...props
}) => (
    <div style={{ display: "block" }}>
        <Box>
            <Flexbox alignItems={"flex-start"} gap={1}>
                <Label label={label} id="" labelOptional={labelOptional} />
                <Box mt={0.5}>{tooltip && <PopoverTooltip title={tooltip} />}</Box>
            </Flexbox>
            {labelDesc && (
                <Box mt={0.5} mb={1}>
                    <Typography color={"gray"} variant="caption">
                        {labelDesc}
                    </Typography>
                </Box>
            )}
        </Box>

        <StyledCheckboxGroup direction={direction} {...props}>
            {children}
        </StyledCheckboxGroup>

        {error ? <ErrorContent content={errorContent} /> : ""}
    </div>
);
