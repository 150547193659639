import { actionChannel, all, call, delay, fork, put, select, spawn, take } from "redux-saga/effects";

import auth from "core/auth";
import cmsContent from "core/cmsContent";
import init from "core/init";
import i18n from "core/i18n";
import impersonate from "core/impersonate";
import pageContext from "core/pageContext";
import router from "core/router";
import userInfo from "core/userInfo";
import { configApi, translationsApi } from "serverApi";
import { pageRoutes } from "routeUrls";

import { Pages } from "routeConstants";

import { PUBLIC_PAGES } from "./constants";
import { CallCenterContactData, Locales, PortalData } from "enums";
import { SetContextPayload } from "core/pageContext/constants";
import { ConfigDto } from "types/generated";

export default function* () {
    yield call(loadSiteInfo);
    yield call(loadTranslations);
    yield call(setTranslationsDefaultVariables);

    // Check Sso login.
    yield call(auth.ssoLoginSaga);

    // Check impersonate access.
    yield call(impersonate.impersonateSaga);

    // Load RSS content.
    yield fork(cmsContent.loadRssContent);

    yield call(loadUserInfo);

    // channel for taking router.ROUTE_ENTERED must be created prior calling router.setPageByLocationDirectly
    // if we just call take router.ROUTE_ENTERED afterwards, its apparently too late, action was already dispatched.
    // normally it would not happen because javascript is single threaded, but there is some history.replace magic, which
    // can probably somehow interrupt js event loop.
    const channel = yield actionChannel(router.routeEntered.type);
    // router.setPageByLocationDirectly will appropriately initialize and enter initial route for app according to URL.
    yield fork(router.setPageByLocationDirectly, window.location);
    // after all the magic is done (action router.ROUTE_ENTERED is dispatched)
    yield take(channel);
    channel.close(); // just good manners, we won't need this channel anymore

    yield fork(router.startRouting);

    // we can show application component, which has already all data for page needed.
    yield put(init.initialize());
}

function* loadSiteInfo() {
    const [config, localeConfig]: [ConfigDto, any] = yield all([call(configApi.getConfig), call(configApi.getLocaleConfig)]);

    yield put(auth.setInactiveInterval(config.sessionMaxInactiveInterval));

    const context: SetContextPayload = {
        clientId: config.clientId,

        configUrls: {
            authUri: config.authUri,
            claimReportingUrl: config.claimReportingUrl,
            claimReportingUrls: config.claimReportingUrls,
            logoutUri: config.logoutUri,
        },
        disclaimer: {
            text: config.disclaimerText,
            version: config.disclaimerVersion,
        },
        featureFlags: config.featureFlags,
        infoTexts: config.infoTexts,

        keys: {
            recaptcha: config.recaptchaSiteKey,
            vapid: config.vapidPublicKey,
            youtube: config.youtubePromoId,
        },
        notAllowed: {
            targetFundCodesCz: config.notAllowedTargetFundCodesCz,
            targetFundCodesSk: config.notAllowedTargetFundCodesSk,
        },
        pageLocalization: {
            locale: localeConfig.locale,
            availableLocales: localeConfig.availableLocales,
            site: localeConfig.site,
        },
    };
    yield all([put(pageContext.setContext(context))]);
}

function* loadTranslations() {
    const site = yield select(pageContext.getSite);
    const locale = yield select(pageContext.getLocale);
    const localMessages = yield call(translationsApi.getTranslations, site, locale);
    const enMessages = yield call(translationsApi.getTranslations, site, Locales.en_US);

    yield all([
        call(i18n.addTranslations, locale.languageCode, localMessages),
        call(i18n.addTranslations, Locales.en_US.languageCode, enMessages),
        call(i18n.changeLanguage, locale.languageCode),
    ]);

    // Handle initial setup of en language if lang=en is in url param. At this moment it is set in a way
    // that initially local language is set and only after en. If en is set initially i18n is not working.
    // TODO: We will need to investigate / fix this.
    const enLocale = Locales.en_US;
    const searchParams = new URLSearchParams(window.location?.search);
    if (searchParams.get(router.LANG_PARAM) === Locales.en_US.languageCode) {
        yield all([put(pageContext.setLocale(enLocale)), call(i18n.changeLanguage, enLocale.languageCode)]);
    }
}

function* setTranslationsDefaultVariables() {
    const site = yield select(pageContext.getSite);
    const { defaultVariables, setDefaultInterpolationVariables } = i18n;

    const defaultValues = {
        [defaultVariables.PORTAL_NAME]: PortalData.PORTAL_NAME[site?.id],
        [defaultVariables.CC_EMAIL]: CallCenterContactData.EMAIL[site?.id],
        [defaultVariables.CC_PHONE]: CallCenterContactData.PHONE[site?.id],
    };

    yield call(setDefaultInterpolationVariables, defaultValues);
}

function* loadUserInfo() {
    try {
        yield call(userInfo.loadCriticalUserInfo);
        yield put(auth.setLogged());
        const acceptedDisclaimer = yield select(userInfo.getCurrentDisclaimerAccepted);
        if (!acceptedDisclaimer) {
            yield put(router.navigate(Pages.DISCLAIMER));
            /* eslint-disable-next-line no-restricted-globals */
        } else if (location.pathname.includes(pageRoutes[Pages.LOGIN]) || location.pathname === "/") {
            // if logged and on login page, redirect to contract. When done in login saga, login page will briefly show itself.
            // This is optimization of this blink
            yield put(router.navigate(Pages.CONTRACTS));
            // some delay is needed to avoid login page blink
            // in production, app init is so fast, that transition is done after rendering the login component
            yield delay(100);
        }
        yield spawn(userInfo.loadAgents);
        yield spawn(userInfo.loadMessages);
    } catch (e) {
        // We are touching raw windows.location because router.getCurrentRoute is set way after this saga is run (withAsyncModule - onRouteEntered)
        /* eslint-disable-next-line no-restricted-globals */
        if (PUBLIC_PAGES.some((page) => location.pathname.includes(pageRoutes[page]))) {
            // do not redirect
        } else {
            yield put(router.navigate(Pages.LOGIN));
        }
    }
}
