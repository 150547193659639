export const NAME = "page-detail-vehicle";

// Entity
export const ENTITY_CONTRACT_DOCUMENTS = `${NAME}/ENTITY_CONTRACT_DOCUMENTS`;
export const ENTITY_FINANCIAL_OPERATIONS = `${NAME}/ENTITY_FINANCIAL_OPERATIONS`;
export const ENTITY_CONTRACT_INCIDENTS = `${NAME}/ENTITY_CONTRACT_INCIDENTS`;
export const ENTITY_PAYMENT_INFORMATION = `${NAME}/ENTITY_PAYMENT_INFORMATION`;
export const ENTITY_CONTRACT_OVERVIEW = `${NAME}/ENTITY_CONTRACT_OVERVIEW`;
export const ENTITY_GREEN_CARD_DATA = `${NAME}/ENTITY_GREEN_CARD_DATA`;

// Entity state
export const ENTITY_CONTRACT_INCIDENTS_OPEN_STATE = `${NAME}/ENTITY_CONTRACT_INCIDENTS_OPEN_STATE`;

// Form
export const FORM_EDIT_POLICY_HOLDER = `${NAME}/FORM_EDIT_POLICY_HOLDER`;
export const FORM_EDIT_CONTRACT_NAME = `${NAME}/FORM_EDIT_CONTRACT_NAME`;
export const FORM_EDIT_CONTRACT_OWNER_PAYMENTS = `${NAME}/FORM_EDIT_CONTRACT_OWNER_PAYMENTS`;
export const FORM_EDIT_LICENCE_PLATE_NUMBER = `${NAME}/FORM_EDIT_LICENCE_PLATE_NUMBER`;
export const FORM_EDIT_VEHICLE_LICENCE_NUMBER = `${NAME}/FORM_EDIT_VEHICLE_LICENCE_NUMBER`;

// Green Card
export const GREEN_CARD_DOCUMENT_TYPE = "GREEN_CARD";
