import React from "react";

import { GridLayout } from "ui-library/layouts";

import { ContractDataGridItem, InsuredTravelPersonGridItem } from "../containers";
import { ModalContractNameForm, ModalContractOwnerPaymentsForm } from "../form";

export const DetailTab = () => {
    return (
        <GridLayout defaultSpacing>
            <InsuredTravelPersonGridItem />

            <ContractDataGridItem />

            {/*/!* Modals *!/*/}
            <ModalContractNameForm />
            <ModalContractOwnerPaymentsForm />
        </GridLayout>
    );
};
