import React from "react";
import PropTypes from "prop-types";

import { FeatureFlag } from "types";
import pageContext from "core/pageContext";

import { ActualAndAdvancedPaymentSchedulerPanel } from "./ActualAndAdvancedPaymentSchedulerPanel";
import { ActualPaymentOnlySchedulerPanel } from "./ActualPaymentOnlySchedulerPanel";

export const ContractPaymentSchedulerGridItem = ({
    actualPaymentScheduler,
    advancePaymentsScheduler,
    hasEditPermission,
    modalFormName,
}) => {
    const isHarmonizedContractsEditEnabled = pageContext.useIsFeatureFlagEnabled(FeatureFlag.HARMONIZED_CONTRACTS_EDIT_ENABLED);

    // In case advance scheduler is available we do display both.
    if (advancePaymentsScheduler?.modalAmount?.value) {
        return (
            <ActualAndAdvancedPaymentSchedulerPanel
                actualScheduler={actualPaymentScheduler}
                advancePaymentsScheduler={advancePaymentsScheduler}
                hasEditPermission={hasEditPermission && isHarmonizedContractsEditEnabled}
                modalFormName={modalFormName}
            />
        );
    }

    return (
        <ActualPaymentOnlySchedulerPanel
            actualScheduler={actualPaymentScheduler}
            hasEditPermission={hasEditPermission && isHarmonizedContractsEditEnabled}
            modalFormName={modalFormName}
        />
    );
};

ContractPaymentSchedulerGridItem.propTypes = {
    actualPaymentScheduler: PropTypes.object.isRequired,
    advancePaymentsScheduler: PropTypes.object,
    hasEditPermission: PropTypes.bool,
    modalFormName: PropTypes.string.isRequired,
};

ContractPaymentSchedulerGridItem.defaultProps = {
    advancePaymentsScheduler: null,
    hasEditPermission: false,
};
