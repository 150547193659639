import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button } from "ui-library/atoms";
import { InfoPanel, LabelTextItem } from "ui-library/modules";
import { Box, ContentBox, GridItem } from "ui-library/layouts";

import i18n from "core/i18n";
import router from "core/router";
import { useHasEditContractPermission } from "core/hooks";
import { formatYesNo } from "core/formatters";
import userContracts from "core/userContracts";

import { EditLinksDropdown } from "containers/EditLinksDropdown";
import { Pages, Params, Tabs } from "routeConstants";
import { getPensionAccountDetail } from "../selectors";
import { FORM_EDIT_CONTRACT_OWNER_PAYMENTS } from "../constants";

const editLinks = [
    {
        formConst: FORM_EDIT_CONTRACT_OWNER_PAYMENTS,
        name: "pension.dss.payments.amount",
    },
];

export const ContributionsGridItem: FC = () => {
    const { t } = i18n.useTranslation();

    const { idObject } = useParams();
    const pensionAccountDetail = useSelector(getPensionAccountDetail);
    const hasEditPermission = useHasEditContractPermission();
    const { canEdit: isContractEditable } = userContracts.useGetContractById(idObject);

    const dispatch = useDispatch();

    const doTransition = (contractId) =>
        router.navigate(
            Pages.ONLINE_PAYMENT,
            Tabs.PAYMENTS_PAY,
            {},
            {
                [Params.PAYMENT_CONTRACT_ID]: contractId,
            },
            true,
        );

    return (
        <GridItem display="flex" xs={12} md={6}>
            <InfoPanel
                icon="circle-dollar"
                title={t("pension.contributions")}
                actionButton={
                    pensionAccountDetail.voluntaryContribution && (
                        <Box>
                            <Button color="green" onClick={() => dispatch(doTransition(idObject))}>
                                {t("common.pay")}
                            </Button>
                        </Box>
                    )
                }
                headerAction={hasEditPermission && isContractEditable && <EditLinksDropdown links={editLinks} />}
            >
                <ContentBox>
                    <LabelTextItem
                        label={t("insurance.voluntaryContribution")}
                        text={formatYesNo(t, pensionAccountDetail.voluntaryContribution)}
                    />
                    <LabelTextItem
                        label={t("pension.guaranteedFundRatioReductionSet")}
                        text={formatYesNo(t, pensionAccountDetail?.isGuaranteedFundRatioReductionSet)}
                    />
                </ContentBox>
            </InfoPanel>
        </GridItem>
    );
};
