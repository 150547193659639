import React from "react";
import { GridLayout } from "ui-library/layouts";

import { ContractDataGridItem, InsuranceCoverageGridItem } from "../containers";
import { ModalContractNameForm, ModalContractOwnerPaymentsForm } from "../form";

export const DetailTab = () => {
    return (
        <GridLayout defaultSpacing>
            <InsuranceCoverageGridItem />

            <ContractDataGridItem />

            {/* Modals */}
            <ModalContractNameForm />
            <ModalContractOwnerPaymentsForm />
        </GridLayout>
    );
};
